import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const UNREGISTERED_TEMPLATE_QUERY = gql`
  query UnregisteredEmailCampaign($campaignId: String!) {
    unregisteredEmailCampaign(campaignId: $campaignId) {
      campaignId
      campaignName
      fields
      allFields
    }
  }
`
