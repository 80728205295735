import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyDeleteInput {
 *   journeyId: ID!
 * }
 */
export const DELETE_JOURNEY_MUTATION = gql`
  mutation DeleteJourney($deleteInput: JourneyDeleteInput!) {
    deleteJourney(journeyDelete: $deleteInput)
  }
`
