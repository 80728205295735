import { delay } from './delay'

/**
 * Scroll to the top of the page.
 *
 * Optionally, you can add a delay if you want to wait for a
 * DOM update that could change the scroll height of the screen.
 */
export async function scrollToTop(delayMs = 0) {
  if (delayMs > 0) await delay(delayMs)
  window.scrollTo(0, 0)
}

/**
 * Scroll to the bottom of the page.
 *
 * Optionally, you can add a delay if you want to wait for a
 * DOM update that could change the scroll height of the screen.
 */
export async function scrollToBottom(delayMs = 0) {
  if (delayMs > 0) await delay(delayMs)
  window.scrollTo(0, document.body.scrollHeight)
}
