import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import { Error } from '../components/Error'
import { JOURNEY_NAMES_QUERY } from '../graphql/queries/journey-names'
import type { Journey } from '../types'

interface RenderProps {
  loading: boolean
  journeys: Journey[]
}

export interface JourneyNamesQueryProps {
  children: ({ loading, journeys }: RenderProps) => React.ReactNode
}

export default function JourneyNamesQuery(props: JourneyNamesQueryProps) {
  const { children } = props

  const [doQuery, { loading, error, data }] = useLazyQuery(JOURNEY_NAMES_QUERY)

  useEffect(() => {
    doQuery({ variables: {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    console.error('Error while querying journey names!', error)
    return <Error errors={error as never} />
  }

  return <>{children({ loading, journeys: data?.journeys || [] })}</>
}
