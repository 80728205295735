import { useState } from 'react'

import { FormControl, InputLabel, OutlinedInput } from '@mui/material'

import _ from 'lodash'

import { VALID_RED_OAK_ID_LENGTH, isValidRedOakId } from '../../utils/number'

interface RedOakInputProps {
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

export function RedOakInput(props: RedOakInputProps) {
  const { value, onChange = _.noop, disabled = false } = props

  const [input, setInput] = useState<string>(value || '')

  const handleChange = e => {
    const newInput = e.target.value
    setInput(newInput)
    if (isValidRedOakId(newInput)) {
      onChange(newInput)
    }
  }

  return (
    <div>
      <FormControl
        sx={{ mx: 1, width: '12ch' }}
        variant='outlined'
        size='small'
        disabled={disabled}
        error={!isValidRedOakId(input)}
      >
        <InputLabel id='red-oak-id-input-label'>RedOak ID</InputLabel>
        <OutlinedInput
          id='red-oak-id-input'
          label='RedOak ID'
          inputProps={{
            labelid: 'red-oak-id-input-label',
            maxLength: VALID_RED_OAK_ID_LENGTH
          }}
          value={input}
          onChange={handleChange}
          autoFocus={!input}
          autoComplete='off'
        />
      </FormControl>
    </div>
  )
}
