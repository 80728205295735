import React, { useEffect } from 'react'

import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'

import { ALL_TAGS_QUERY } from '../graphql/queries/tags'
import { Error } from '../components/Error'

interface RenderProps {
  loading: boolean
  tags: string[]
}

export interface AllTagsQueryProps {
  children: ({ loading, tags }: RenderProps) => React.ReactNode
}

export default function TagsQuery(props: AllTagsQueryProps) {
  const { children } = props

  const [doQuery, { loading, error, data }] = useLazyQuery(ALL_TAGS_QUERY, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    doQuery({ variables: {} })
  }, [doQuery])

  if (error) {
    console.error('Error while querying journey tags!', error)
    return <Error errors={error as never} />
  }

  return <>{children({ loading, tags: _.sortBy(data?.allTags, tag => tag) || [] })}</>
}
