import clsx from 'clsx'

import styles from './ComponentEdge.module.scss'

interface ComponentEdgeProps {
  size: 'small' | 'medium' | 'large'
}

export function ComponentEdge(props: ComponentEdgeProps) {
  const { size } = props
  return <div className={clsx(styles.root, styles[size])}></div>
}
