import { useNavigate } from 'react-router-dom'

import _ from 'lodash'
import { useApolloClient, useMutation } from '@apollo/client'
import { useSnackbar } from '@assuranceiq/react-components'

import { CAMPAIGN_QUERY } from '../graphql/queries/campaign'
import { COPY_CAMPAIGN_MUTATION } from '../graphql/mutations/copy-campaign'
import { CREATE_CALL_CAMPAIGN_MUTATION } from '../graphql/mutations/create-call-campaign'
import { CREATE_EMAIL_CAMPAIGN_MUTATION } from '../graphql/mutations/create-email-campaign'
import { CREATE_SMS_CAMPAIGN_MUTATION } from '../graphql/mutations/create-sms-campaign'
import { CampaignBusinessType, ComponentSource, ComponentType } from '../enums'
import type { CampaignMessage } from '../types'
import { UPDATE_CAMPAIGN_MUTATION } from '../graphql/mutations/update-campaign'
import { convertToE164 } from '../utils/number'

export interface CampaignCreateInput {
  name: string // must be unique
  description?: string
  template?: string
  businessType: CampaignBusinessType
  smsCallbackNumber?: string // for SMS only
  senderPool?: string[] // for SMS only
  sentByAgent?: boolean // for SMS only
  componentSource?: ComponentSource // for CALL only
  delays?: number[] // for CALL only
  queuingEligible?: boolean // for CALL only
}

export interface CampaignDeleteInput {
  campaignId: string
  componentSource?: ComponentSource // for CALL only
}

export interface CampaignUpdateInput {
  componentType: ComponentType
  campaignId?: string // used to identify Email campaign
  name?: string // used to identify SMS campaign
  businessType?: CampaignBusinessType
  description?: string
  message?: CampaignMessage // for Email only
  template?: string // for SMS only
  fields?: string[] // for Email only
  componentSource?: ComponentSource // for CALL only
  delays?: number[] //for CALL only
  queuingEligible?: boolean // for CALL only
}

export interface EmailCampaignRegisterInput {
  campaignId: string // SendGrid template ID
  businessType: CampaignBusinessType
  description?: string
  message?: CampaignMessage // sender name & alias
  fields?: string[]
}

export function useCampaignMutation() {
  const client = useApolloClient()

  const [createSmsCampaignFn] = useMutation(CREATE_SMS_CAMPAIGN_MUTATION)
  const [createCallCampaignFn] = useMutation(CREATE_CALL_CAMPAIGN_MUTATION)
  const [createEmailCampaignFn] = useMutation(CREATE_EMAIL_CAMPAIGN_MUTATION)
  const [updateCampaignFn] = useMutation(UPDATE_CAMPAIGN_MUTATION)
  const [copyCampaignFn] = useMutation(COPY_CAMPAIGN_MUTATION)

  const navigate = useNavigate()

  const { showSnackbar } = useSnackbar()

  const refetchCampaign = async () =>
    client.refetchQueries({
      include: [CAMPAIGN_QUERY]
    })

  /** CREATE SMS CAMPAIGN **/
  const createSmsCampaign = async (
    campaignCreateInput: CampaignCreateInput,
    onSuccess = _.noop
  ) => {
    if (campaignCreateInput.smsCallbackNumber) {
      campaignCreateInput.smsCallbackNumber = convertToE164(campaignCreateInput.smsCallbackNumber)
    }
    if (campaignCreateInput.senderPool) {
      campaignCreateInput.senderPool = _.uniq(_.compact(campaignCreateInput.senderPool)).map(
        convertToE164
      )
    }
    const { data } = await createSmsCampaignFn({
      variables: {
        campaignCreateInput: { componentType: ComponentType.SMS, ...campaignCreateInput }
      }
    })
    const savedCampaignName = data?.createCampaign?.campaignName
    if (!savedCampaignName) return showSnackbar('Failed to create campaign', 'error')

    showSnackbar('Created campaign')
    onSuccess()
    navigate(`/campaigns/SMS/${encodeURIComponent(savedCampaignName)}`)
  }

  /** CREATE CALL SHOVEL CAMPAIGN **/
  const createCallCampaign = async (
    campaignCreateInput: CampaignCreateInput,
    onSuccess = _.noop
  ) => {
    const { data } = await createCallCampaignFn({
      variables: {
        campaignCreateInput: { componentType: ComponentType.CALL, ...campaignCreateInput }
      }
    })
    const savedCampaignId = data?.createCampaign?.campaignId
    if (!savedCampaignId) return showSnackbar('Failed to create campaign', 'error')

    showSnackbar('Created campaign')
    onSuccess()
    navigate(`/campaigns/Call/${encodeURIComponent(savedCampaignId)}`)
  }

  /** COPY CAMPAIGN **/
  const copyCampaign = async (
    sourceCampaignId,
    targetCampaignName,
    componentType,
    onSuccess = _.noop
  ) => {
    const { data } = await copyCampaignFn({
      variables: {
        campaignCopyInput: { sourceCampaignId, targetCampaignName, componentType }
      }
    })
    const copiedCampaignId = data?.copyCampaign?.campaignId
    if (!copiedCampaignId) return showSnackbar('Failed to clone campaign', 'error')

    showSnackbar('Cloned campaign')
    onSuccess()
    navigate(`/campaigns/${componentType}/${encodeURIComponent(copiedCampaignId)}`)
  }

  /** CREATE EMAIL CAMPAIGN **/
  const createEmailCampaign = async (
    emailCampaignRegisterInput: EmailCampaignRegisterInput,
    onSuccess = _.noop
  ) => {
    const { data } = await createEmailCampaignFn({ variables: { emailCampaignRegisterInput } })
    const savedCampaignId = data?.registerEmailCampaign?.campaignId
    if (!savedCampaignId) return showSnackbar('Failed to register campaign', 'error')

    showSnackbar('Registered campaign')
    onSuccess()
    navigate(`/campaigns/Email/${encodeURIComponent(savedCampaignId)}`)
  }

  /** UPDATE CAMPAIGN **/
  const updateCampaign = async (campaignUpdateInput: CampaignUpdateInput) => {
    const { data } = await updateCampaignFn({ variables: { campaignUpdateInput } })
    const savedCampaignName = data?.updateCampaign?.campaignName
    if (!savedCampaignName) return showSnackbar('Failed to update campaign', 'error')

    showSnackbar('Updated campaign')
    refetchCampaign()
  }

  return {
    createSmsCampaign,
    createCallCampaign,
    createEmailCampaign,
    updateCampaign,
    copyCampaign
  }
}
