import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Tooltip
} from '@mui/material'
import type { SxProps } from '@mui/system'
import { TaskAlt, Verified } from '@mui/icons-material'

import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { CAMPAIGN_QUERY } from '../../graphql/queries/campaign'
import { ComponentType } from '../../enums'
import { Error } from '../Error'
import type { JourneyComponent } from '../../types'
import { TEST_LEAD_ID } from '../../utils/environment-variables'
import { convertToE164, isValidPhone } from '../../utils/number'
import { getCampaignLogsUrl } from '../../utils/url'
import { isValidEmail } from '../../utils/email'
import { useJourneyMutation } from '../../hooks/useJourneyMutation'

import styles from './TestWidget.module.scss'

const SHOPPER_FIELD_NAMES_TO_IGNORE = ['email', 'telephone', 'leadId']

interface TestWidgetProps {
  component: JourneyComponent
  disabled?: boolean
  sx?: SxProps
}

export function TestWidget(props: TestWidgetProps) {
  const { component, disabled = false, sx = {} } = props
  const { componentId, componentConfig } = component

  const [content, setContent] = useState<object>({})
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [logsUrl, setLogsUrl] = useState<string>(getCampaignLogsUrl(TEST_LEAD_ID, component))

  const { testComponent } = useJourneyMutation()

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleFormChange = (key, value) => {
    const newContent = _.cloneDeep(content)
    _.set(newContent, key, value)
    setContent(newContent)
    if (key === 'shopper.leadId') setLogsUrl(getCampaignLogsUrl(value, component))
  }

  const sendTest = async () => {
    const payload = {}

    // stringify all content values as payload to send
    _.keys(content).forEach(k => {
      payload[k] = JSON.stringify(content[k])
    })

    // convert phone number to E.164 format
    const telephone = _.get(payload, 'shopper.telephone')
    if (telephone) _.set(payload, 'shopper.telephone', convertToE164(telephone))

    const success = await testComponent(componentId, payload)
    if (success) handleClose()
  }

  const isTested = !!component.testedAt
  const dialogueTitle =
    component.componentType === ComponentType.CALL ? 'Test Queuing' : 'Test Content'

  return (
    <>
      <Button
        variant='contained'
        color={isTested ? 'primary' : 'warning'}
        startIcon={isTested ? <Verified /> : <TaskAlt />}
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
        sx={sx}
      >
        {dialogueTitle}
      </Button>

      {dialogOpen && (
        <Dialog onClose={handleClose} open>
          <DialogTitle>{dialogueTitle}</DialogTitle>
          <TestContent
            componentType={component.componentType}
            campaignId={componentConfig?.campaignId}
            campaignName={componentConfig?.campaignName}
            onChange={handleFormChange}
          />
          <DialogActions className={styles.actions}>
            <Box ml={1.25}>
              <Tooltip title='Click to see campaign logs in Datadog' placement='right' arrow>
                <Link className={styles.errorLink} underline='hover' href={logsUrl} target='_blank'>
                  Looking for errors?
                </Link>
              </Tooltip>
            </Box>
            <Box>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={sendTest} disabled={!isValidContent(content)}>
                Send
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

function TestContent({ componentType, campaignId, campaignName, onChange }) {
  const { loading, error, data } = useQuery(CAMPAIGN_QUERY, {
    variables: { componentType, campaignId, campaignName },
    skip: componentType === ComponentType.CALL
  })

  let label = ''
  if (componentType === ComponentType.EMAIL)
    label = 'Enter your email address here to receive test content.'
  else if (componentType === ComponentType.SMS)
    label = 'Enter your phone number here to receive test content.'
  else if (componentType === ComponentType.CALL) label = 'Enter your Lead ID here to test queuing.'
  else return <Error message='Unsupported component type' />

  return (
    <DialogContent>
      <DialogContentText>{label}</DialogContentText>
      {componentType === ComponentType.EMAIL && (
        <EmailInput onChange={value => onChange('shopper.email', value)} />
      )}
      {componentType === ComponentType.SMS && (
        <SmsInput onChange={value => onChange('shopper.telephone', value)} />
      )}
      {componentType === ComponentType.CALL && (
        <LeadIdInput onChange={value => onChange('shopper.leadId', value)} />
      )}
      {loading ? (
        <Loading />
      ) : (
        _.map(data?.campaign?.fields, field => (
          <div key={field.name}>
            <div className={styles.fieldGroupTitle}>{field.name}</div>
            {_.map(
              _.without(
                field.fieldNames,
                ...(_.lowerFirst(field.name) === 'shopper' ? SHOPPER_FIELD_NAMES_TO_IGNORE : [])
              ),
              fieldName => (
                <TextField
                  key={fieldName}
                  id={fieldName}
                  type='text'
                  variant='filled'
                  label={fieldName}
                  size='small'
                  margin='dense'
                  onChange={e =>
                    onChange(`${_.lowerFirst(field.name)}.${fieldName}`, e.target.value)
                  }
                  fullWidth
                />
              )
            )}
          </div>
        ))
      )}
      {error && <Error errors={error as never} />}
    </DialogContent>
  )
}

function EmailInput({ onChange }) {
  const [input, setInput] = useState<string>('')
  return (
    <TextField
      id='email'
      type='email'
      variant='filled'
      label='Email Address'
      margin='dense'
      onChange={e => {
        const { value } = e.target
        setInput(value)
        onChange(value)
      }}
      error={!!input && !isValidEmail(input)}
      required
      fullWidth
      autoFocus
    />
  )
}

function LeadIdInput({ onChange }) {
  const [input, setInput] = useState<number>(TEST_LEAD_ID)

  // ensure default Lead ID gets registered in content input when this component renders
  // to let validation know there is an input (and not empty)
  useEffect(() => {
    onChange(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextField
      id='test-lead-id'
      type='number'
      variant='filled'
      label='Lead ID'
      margin='dense'
      onChange={e => {
        const { value } = e.target
        setInput(parseInt(value))
        onChange(value)
      }}
      value={input}
      required
      fullWidth
      autoFocus
    />
  )
}

function SmsInput({ onChange }) {
  const [input, setInput] = useState<string>('')
  return (
    <TextField
      id='phone'
      type='tel'
      variant='filled'
      label='Phone Number'
      margin='dense'
      onChange={e => {
        const { value } = e.target
        setInput(value)
        onChange(value)
      }}
      error={!!input && !isValidPhone(input)}
      required
      fullWidth
      autoFocus
    />
  )
}

function isValidContent(content): boolean {
  const email = _.get(content, 'shopper.email', '')
  if (email && isValidEmail(email)) return true
  const telephone = _.get(content, 'shopper.telephone', '')
  if (telephone && isValidPhone(telephone)) return true
  const leadId = _.get(content, 'shopper.leadId', '')
  return !!leadId
}
