import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input ComponentDeleteInput {
 *   journeyId: ID!
 *   componentId: ID!
 * }
 */
export const REMOVE_COMPONENT_MUTATION = gql`
  mutation removeComponent($componentDelete: ComponentDeleteInput!) {
    removeComponent(component: $componentDelete)
  }
`
