import { gql } from '@apollo/client'

/**
 * Fetches all journey names (just the names, instead of whole Journey objects)
 *
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const JOURNEY_NAMES_QUERY = gql`
  query Journeys {
    journeys {
      id
      name
    }
  }
`
