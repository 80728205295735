import { useState } from 'react'

import { Box, FormControlLabel, FormGroup, Switch, Tooltip } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

import CampaignQuery from '../../hooks/CampaignQuery'
import { CampaignSelect } from '../CampaignSelect'
import { CcaInput } from '../CcaInput'
import type { ComponentCampaign, JourneyComponent } from '../../types'
import { ComponentType } from '../../enums'
import { RedOakInput } from '../RedOakInput'
import { TestWidget } from '../TestWidget'
import { getDateOnly } from '../../utils/date'
import { useDebounce } from '../../hooks/useDebounce'
import { useJourneyMutation } from '../../hooks/useJourneyMutation'

import styles from './ComplianceConfig.module.scss'

interface ComplianceConfigProps {
  componentType: ComponentType
  component: JourneyComponent
  disabled: boolean
}

export function ComplianceConfig(props: ComplianceConfigProps) {
  const { componentType, component, disabled } = props
  const { componentId, componentConfig } = component

  const [deadlineToggle, setDeadlineToggle] = useState<boolean>(!!componentConfig?.deadline)
  const [endDate, setEndDate] = useState<string | undefined>(getDateOnly(componentConfig?.deadline))

  const { configureEmailComponent, configureSmsComponent } = useJourneyMutation()

  let configureFn = _.noop
  if (componentType === ComponentType.EMAIL) configureFn = configureEmailComponent
  if (componentType === ComponentType.SMS) configureFn = configureSmsComponent

  const save = ({
    campaign = null,
    redOakId = null,
    involvesCca = null,
    deadline = undefined
  }: {
    campaign?: ComponentCampaign | null
    redOakId?: string | null
    involvesCca?: boolean | null
    deadline?: string | null
  }) =>
    configureFn(
      componentId,
      campaign ? campaign.campaignId : componentConfig?.campaignId,
      campaign ? campaign.campaignName : componentConfig?.campaignName,
      redOakId !== null ? redOakId : componentConfig?.redOakId,
      involvesCca !== null ? involvesCca : componentConfig?.involvesCca,
      deadline === undefined ? componentConfig?.deadline : deadline // accept null value
    )

  const handleToggle = checked => {
    if (endDate !== undefined) save({ deadline: checked ? endDate : null })
    setDeadlineToggle(checked)
  }

  // debounce on termination date change (to prevent race condition when typing by keyboard)
  useDebounce(() => save({ deadline: endDate }), [endDate], disabled)

  // still show the deprecated CCA toggle for old journeys that already have this turned on
  const showDeprecatedCcaInput = !!componentConfig?.involvesCca

  const isTerminated = disabled && endDate && dayjs().isAfter(endDate, 'day')

  return (
    <>
      <Box mr={1}>Send {componentType} for:</Box>
      <CampaignQuery componentType={componentType}>
        {({ campaigns }) => {
          return (
            <div className={styles.campaign}>
              <CampaignSelect
                campaigns={campaigns}
                value={componentConfig?.campaignId}
                onChange={campaign => save({ campaign })}
                disabled={disabled}
              />

              {componentConfig?.campaignId && (
                <div className={styles.extraConfig}>
                  <RedOakInput
                    value={componentConfig?.redOakId}
                    onChange={redOakId => save({ redOakId })}
                    disabled={disabled}
                  />
                  {showDeprecatedCcaInput && (
                    <CcaInput
                      checked={componentConfig?.involvesCca}
                      onChange={involvesCca => save({ involvesCca })}
                      disabled={disabled}
                    />
                  )}
                  {!disabled && <TestWidget component={component} />}
                </div>
              )}

              {componentConfig?.campaignId && (
                <div className={styles.terminationConfig}>
                  <Tooltip
                    title="You can specify a date after which this action will be skipped. Other actions in this journey won't be affected."
                    placement='bottom-start'
                    arrow
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={deadlineToggle}
                            onChange={e => handleToggle(e.target.checked)}
                            color={isTerminated ? 'warning' : 'primary'}
                            disabled={disabled}
                          />
                        }
                        label={
                          `Terminate${isTerminated ? 'd' : ''} on` +
                          (deadlineToggle ? ':' : ' a date')
                        }
                      />
                    </FormGroup>
                  </Tooltip>

                  {deadlineToggle && (
                    <DatePicker
                      className={styles.datePicker}
                      value={dayjs(endDate || '')}
                      onChange={date => setEndDate((date as Dayjs).format('YYYY-MM-DD'))}
                      autoFocus={_.isEmpty(endDate)}
                      disabled={disabled}
                      disablePast
                    />
                  )}
                </div>
              )}
            </div>
          )
        }}
      </CampaignQuery>
    </>
  )
}
