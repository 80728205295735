import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input ComponentTestInput {
 *   journeyId: ID!                // ID of the journey
 *   componentId: ID!              // ID of the component to test
 *   agent: String = null          // JSON test input for agent fields
 *   metadata: String = null       // JSON test input for metadata fields
 *   policy: String = null         // JSON test input for policy fields
 *   policyCarrier: String = null  // JSON test input for policyCarrier fields
 *   shopper: String = null        // JSON test input for shopper fields
 * }
 */
export const TEST_COMPONENT_MUTATION = gql`
  mutation testComponent($componentTest: ComponentTestInput!) {
    testComponent(componentTest: $componentTest)
  }
`
