import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input DelayComponentConfigInput {
 *   journeyId: ID!
 *   componentId: ID!
 *   campaignName: String!
 *   days: Int! = 0
 *   hours: Int! = 0
 *   minutes: Int! = 0
 *   targetedDelay: TargetedDelay = null
 *   customHoursWindow: [Int!] = null
 * }
 */
export const CONFIGURE_DELAY_MUTATION = gql`
  mutation ConfigureDelayComponent($delayConfig: DelayComponentConfigInput!) {
    configureDelayComponent(delayConfig: $delayConfig) {
      componentId
    }
  }
`
