import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'

import _ from 'lodash'
import clsx from 'clsx'
import dayjs from 'dayjs'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { CampaignBusinessType, ComponentType } from '../enums'
import type { CampaignField, CampaignMessage, ComponentCampaign } from '../types'
import CampaignQuery from '../hooks/CampaignQuery'
import { CloneButton } from '../components/CloneButton'
import { DynamicVariables } from '../components/DynamicVariables'
import { EmailCampaignRegisterInput, useCampaignMutation } from '../hooks/useCampaignMutation'
import UnregisteredTemplateQuery from '../hooks/UnregisteredTemplateQuery'
import { isValidAlias } from '../utils/campaign'
import { useDebounce } from '../hooks/useDebounce'
import { useGlobalState } from '../store'

import styles from './EmailCampaign.module.scss'

const DEFAULT_SENDER_NAME = 'Assurance Customer Care'
const DEFAULT_SENDER_ALIAS = 'customercare'

export function EmailCampaign() {
  const { campaignId } = useParams()
  if (!campaignId) return <Main />
  return (
    <CampaignQuery componentType={ComponentType.EMAIL} campaignId={campaignId} noCache>
      {({ campaigns }) => {
        if (_.isEmpty(campaigns)) {
          return <Alert severity='warning'>No matching campaign found.</Alert>
        }
        const campaign = campaigns.pop()
        return <Main campaign={campaign} />
      }}
    </CampaignQuery>
  )
}

function Main({ campaign }: { campaign?: ComponentCampaign }) {
  const [templateId, setTemplateId] = useState<string>(campaign?.campaignId || '')
  const [businessType, setBusinessType] = useState<CampaignBusinessType>(
    campaign?.businessType || CampaignBusinessType.MARKETING
  )
  const [description, setDescription] = useState<string>(campaign?.description || '')
  const [senderDisplayName, setSenderDisplayName] = useState<string>(
    campaign?.message?.senderDisplayName || DEFAULT_SENDER_NAME
  )
  const [senderUserName, setSenderUserName] = useState<string>(
    campaign?.message?.senderUserName || DEFAULT_SENDER_ALIAS
  )
  const [fields, setFields] = useState<string[]>(
    _.isEmpty(campaign?.fields) ? [] : flattenFields(campaign?.fields)
  )

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const { createEmailCampaign, updateCampaign, copyCampaign } = useCampaignMutation()

  const disableEdit = !hasEditPermission
  const hasInvalidAlias = !!senderUserName && !isValidAlias(senderUserName)

  const renderDynamicVariables = fieldList => (
    <DynamicVariables
      fieldList={fieldList}
      selectedFields={fields}
      onChange={setFields}
      disabled={disableEdit}
    />
  )

  // auto-save changes when editing campaign
  useDebounce(
    () =>
      updateCampaign({
        componentType: ComponentType.EMAIL,
        campaignId: campaign?.campaignId,
        businessType,
        description,
        message: { senderDisplayName, senderUserName },
        fields
      }),
    [businessType, description, senderDisplayName, senderUserName, fields],
    !campaign || !hasEditPermission
  )

  const cloneCampaign = targetName => {
    return copyCampaign(campaign?.campaignId, targetName, ComponentType.CALL)
  }

  const create = () => {
    let payload: EmailCampaignRegisterInput = { campaignId: templateId, businessType }
    // optional fields
    if (description) payload = { ...payload, description }
    if (senderDisplayName || senderUserName) {
      const message: CampaignMessage = {}
      if (senderDisplayName) message.senderDisplayName = senderDisplayName
      if (senderUserName) message.senderUserName = senderUserName
      payload = { ...payload, message }
    }
    if (!_.isEmpty(fields)) payload = { ...payload, fields }
    return createEmailCampaign(payload)
  }

  const author = campaign?.updatedBy || campaign?.createdBy
  const journeyId = campaign?.journeyId
  const journeyName = campaign?.journeyName

  return (
    <div className={clsx(styles.root, 'animate__animated animate__fadeIn')}>
      <div className={styles.header}>
        <Breadcrumbs
          root='Email Campaigns'
          title={campaign?.campaignName || 'New Email Campaign'}
        />
        {campaign && (
          <div className={styles.right}>
            <div className={styles.metadata}>
              <div>Version {campaign.version}</div>
              {(campaign.updatedTimestamp || author) && (
                <div>
                  Updated
                  {campaign.updatedTimestamp &&
                    ` at ${dayjs(campaign.updatedTimestamp).format('MM/DD/YYYY @h:mma')}`}
                  {author && (
                    <>
                      {' by '}
                      <span className={styles.author}>{author}</span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Box mt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <UnregisteredTemplateQuery mockResults={campaign}>
          {({ templates }) => {
            return (
              <FormControl
                sx={{ minWidth: 300 }}
                disabled={!!campaign || !hasEditPermission}
                required
              >
                <InputLabel id='template-select-label'>SendGrid Template</InputLabel>
                <Select
                  labelId='template-select-label'
                  id='template-select'
                  value={templateId}
                  label='SendGrid Template'
                  placeholder='Select a template'
                  onChange={e => setTemplateId(e.target.value)}
                  autoWidth
                >
                  {templates.map(template => (
                    <MenuItem key={template.campaignId} value={template.campaignId}>
                      {template.campaignName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }}
        </UnregisteredTemplateQuery>

        {campaign && (
          <CloneButton
            label='campaign'
            defaultName={campaign.campaignName}
            onConfirm={cloneCampaign}
            disabled={!hasEditPermission}
          />
        )}
      </Box>

      <Box mt={2}>
        <TextField
          id='description'
          label='Description'
          variant='outlined'
          value={description}
          onChange={e => setDescription(e.target.value)}
          autoComplete='off'
          disabled={disableEdit}
          fullWidth
        />
      </Box>

      <FormControl sx={{ mt: 4, ml: 1 }} disabled={disableEdit} required>
        <FormLabel id='outreach-type-radio'>Outreach Type</FormLabel>
        <RadioGroup
          aria-labelledby='outreach-type-radio'
          name='outreach-type'
          value={businessType}
          onChange={e => setBusinessType(e.target.value as CampaignBusinessType)}
          row
        >
          <FormControlLabel
            value={CampaignBusinessType.MARKETING}
            control={<Radio />}
            label='Marketing'
          />
          <FormControlLabel
            value={CampaignBusinessType.INFORMATIONAL}
            control={<Radio />}
            label='Informational'
          />
          <FormControlLabel
            value={CampaignBusinessType.TRANSACTIONAL}
            control={<Radio />}
            label='Transactional'
          />
        </RadioGroup>
      </FormControl>

      <Box mt={4}>
        <TextField
          id='sender-name'
          label='Sender Name'
          variant='outlined'
          value={senderDisplayName}
          onChange={e => setSenderDisplayName(e.target.value)}
          placeholder={DEFAULT_SENDER_NAME}
          autoComplete='off'
          inputProps={{ maxLength: 50 }}
          disabled={disableEdit}
          sx={{ minWidth: 300 }}
        />
        <TextField
          id='sender-alias'
          label='Sender Alias'
          variant='outlined'
          value={senderUserName}
          onChange={e => setSenderUserName(e.target.value)}
          error={hasInvalidAlias}
          placeholder={DEFAULT_SENDER_ALIAS}
          autoComplete='off'
          inputProps={{ maxLength: 25 }}
          disabled={disableEdit}
          sx={{ ml: 2, minWidth: 300 }}
        />
      </Box>
      <Box mt={1} ml={1}>
        <Typography variant='caption' display='block'>
          Email signature will appear as follows:
        </Typography>
        <Typography variant='caption' display='block'>
          From:{' '}
          <b>
            {senderDisplayName || DEFAULT_SENDER_NAME}{' '}
            {`<${senderUserName || DEFAULT_SENDER_ALIAS}@info-mail.assurance.com>`}
          </b>
        </Typography>
        <Typography variant='caption' display='block'>
          Reply to:{' '}
          <b>
            {senderDisplayName || DEFAULT_SENDER_NAME}{' '}
            {`<${senderUserName || DEFAULT_SENDER_ALIAS}@assurance.com>`}
          </b>
        </Typography>
      </Box>

      {templateId && (
        <Box mt={4}>
          {campaign ? (
            renderDynamicVariables(campaign.allFields)
          ) : (
            <UnregisteredTemplateQuery templateId={templateId}>
              {({ templates }) => {
                const template = templates.pop()
                return renderDynamicVariables(template?.allFields)
              }}
            </UnregisteredTemplateQuery>
          )}
        </Box>
      )}

      <Box mt={4}>
        {!campaign && (
          <Button
            variant='contained'
            color='primary'
            onClick={create}
            disabled={!hasEditPermission || !templateId || hasInvalidAlias}
          >
            CREATE CAMPAIGN
          </Button>
        )}
      </Box>

      {journeyId && (
        <Box mt={3}>
          <Alert severity='warning'>
            This campaign is already being used in the journey:{' '}
            <Link component={RouterLink} to={`/journeys/${journeyId}`}>
              {journeyName}
            </Link>
          </Alert>
        </Box>
      )}
    </div>
  )
}

function flattenFields(fields: CampaignField[] = []) {
  return _.flatMap(fields, o => _.map(o.fieldNames, field => `${_.lowerFirst(o.name)}.${field}`))
}
