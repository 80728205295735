import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input EmailComponentConfigInput {
 *   journeyId: ID!
 *   componentId: ID!
 *   campaignId: String!
 *   campaignName: String!
 *   redOakId: String = null
 *   involvesCca: Boolean! = false
 * }
 */
export const CONFIGURE_EMAIL_MUTATION = gql`
  mutation ConfigureEmailComponent($emailConfig: EmailComponentConfigInput!) {
    configureEmailComponent(emailConfig: $emailConfig) {
      ...ComponentFragment
    }
  }
  ${COMPONENT_FRAGMENT}
`
