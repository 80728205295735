import { gql } from '@apollo/client'

export const CAMPAIGN_FRAGMENT = gql`
  fragment CampaignFragment on ComponentCampaign {
    campaignId
    campaignName
    version
    description
    updatedTimestamp
    events
    fields {
      name
      fieldNames
    }
    template
    provider
    providerCampaignId
    businessType
    smsCallbackNumber
    approvalStatus
    message {
      senderUserName
      senderDisplayName
    }
    allFields
    senderPool
    createdBy
    updatedBy
    sentByAgent
    delays
    journeyId
    journeyName
    queuingEligible
  }
`
