import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyEditInput {
 *   journeyId: ID!
 *   description: String = ""
 *   version: String = ""
 *
 *   """Type of trigger to start journey"""
 *   journeyStartTrigger: JourneyStartTrigger = null
 *
 *   """Daily journey start limit for batch journeys"""
 *   batchDailyLimit: Int = null
 *
 *   """
 *   Daily journey start threshold for alerting.
 *   null indicates do not update.
 *   -1 indicates no alert threshold.
 *   """
 *   alertDailyThreshold: Int = null
 *
 *   """
 *   New journey end rules to apply.
 *   null indicates do not update.
 *   Otherwise, the list overwrites the entire rules. For example, [] clears the rules.
 *   """
 *   journeyEndRules: [JourneyEndRuleInput!] = null
 *
 *   """
 *   New tags to apply. null indicates do not update. Otherwise, the list overwrites the tags. For example, [] clears the tags.
 *   """
 *   tags: [String!] = null
 *
 *   """Journey experiments"""
 *   routingExperiments: [JourneyRoutingExperimentInput!] = null
 * }
 *
 * input JourneyEndRuleInput {
 *   """Journey end trigger event."""
 *   triggerEvent: TriggerEvent!
 *
 *   """Journey end date when trigger_event is deadline_expired"""
 *   deadline: DateTime = null
 *
 *   """Policy sold or not when trigger_event is shopper_spoke_with_agent"""
 *   policySold: Boolean = null
 *
 *   """Optionally specify the next journey id to start upon event trigger"""
 *   nextJourneyId: ID = null
 * }
 *
 * input JourneyRoutingExperimentInput {
 *   """Journey name to route the traffic"""
 *   name: String!
 *
 *   """Percentage of traffic to route to this journey"""
 *   traffic: Int!
 * }
 */
export const EDIT_JOURNEY_MUTATION = gql`
  mutation editJourney($journeyUpdate: JourneyEditInput!) {
    editJourney(journeyUpdate: $journeyUpdate) {
      id
    }
  }
`
