import { gql } from '@apollo/client'

export const COMPONENT_FRAGMENT = gql`
  fragment ComponentFragment on JourneyComponent {
    componentId
    componentName
    componentType
    testedAt
    testedBy
    fromLinks {
      componentId
      componentName
      componentType
    }
    toLinks {
      componentId
      componentName
      componentType
    }
    componentConfig {
      campaignId
      campaignName
      ... on DelayComponentConfig {
        days
        hours
        minutes
        targetedDelay
        customHoursWindow
      }
      ... on SMSComponentConfig {
        redOakId
        involvesCca
        deadline
      }
      ... on EmailComponentConfig {
        redOakId
        involvesCca
        deadline
      }
      ... on DialComponentConfig {
        deadline
        nextActionOnEnd
      }
    }
  }
`
