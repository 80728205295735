import {
  Action,
  Computed,
  action,
  computed,
  createStore as createStoreEasyPeasy,
  createTypedHooks,
  persist
} from 'easy-peasy'
import type { User } from '@auth0/auth0-spa-js'
import _ from 'lodash'

import { ComponentType, LineOfInsurance } from './enums'

/** App Model **/

interface AppModel {
  accessToken: string | null
  setAccessToken: Action<AppModel, string | null>

  user: User | null
  setUser: Action<AppModel, User | null>
  hasEditPermission: Computed<User, boolean>
  name: Computed<User, string>
}

const appModel: AppModel = {
  accessToken: sessionStorage.getItem('accessToken'),
  setAccessToken: action((state, accessToken) => {
    if (!accessToken) {
      sessionStorage.removeItem('accessToken')
    } else {
      sessionStorage.setItem('accessToken', accessToken)
    }
    state.accessToken = accessToken
  }),

  user: null,
  setUser: action((state, user) => {
    state.user = user
  }),
  hasEditPermission: computed(state => {
    const roles = state.user?.['https://assurance.com/roles'] || []
    return (
      roles.includes('admin') ||
      roles.includes('developer') ||
      roles.includes('journey_composer.admin') ||
      roles.includes('journey_composer.editor')
    )
  }),
  name: computed(state => {
    const { user } = state
    if (!user) return 'User'
    return user.given_name || _.upperFirst(user.nickname) || user.name || user.email
  })
}

/** Settings Model (Note: These are persisted via localStorage) **/

interface SettingsModel {
  // filters for Journeys (Home) page
  searchByTags: string[]
  setSearchByTags: Action<SettingsModel, string[]>

  showCreatedByMe: boolean
  setShowCreatedByMe: Action<SettingsModel, boolean>

  showCreatedByOthers: boolean
  setShowCreatedByOthers: Action<SettingsModel, boolean>

  showActive: boolean
  setShowActive: Action<SettingsModel, boolean>

  showInactive: boolean
  setShowInactive: Action<SettingsModel, boolean>

  showArchived: boolean
  setShowArchived: Action<SettingsModel, boolean>

  journeyLoisFilter: LineOfInsurance[]
  setJourneyLoisFilter: Action<SettingsModel, LineOfInsurance[]>

  journeyCampaignsFilter: ComponentType[]
  setJourneyCampaignsFilter: Action<SettingsModel, ComponentType[]>

  showTrafficSplit: boolean
  setShowTrafficSplit: Action<SettingsModel, boolean>

  // filters for Campaigns page
  campaignTypeToShow: ComponentType
  setCampaignTypeToShow: Action<SettingsModel, ComponentType>
}

const settingsModel: SettingsModel = {
  // filters for Journeys (Home) page
  showCreatedByMe: true,
  setShowCreatedByMe: action((state, showCreatedByMe) => {
    state.showCreatedByMe = showCreatedByMe
  }),

  showCreatedByOthers: true,
  setShowCreatedByOthers: action((state, showCreatedByOthers) => {
    state.showCreatedByOthers = showCreatedByOthers
  }),

  showActive: true,
  setShowActive: action((state, showActive) => {
    state.showActive = showActive
  }),

  showInactive: false,
  setShowInactive: action((state, showInactive) => {
    state.showInactive = showInactive
  }),

  showArchived: false,
  setShowArchived: action((state, showArchived) => {
    state.showArchived = showArchived
  }),

  journeyLoisFilter: [],
  setJourneyLoisFilter: action((state, journeyLoisFilter) => {
    state.journeyLoisFilter = journeyLoisFilter
  }),

  journeyCampaignsFilter: [],
  setJourneyCampaignsFilter: action((state, journeyCampaignsFilter) => {
    state.journeyCampaignsFilter = journeyCampaignsFilter
  }),

  searchByTags: [],
  setSearchByTags: action((state, searchByTags) => {
    state.searchByTags = searchByTags
  }),

  showTrafficSplit: false,
  setShowTrafficSplit: action((state, showTrafficSplit) => {
    state.showTrafficSplit = showTrafficSplit
  }),

  // filters for Campaigns page
  campaignTypeToShow: ComponentType.SMS,
  setCampaignTypeToShow: action((state, campaignTypeToShow) => {
    state.campaignTypeToShow = campaignTypeToShow
  })
}

/** Store Model (final assembly) **/

interface StoreModel {
  app: AppModel
  settings: SettingsModel
}

// create typed hooks to avoid having to constantly provide StoreModel definition in the app
// use these instead of the native hooks from easy-peasy library
const typedHooks = createTypedHooks<StoreModel>()
export const useGlobalActions = typedHooks.useStoreActions
export const useGlobalState = typedHooks.useStoreState

export const createStore = function (defaults?: StoreModel) {
  const model = {
    app: appModel,
    settings: persist(settingsModel, { storage: 'localStorage' })
  }

  if (defaults) {
    _.merge(model.app, defaults.app)
    _.merge(model.settings, defaults.settings)
  }

  return createStoreEasyPeasy<StoreModel>(model)
}
