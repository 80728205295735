import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyUnarchiveInput {
 *   journeyId: ID!
 * }
 */
export const UNARCHIVE_JOURNEY_MUTATION = gql`
  mutation UnarchiveJourney($unArchiveInput: JourneyUnarchiveInput!) {
    unarchiveJourney(journeyUnarchive: $unArchiveInput)
  }
`
