import { Avatar, Box, Chip, Tooltip } from '@mui/material'

import { ComponentSource, ComponentType } from '../../enums'
import { loiFromName } from '../../utils/campaign'

const LOOKER_QID = 'W5bbWbe7hKsgSVCMd0oMSp'
const LOOKER_BASE_URL = `https://looker.assurance.com/explore/assurance/conversations_rel_silver?qid=${LOOKER_QID}&origin_space=2716&toggle=fil`

const COMPONENT_TO_FIELD_NAME = {
  [ComponentType.CALL + '-' + ComponentSource.Shovel]:
    '&f[conversation_first_last_information.first_call_campaign_name]',
  [ComponentType.CALL]: '&f[conversation_first_last_information.first_call_campaign_name]'
}

interface LookerLinkProps {
  campaignName: string
  campaignType: string
  leadType?: string
}

export function LookerLink(props: LookerLinkProps) {
  const { campaignName, campaignType, leadType } = props

  if (!(campaignType in COMPONENT_TO_FIELD_NAME)) {
    return null
  }

  const finalLeadType = leadType || loiFromName(campaignName)
  const urlParams = `${COMPONENT_TO_FIELD_NAME[campaignType]}=${campaignName}${
    finalLeadType ? `&f[lead_meta_data.lead_type]=${finalLeadType}` : ''
  }`

  return (
    <Tooltip title='See campaign performance in Looker' placement='right' arrow>
      <Chip
        size='small'
        variant='outlined'
        onClick={e => {
          e.stopPropagation()
        }}
        label={
          <Box onClick={() => window.open(LOOKER_BASE_URL + urlParams, '_blank')}>
            <Avatar alt='Looker' src='/images/looker.svg' />
          </Box>
        }
      ></Chip>
    </Tooltip>
  )
}
