import { DependencyList, useEffect, useRef } from 'react'

import { useSnackbar } from '@assuranceiq/react-components'

const DEBOUNCE_MS = 750

export function useDebounce(
  fn: () => void,
  deps: DependencyList = [],
  disabled = false,
  delay = DEBOUNCE_MS
) {
  const didMountRef = useRef(false)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true // skip on mount
      return
    }
    if (disabled) return
    if (delay > 0) showSnackbar('Saving changes...', 'info')
    const debounce = setTimeout(fn, delay)
    return () => clearTimeout(debounce)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
