import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input ComponentMove {
 *   journeyId: ID!
 *   componentId: ID!
 *   delta: Int!
 * }
 */
export const MOVE_COMPONENT_MUTATION = gql`
  mutation moveComponent($componentInput: ComponentMove!) {
    moveComponent(componentInput: $componentInput)
  }
`
