import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input EmailCampaignRegisterInput {
 *   """Campaign id"""
 *   campaignId: ID!
 *
 *   """business_type: Informational or Marketing"""
 *   businessType: CampaignBusinessType!
 *
 *   """Email campaign description"""
 *   description: String = null
 *
 *   """Message content"""
 *   message: Message = null
 *
 *   """List of fields from the template"""
 *   fields: [String] = null
 * }
 */
export const CREATE_EMAIL_CAMPAIGN_MUTATION = gql`
  mutation RegisterEmailCampaign($emailCampaignRegisterInput: EmailCampaignRegisterInput!) {
    registerEmailCampaign(emailCampaignRegisterInput: $emailCampaignRegisterInput) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
