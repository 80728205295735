import { Link as RouterLink } from 'react-router-dom'

import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { Logout } from '@mui/icons-material'

import clsx from 'clsx'
import { useAuth0 } from '@auth0/auth0-react'

import { NavMenu } from '../components/NavMenu'
import { useGlobalState } from '../store'

import styles from './Layout.module.scss'

interface LayoutProps {
  children: React.ReactNode
}

export function Layout({ children }: LayoutProps) {
  console.info('Rendering Layout')
  return (
    <div className={styles.root}>
      <Header />
      <main>{children}</main>
    </div>
  )
}

function Header() {
  const { logout } = useAuth0()

  const username = useGlobalState(state => state.app.name)
  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const location = window.document.location

  const doLogout = () => {
    if (window.confirm('You are about to log out. Proceed?')) {
      logout({ returnTo: location.origin })
    }
  }

  return (
    <AppBar
      position='relative'
      color='default'
      className={clsx(styles.appBar, 'animate__animated animate__fadeIn')}
      style={{
        // inline here to reduce FOUC
        // https://en.wikipedia.org/wiki/Flash_of_unstyled_content
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fafafa'
      }}
    >
      <RouterLink className={styles.logo} to='/'>
        <img src='/images/assurance-logo.svg' alt='Assurance logo' />
        <span>Journey Composer</span>
        {!hasEditPermission && (
          <Typography variant='caption' sx={{ ml: 2 }}>
            You have restricted permissions (READ ONLY)
          </Typography>
        )}
      </RouterLink>

      <NavMenu />

      <Toolbar className={styles.toolbar}>
        <Box display='flex' alignItems='center' className='animate__animated animate__slideInRight'>
          <Box className={styles.username} mr={1}>
            Welcome, {username}
          </Box>
          <Tooltip title='Logout' arrow>
            <IconButton onClick={doLogout} color='info'>
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
