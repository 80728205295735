import _ from 'lodash'

import { ComponentType } from '../enums'
import type { Journey } from '../types'
import { getDelaysBeforeAnotherSms, validDelay } from './delay'
import { isValidRedOakId } from './number'

export function validateJourney(journey: Journey | undefined): boolean {
  if (!journey) return false

  let error = ''

  // validate compliance input
  journey.components
    .filter(o => [ComponentType.EMAIL, ComponentType.SMS].includes(o.componentType))
    .forEach(component => {
      if (!component.testedAt) {
        error = '🛑 Please test content before publishing the journey.'
      }
      const redOakId = component.componentConfig?.redOakId || ''
      if (!isValidRedOakId(redOakId)) {
        error = '🛑 Please enter a valid RedOak ID.'
      }
    })

  // validate campaign input
  journey.components
    .filter(o =>
      [ComponentType.TODO, ComponentType.CALL, ComponentType.EMAIL, ComponentType.SMS].includes(
        o.componentType
      )
    )
    .forEach(component => {
      if (!component.componentConfig?.campaignId) {
        error = '🛑 Please select a campaign for the action.'
      }
    })

  // validate SMS component
  const smsComponents = journey.components.filter(o => o.componentType === ComponentType.SMS)

  if (smsComponents.length > 1) {
    smsComponents.forEach(component => {
      const beforeComponent = _.first(component.fromLinks)
      if (beforeComponent?.componentName === ComponentType.START) return
      if (beforeComponent?.componentType === ComponentType.SMS) {
        error = '🛑 A delay of at least 3 days must be added between SMS actions'
        return
      }

      const delaysBeforeAnotherSms = getDelaysBeforeAnotherSms(
        journey,
        beforeComponent?.componentId,
        []
      )
      if (!_.isEmpty(delaysBeforeAnotherSms) && !validDelay(delaysBeforeAnotherSms)) {
        error = '🛑 A delay of at least 3 days must be added between SMS actions'
        return
      }
    })
  }

  if (error) {
    alert(error)
    return false
  }

  return true
}
