import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyPublishInput {
 *   journeyId: ID!
 * }
 */
export const PUBLISH_JOURNEY_MUTATION = gql`
  mutation publishJourney($journeyPublish: JourneyPublishInput!) {
    publishJourney(journeyPublish: $journeyPublish) {
      id
    }
  }
`
