import React, { useEffect, useState } from 'react'

import { AddCircle, RemoveCircle, Star, StarOutline } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'

import _ from 'lodash'

import type { Journey, JourneyRoutingExperiment } from '../../types'
import { NumberInput } from '../NumberInput'

const MAX_TRAFFIC_VALUE = 100

interface JourneyTrafficInputProps {
  masterJourneyName: string // name of journey you're splitting traffic from
  NamesQuery: React.ElementType
  values?: JourneyRoutingExperiment[]
  onChange?: (experiments: JourneyRoutingExperiment[]) => void
  disabled?: boolean
}

export function JourneyTrafficInput(props: JourneyTrafficInputProps) {
  const { masterJourneyName, NamesQuery, values = [], onChange = _.noop, disabled = false } = props

  const [toggleChecked, setToggleChecked] = useState<boolean>(!_.isEmpty(values))
  const [experiments, setExperiments] = useState<JourneyRoutingExperiment[]>(
    values.map(o => {
      // we need to assign unique IDs here (because server doesn't provide one)
      // in order to feed React necessary 'key' props for dynamic rows to render correctly
      const v = _.clone(o)
      if (!v.uniqueId) v.uniqueId = _.uniqueId()
      return v
    })
  )

  const getTrafficRemainder = (newExperiments?) => {
    return _.reduce(
      newExperiments || experiments,
      (traffic, o) => traffic - o.traffic,
      MAX_TRAFFIC_VALUE
    )
  }

  // ensure the master journey always shows at the top
  useEffect(() => {
    const needsMaster = !_.find(experiments, o => o.name === masterJourneyName)
    if (needsMaster) {
      const masterExperiment = {
        traffic: getTrafficRemainder(),
        name: masterJourneyName,
        uniqueId: _.uniqueId(),
        sendNextShopper: false
      }
      setExperiments(_.concat([masterExperiment], experiments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments])

  const handleChange = (newExperiments: JourneyRoutingExperiment[]) => {
    setExperiments(newExperiments)

    // validate final input
    if (!_.isEmpty(newExperiments) && getTrafficRemainder(newExperiments) !== 0) return
    if (_.filter(newExperiments, o => !o.name).length > 0) return

    // should exclude master journey from API payload (as well as internal keys)
    onChange(
      _.filter(newExperiments, o => o.name !== masterJourneyName).map(o =>
        _.omit(o, ['uniqueId', '__typename'])
      )
    )
  }

  const handleToggle = e => {
    const checked = e.target.checked
    setToggleChecked(checked)
    if (!checked) {
      handleChange([]) // nuke values on un-checked
    }
  }

  const addNewExperiment = () => {
    const newExperiment = {
      traffic: getTrafficRemainder(),
      name: '',
      uniqueId: _.uniqueId(),
      sendNextShopper: false
    }
    setExperiments([...experiments, newExperiment])
  }

  const handleNextShopperJourneyChange = (journeyName: string) => {
    // reset 'sendNextShopper' to false for all other experiments
    const newExperiments = experiments.map(exp => {
      exp.sendNextShopper = exp.name === journeyName
      return exp
    })
    handleChange(newExperiments)
  }

  return (
    <Box display='flex' flexDirection='column'>
      <FormGroup sx={{ mb: 0.5, width: 'fit-content' }}>
        <FormControlLabel
          control={<Switch checked={toggleChecked} onChange={handleToggle} disabled={disabled} />}
          label='Split Journey Traffic'
        />
      </FormGroup>

      {toggleChecked && (
        <Box>
          <NamesQuery>
            {({ loading, journeys }) => {
              const sortedJourneys = _.sortBy(journeys, 'name') as Journey[]
              return (
                <>
                  {experiments.map(experiment => (
                    <InputRow
                      key={experiment.uniqueId}
                      experiments={experiments}
                      masterJourneyName={masterJourneyName}
                      journeys={sortedJourneys}
                      loading={loading}
                      value={experiment}
                      onChange={experiment => {
                        const newExperiments = _.cloneDeep(experiments)
                        const indexToChange = _.findIndex(newExperiments, {
                          uniqueId: experiment.uniqueId
                        })

                        newExperiments[indexToChange] = experiment
                        handleChange(newExperiments)
                      }}
                      onDelete={() => {
                        const newExperiments = _.cloneDeep(experiments)
                        const indexToDelete = _.findIndex(newExperiments, {
                          uniqueId: experiment.uniqueId
                        })
                        _.pullAt(newExperiments, indexToDelete)
                        handleChange(newExperiments)
                      }}
                      onStar={handleNextShopperJourneyChange}
                      onUnstar={() => handleNextShopperJourneyChange('')}
                      error={getTrafficRemainder() !== 0}
                      disabled={disabled}
                    />
                  ))}

                  <Button
                    onClick={addNewExperiment}
                    color='success'
                    startIcon={<AddCircle color={disabled ? 'disabled' : 'success'} />}
                    disabled={disabled}
                    sx={{ mt: 0.5, width: 'fit-content' }}
                  >
                    Add another journey
                  </Button>
                </>
              )
            }}
          </NamesQuery>
        </Box>
      )}
    </Box>
  )
}

function InputRow({
  experiments,
  masterJourneyName,
  journeys = [],
  loading = false,
  value,
  onChange,
  onDelete,
  onStar,
  onUnstar,
  error,
  disabled
}: {
  experiments: JourneyRoutingExperiment[]
  masterJourneyName: string
  journeys: Journey[]
  loading: boolean
  value: JourneyRoutingExperiment
  onChange: (experiment: JourneyRoutingExperiment) => void
  onDelete: () => void
  onStar: (journeyName: string) => void
  onUnstar: () => void
  error: boolean
  disabled: boolean
}) {
  const masterJourneyUniqueId = _.find(experiments, o => o.name === masterJourneyName)?.uniqueId
  const isMasterJourney = value.uniqueId === masterJourneyUniqueId

  const journeyNames = _.map(journeys, 'name')

  return (
    <Box display='flex' alignItems='center' mt={1.5}>
      <NumberInput
        label='% of Traffic'
        inputProps={{ max: MAX_TRAFFIC_VALUE, maxLength: 2 }}
        value={value.traffic}
        onChange={traffic => {
          value.traffic = traffic
          onChange(value)
        }}
        error={error}
        tooltip={error ? `Traffic percentages must add up to a total of ${MAX_TRAFFIC_VALUE}` : ''}
        sx={{ width: '10ch', mr: 1 }}
        disabled={disabled}
      />

      <Autocomplete
        options={journeyNames}
        getOptionDisabled={journeyName => !!_.find(experiments, o => o.name === journeyName)}
        noOptionsText='No journeys found'
        loading={loading}
        loadingText='Loading journey names...'
        renderInput={params => (
          <TextField
            {...params}
            label={isMasterJourney ? 'This Journey' : 'Journey Name'}
            error={!value.name}
          />
        )}
        value={value.name}
        onChange={(_, journeyName) => {
          value.name = journeyName || ''
          onChange(value)
        }}
        sx={{ minWidth: '35rem' }}
        disabled={disabled || isMasterJourney}
        disablePortal
        autoHighlight
      />

      {!isMasterJourney && (
        <>
          <Tooltip title='Delete' placement='top'>
            <Box ml={0.5}>
              <IconButton onClick={onDelete} disabled={disabled}>
                <RemoveCircle color={disabled ? 'disabled' : 'error'} fontSize='small' />
              </IconButton>
            </Box>
          </Tooltip>

          {value.name && (
            <Tooltip title='Send next shopper' placement='top'>
              <Box ml={-0.75} mr={1}>
                {value.sendNextShopper ? (
                  <IconButton onClick={onUnstar} disabled={disabled}>
                    <Star color={disabled ? 'disabled' : 'warning'} fontSize='small' />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      window.confirm(
                        'You can send the next shopper to this journey. As soon as the first shopper goes through it, journey traffic will be split randomly. Proceed?'
                      ) && onStar(value.name)
                    }
                    disabled={disabled}
                  >
                    <StarOutline color={disabled ? 'disabled' : 'warning'} fontSize='small' />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  )
}
