import { Link as RouterLink } from 'react-router-dom'

import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import type { ComponentCampaign } from '../../types'
import { ComponentType } from '../../enums'
import { LookerLink } from '../LookerLink'
import { getIcon } from '../ComponentNode'

import styles from './CampaignListItem.module.scss'

dayjs.extend(relativeTime)

interface CampaignListItemProps {
  campaignType: ComponentType
  campaign: ComponentCampaign
}

export function CampaignListItem(props: CampaignListItemProps) {
  const { campaignType, campaign } = props

  const icon = getIcon(campaignType)
  const Icon = ({ icon }) => {
    const IconComponent = icon
    return <IconComponent />
  }

  const metadata = getMetadata(campaign)

  let linkTo = ''
  if (campaignType === ComponentType.SMS) {
    linkTo = `/campaigns/SMS/${encodeURIComponent(campaign.campaignName)}`
  } else if (campaignType === ComponentType.EMAIL) {
    linkTo = `/campaigns/Email/${encodeURIComponent(campaign.campaignId)}`
  } else if (campaignType === ComponentType.CALL) {
    linkTo = `/campaigns/Call/${encodeURIComponent(campaign.campaignId)}`
  }

  const primary = (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <span>{campaign.campaignName}</span>
      <LookerLink campaignName={campaign.campaignName} campaignType={campaignType} />
    </Box>
  )

  return (
    <ListItem component={RouterLink} to={linkTo} disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={primary} secondary={metadata} />
      </ListItemButton>
    </ListItem>
  )
}

function getMetadata(campaign: ComponentCampaign) {
  const author = campaign.updatedBy || campaign.createdBy
  if (!campaign.updatedTimestamp && !author) return null
  return (
    <>
      Updated
      {campaign.updatedTimestamp && ` ${dayjs(campaign.updatedTimestamp).fromNow()}`}
      {author && (
        <>
          {' by '}
          <span className={styles.author}>{author}</span>
        </>
      )}
    </>
  )
}
