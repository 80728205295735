import _ from 'lodash'

import { LineOfInsurance } from '../enums'

const LOIS = Object.values(LineOfInsurance)

/**
 * Returns true if the given string is a valid campaign name. Otherwise, returns false.
 *
 * Campaign name should only contain:
 * - lowercase a-z letters
 * - numbers 0-9
 * - underscores (_)
 * - length no longer than 100
 *
 * @param name
 */
export function isValidName(name: string): boolean {
  if (!name) return false
  const regex = /^[a-z0-9_]{0,99}$/
  return regex.test(name)
}

/**
 * Returns true if the given string is a valid sender alias (username). Otherwise, returns false.
 *
 * Username should only contain:
 * - alphabetic letters
 * - numbers 0-9
 * - dots (.)
 * - dashes (-)
 * - underscores (_)
 *
 * @param name
 */
export function isValidAlias(name: string): boolean {
  if (!name) return true
  const regex = /^[a-zA-Z0-9_.-]+$/
  return regex.test(name)
}

/**
 * Returns true if the given string is a valid field name. Otherwise, returns false.
 *
 * Field name should only contain:
 * - alphabetic letters (both upper and lowercase OK)
 * - no spaces or special chars of any kind
 *
 * @param name
 */
export function isValidFieldName(name: string): boolean {
  if (!name) return true
  const regex = /^[a-zA-Z]+$/
  return regex.test(name)
}

/**
 * Determines the lead type based on the provided campaign name.
 *
 * This function searches for specific keywords in the campaign name and returns the corresponding lead type.
 *
 * @param name The campaign name to analyze for lead type determination.
 * @returns The lead type inferred from the campaign name, or an empty string if no lead type is found.
 */
export function loiFromName(name: string): string {
  if (!name) return ''
  const lowerCaseName = name.toLowerCase()
  return _.find(LOIS, value => _.includes(lowerCaseName, value)) || ''
}
