import { Box, IconButton, Link, Tooltip } from '@mui/material'
import {
  Call,
  Circle,
  Email,
  HourglassTop,
  OpenInNew,
  PlayCircleFilled,
  RemoveCircle,
  Sms,
  StopCircle,
  Task
} from '@mui/icons-material'
import type { SvgIconComponent } from '@mui/icons-material'

import _ from 'lodash'

import CampaignQuery from '../../hooks/CampaignQuery'
import { CampaignSelect } from '../CampaignSelect'
import { ComplianceConfig } from '../ComplianceConfig'
import { ComponentEdge } from '../ComponentEdge'
import { ComponentType, JourneyStatus } from '../../enums'
import { DelaySelect } from '../DelaySelect'
import { DialConfig } from '../DialConfig'
import type { JourneyComponent } from '../../types'
import { LookerLink } from '../LookerLink'
import { MoveComponentMenu } from '../MoveComponentMenu'
import { campaignDetailLink } from '../../utils/url'
import { useGlobalState } from '../../store'
import { useJourney } from '../../hooks/JourneyQuery'
import { useJourneyMutation } from '../../hooks/useJourneyMutation'

import styles from './ComponentNode.module.scss'

interface ComponentNodeProps {
  component: JourneyComponent
  menu?: React.ReactNode // AddComponentMenu
}

export function ComponentNode(props: ComponentNodeProps) {
  const { component, menu } = props

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)
  const journey = useJourney()
  const disabled =
    journey?.status === JourneyStatus.PUBLISHED ||
    journey?.status === JourneyStatus.ARCHIVED ||
    !hasEditPermission

  const icon = getIcon(component.componentType)
  const Icon = ({ icon }) => {
    const IconComponent = icon
    return <IconComponent />
  }

  const config = getConfig(component, disabled)

  const isComplianceConfig = [ComponentType.EMAIL, ComponentType.SMS].includes(
    component.componentType
  )
  const hasCampaignSelected = !!component.componentConfig?.campaignId
  const lookerLink = (
    <Box ml={1}>
      {journey?.status === JourneyStatus.PUBLISHED && component.componentConfig && (
        <LookerLink
          campaignName={component.componentConfig.campaignName}
          leadType={journey?.loi || ''}
          campaignType={component.componentType}
        />
      )}
    </Box>
  )

  const campaignHref = campaignDetailLink(
    component.componentType,
    component.componentConfig?.campaignId || '',
    component.componentConfig?.campaignName || ''
  )

  const campaignLink = (
    <Box ml={1}>
      {campaignHref && (
        <Link href={campaignHref} target='_blank'>
          <Tooltip title='Open campaign (in new tab)' placement='right' arrow>
            <IconButton size='small'>
              <OpenInNew color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>
        </Link>
      )}
    </Box>
  )

  let edgeSize: 'small' | 'medium' | 'large' = 'small'
  if (component.componentType === ComponentType.DELAY) edgeSize = 'medium'
  if (component.componentType === ComponentType.CALL && hasCampaignSelected) edgeSize = 'large'
  if (isComplianceConfig && hasCampaignSelected) edgeSize = 'large'

  return (
    <>
      <div className={styles.root}>
        <div className={styles.icon}>
          <Icon icon={icon} />
        </div>
        {config}
        {campaignLink}
        {lookerLink}
        {!disabled && <DeleteButton component={component} />}
        {!disabled && <MoveButton component={component} />}
        {!disabled && <AddButton menu={menu} component={component} />}
      </div>
      {!_.isEmpty(component.toLinks) && <ComponentEdge size={edgeSize} />}
    </>
  )
}

export function getIcon(componentType: ComponentType): SvgIconComponent {
  switch (componentType) {
    case ComponentType.START:
      return PlayCircleFilled
    case ComponentType.END:
      return StopCircle
    case ComponentType.EMAIL:
      return Email
    case ComponentType.SMS:
      return Sms
    case ComponentType.CALL:
      return Call
    case ComponentType.TODO:
      return Task
    case ComponentType.DELAY:
      return HourglassTop
    default:
      return Circle
  }
}

function getConfig(component: JourneyComponent, disabled = false) {
  switch (component.componentType) {
    case ComponentType.CALL:
      return <DialConfig component={component} disabled={disabled} />
    case ComponentType.TODO:
      return <TodoConfig component={component} disabled={disabled} />
    case ComponentType.EMAIL:
      return (
        <ComplianceConfig
          componentType={ComponentType.EMAIL}
          component={component}
          disabled={disabled}
        />
      )
    case ComponentType.SMS:
      return (
        <ComplianceConfig
          componentType={ComponentType.SMS}
          component={component}
          disabled={disabled}
        />
      )
    case ComponentType.DELAY:
      return <DelayConfig component={component} disabled={disabled} />
    default:
      return (
        <div>
          {component.componentType}{' '}
          {!_.isEmpty(component.componentName.trim()) && `(${component.componentName})`}
        </div>
      )
  }
}

function TodoConfig({ component, disabled }) {
  const { configureTodoComponent } = useJourneyMutation()
  return (
    <>
      <Box mr={1}>Create a To-Do for:</Box>
      <CampaignQuery componentType={ComponentType.TODO}>
        {({ campaigns }) => {
          return (
            <CampaignSelect
              campaigns={campaigns}
              value={component.componentConfig?.campaignId}
              onChange={campaign =>
                configureTodoComponent(
                  component.componentId,
                  campaign.campaignId,
                  campaign.campaignName
                )
              }
              disabled={disabled}
            />
          )
        }}
      </CampaignQuery>
    </>
  )
}

function DelayConfig({ component, disabled }) {
  const { configureDelayComponent } = useJourneyMutation()
  return (
    <>
      <Box mr={1}>Delay for:</Box>
      <DelaySelect
        value={component.componentConfig}
        onChange={delayInput => configureDelayComponent(component.componentId, delayInput)}
        disabled={disabled}
        useTargetedDelay
        debounce
      />
    </>
  )
}

function DeleteButton({ component }) {
  const { removeComponent } = useJourneyMutation()

  if (component.componentType === ComponentType.START) return null
  if (component.componentType === ComponentType.END) return null

  return (
    <Tooltip title='Delete this action' placement='top' arrow>
      <IconButton
        color='error'
        sx={{ ml: 1, mr: -1 }}
        onClick={() => removeComponent(component.componentId)}
      >
        <RemoveCircle />
      </IconButton>
    </Tooltip>
  )
}

function MoveButton({ component }) {
  const { moveComponent } = useJourneyMutation()

  if (component.componentType === ComponentType.START) return null
  if (component.componentType === ComponentType.END) return null

  return (
    <MoveComponentMenu
      component={component}
      onClickMove={delta => moveComponent(component.componentId, delta)}
    />
  )
}

function AddButton({ menu, component }) {
  if (!menu) return null
  if (component.componentType === ComponentType.END) return null
  const extraProps = { after: component.componentId }
  return <menu.type {...menu.props} {...extraProps} />
}
