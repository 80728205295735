import { useState } from 'react'

import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material'

import _ from 'lodash'

import { NumberInput } from '../NumberInput'

export const NO_VALUE = -1

interface ToggleNumberInputProps {
  label?: string // label for the toggle switch
  tooltip?: string
  size?: 'small' | 'medium' // input size
  value?: number | undefined | null
  onChange?: (value: number) => void
  disabled?: boolean
}

export function ToggleNumberInput(props: ToggleNumberInputProps) {
  const {
    onChange = _.noop,
    label = 'Set daily limit',
    tooltip = '',
    size = 'medium',
    disabled = false
  } = props

  const [value, setValue] = useState<number | undefined>(
    props.value == null ? NO_VALUE : props.value
  )

  const handleToggle = e => {
    const checked = e.target.checked
    if (checked) {
      setValue(undefined)
    } else {
      onChange(NO_VALUE)
      setValue(NO_VALUE)
    }
  }

  const handleChange = (newValue: number) => {
    if (newValue != null) {
      onChange(newValue)
      setValue(newValue)
    }
  }

  const checked = value !== NO_VALUE

  return (
    <Box display='flex' flexDirection='column' sx={{ width: 'fit-content' }}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleToggle} disabled={disabled} />}
          label={label}
        />
      </FormGroup>
      {checked && (
        <NumberInput
          sx={{ mt: 1 }}
          tooltip={tooltip}
          postfix='per day'
          size={size}
          value={value === NO_VALUE ? undefined : value}
          onChange={handleChange}
          disabled={disabled}
          autoFocus={value == null /* no existing value */}
        />
      )}
    </Box>
  )
}
