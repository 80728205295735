import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input TodoComponentConfigInput {
 *   journeyId: ID!
 *   componentId: ID!
 *   campaignId: String!
 *   campaignName: String!
 * }
 */
export const CONFIGURE_TODO_MUTATION = gql`
  mutation ConfigureTodoComponent($todoConfig: TodoComponentConfigInput!) {
    configureTodoComponent(todoConfig: $todoConfig) {
      ...ComponentFragment
    }
  }
  ${COMPONENT_FRAGMENT}
`
