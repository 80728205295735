import _ from 'lodash'

/**
 * Returns true if the given two arrays have the same contents in them, regardless of order.
 *
 * ex) [
 *       { name: 'a', value: 1 },
 *       { name: 'b', value: 2 }
 *     ],
 *     [
 *       { name: 'b', value: 2 },
 *       { name: 'a', value: 1 }
 *     ]
 *     -> true
 *
 * Limitations:
 * - This code uses _.isEqual() for equality comparisons at the top level.
 *   May not work for objects containing nested objects in them.
 * - Do not use if you're expecting duplicate values in either array.
 *   For example, [1, 2, 3] and [1, 2, 2, 3] will be considered to have the same contents.
 */

export function haveSameContents(array1: unknown[] = [], array2: unknown[] = []): boolean {
  const clone1 = _.cloneDeep(array1)
  _.pullAllWith(clone1, array2, _.isEqual)

  const clone2 = _.cloneDeep(array2)
  _.pullAllWith(clone2, array1, _.isEqual)

  return _.isEmpty(clone1) && _.isEmpty(clone2)
}
