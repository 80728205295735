import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input DialComponentConfigInput {
 *   journeyId: ID!
 *   componentId: ID!
 *   campaignId: String!
 *   campaignName: String!
 * }
 */
export const CONFIGURE_DIAL_MUTATION = gql`
  mutation ConfigureDialComponent($dialConfig: DialComponentConfigInput!) {
    configureDialComponent(dialConfig: $dialConfig) {
      ...ComponentFragment
    }
  }
  ${COMPONENT_FRAGMENT}
`
