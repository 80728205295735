import { Box, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup } from '@mui/material'

import _ from 'lodash'

import { ComponentType } from '../../enums'

interface CampaignListFiltersProps {
  campaignTypeToShow?: ComponentType
  onChange?: (campaignType: ComponentType) => void
}

export function CampaignListFilters(props: CampaignListFiltersProps) {
  const { campaignTypeToShow = ComponentType.SMS, onChange = _.noop } = props
  return (
    <Box className='animate__animated animate__slideInLeft' sx={{ minWidth: '17rem' }}>
      <Paper
        variant='outlined'
        sx={{ position: 'sticky', top: '1rem', mt: 1, mr: 2, px: 3, py: 2 }}
      >
        <FormHelperText>CAMPAIGN TYPE</FormHelperText>
        <RadioGroup
          sx={{ mt: 1 }}
          value={campaignTypeToShow}
          onChange={e => onChange(e.target.value)}
        >
          {_.values(ComponentType)
            .filter(v => ![ComponentType.START, ComponentType.END, ComponentType.DELAY].includes(v))
            .map(v => (
              <FormControlLabel key={v} value={v} label={v} control={<Radio />} />
            ))}
        </RadioGroup>
      </Paper>
    </Box>
  )
}
