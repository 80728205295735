const VALID_PHONE_NUMBER_LENGTH = 12
export const VALID_RED_OAK_ID_LENGTH = 7

/**
 * Strip out non-numeric characters.
 * ex) "0abc123!" -> "0123"
 */
export function keepOnlyNumericChars(str = ''): string {
  return str.replace(/\D/g, '')
}

/**
 * Format number to U.S. currency display
 * ex) 1234.567 -> "1,234.57"
 * ex) 12.5 -> "12.50"
 */
export function formatCurrency(num: number | null | undefined): string {
  if (!num && num !== 0) return ''
  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

/**
 * Format number with thousand separator commas.
 * ex) 1234567 -> "1,234,567"
 *
 * Only works with integers. Do not use this with decimal points.
 */
export function formatLocale(num: number | null | undefined): string {
  if (!num && num !== 0) return ''
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Convert a phone number to E.164 standard format.
 * ex) "222-333-4444" -> "+12223334444"
 * ex) "222.333.4444" -> "+12223334444"
 * ex) "2223334444" -> "+12223334444"
 * ex) "1 222 333 4444" -> "+12223334444"
 */
export function convertToE164(phoneNumber = ''): string {
  if (phoneNumber.startsWith('+')) return phoneNumber // assume already formatted
  const str = keepOnlyNumericChars(phoneNumber)
  return str.length > 10 ? '+' + str : '+1' + str
}

/**
 * Convert a phone number to ###-###-#### format.
 * ex) '2223334444' -> 222-333-4444
 * ex) '+12223334444' -> 222-333-4444
 */
export const formatPhoneNumber = (number, delimiter = '-') => {
  const filteredPhoneNumber = number.replace(/\D/g, '').replace(/^1/, '')
  if (!filteredPhoneNumber) return number
  const arrNumbers = filteredPhoneNumber.split('')
  const start = arrNumbers.slice(0, 3).join('')
  const middle = arrNumbers.slice(3, 6).join('')
  const rest = arrNumbers.slice(6, arrNumbers.length).join('')
  return `${start}${delimiter}${middle}${delimiter}${rest}`
}

/**
 * Returns true if the given phone number is a valid phone number; Otherwise, false.
 * ex) "2223334444" -> true
 * ex) "12345" -> false
 */
export function isValidPhone(phoneNumber: string): boolean {
  return convertToE164(phoneNumber).length === VALID_PHONE_NUMBER_LENGTH
}

/**
 * Add ordinal suffix to a number (https://stackoverflow.com/a/31615643)
 * ex) 1 -> "1st"
 * ex) 2 -> "2nd"
 * ex) 7 -> "7th"
 */
export function withOrdinalSuffix(n: number): string {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

/**
 * Returns true if the given number is a valid RedOak ID.
 * ex) "0000000" -> true
 * ex) "123" -> false
 * ex) "abcd123" -> false
 */
export function isValidRedOakId(redOakId: string): boolean {
  if (!redOakId) return false
  return keepOnlyNumericChars(redOakId).length === VALID_RED_OAK_ID_LENGTH
}
