import { LineOfInsurance } from '../../enums'

interface LoiIconProps {
  loi: string // leadType
}

export function LoiIcon(props: LoiIconProps) {
  const { loi } = props
  switch (loi) {
    case LineOfInsurance.Medicare:
      return <img src='/images/loi/medicare.svg' alt={loi} />
    case LineOfInsurance.Health:
      return <img src='/images/loi/health.svg' alt={loi} />
    case LineOfInsurance.Life:
      return <img src='/images/loi/life.svg' alt={loi} />
    case LineOfInsurance.Auto:
      return <img src='/images/loi/auto.svg' alt={loi} />
    case LineOfInsurance.Home:
      return <img src='/images/loi/mortgage.svg' alt={loi} />
    case LineOfInsurance.P_and_C:
      return <img src='/images/loi/pnc.svg' alt={loi} />
    default:
      return null
  }
}
