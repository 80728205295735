import React, { useState } from 'react'

import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  SwapVerticalCircle
} from '@mui/icons-material'

import _ from 'lodash'

import { ComponentType } from '../../enums'
import type { JourneyComponent } from '../../types'

interface MoveComponentMenuProps {
  component: JourneyComponent
  onClickMove?: (delta: number) => void
}

export function MoveComponentMenu(props: MoveComponentMenuProps) {
  const { component, onClickMove = _.noop } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMove = position => {
    if (_.isNumber(position)) onClickMove(position)
    setAnchorEl(null)
  }

  const isHead = !!_.find(component.fromLinks, { componentType: ComponentType.START })
  const isTail = !!_.find(component.toLinks, { componentType: ComponentType.END })

  return (
    <Tooltip title='Move this action' placement='top' arrow>
      <span>
        <IconButton color='primary' onClick={handleClick} sx={{ mr: -1 }}>
          <SwapVerticalCircle />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMove}>
          <MenuItem onClick={() => handleMove(-99999)} disabled={isHead}>
            <ListItemIcon>
              <KeyboardDoubleArrowUp fontSize='small' />
            </ListItemIcon>
            Move to Top
          </MenuItem>
          <MenuItem onClick={() => handleMove(-1)} disabled={isHead}>
            <ListItemIcon>
              <KeyboardArrowUp fontSize='small' />
            </ListItemIcon>
            Move Up
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => handleMove(1)} disabled={isTail}>
            <ListItemIcon>
              <KeyboardArrowDown fontSize='small' />
            </ListItemIcon>
            Move Down
          </MenuItem>
          <MenuItem onClick={() => handleMove(99999)} disabled={isTail}>
            <ListItemIcon>
              <KeyboardDoubleArrowDown fontSize='small' />
            </ListItemIcon>
            Move to Bottom
          </MenuItem>
        </Menu>
      </span>
    </Tooltip>
  )
}
