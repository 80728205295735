import React, { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import _ from 'lodash'
import clsx from 'clsx'

import { ComponentNode } from '../ComponentNode'
import { ComponentType } from '../../enums'
import { CopyButton } from '../CopyButton'
import type { JourneyComponent, JourneyField } from '../../types'

import styles from './ComponentsView.module.scss'

interface ComponentsViewProps {
  components: JourneyComponent[]
  menu?: React.ReactNode
  journeyField: JourneyField
}

export function ComponentsView(props: ComponentsViewProps) {
  const { components = [], menu, journeyField } = props
  const [tabValue, setTabValue] = useState(0)

  return (
    <Box className={styles.root}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={(_e, v) => setTabValue(v)} aria-label='tabs'>
          <Tab label='Actions' />
          <Tab label='JSON' />
          <Tab label='Journey Start JSON' />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ComponentsTab components={components} menu={menu} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} grey>
        <JsonTab data={components} />
      </TabPanel>
      <TabPanel value={tabValue} index={2} grey>
        <JsonTab data={journeyField?.body} />
      </TabPanel>
    </Box>
  )
}

function TabPanel({ children, value, index, grey = false }) {
  return (
    <div
      className={clsx(grey && styles.grey)}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function JsonTab({ data }) {
  const jsonString = JSON.stringify(data, null, 2)

  return (
    <Box style={{ position: 'relative' }}>
      <CopyButton toolTip={'Copy JSON'} value={jsonString} />
      <pre>{jsonString}</pre>
    </Box>
  )
}

function ComponentsTab({
  components,
  menu
}: {
  components: JourneyComponent[]
  menu: React.ReactNode // AddComponentMenu
}) {
  let component = components.find(o => o.componentType === ComponentType.START) as JourneyComponent
  const linkedComponents: JourneyComponent[] = [component]
  while (!_.isEmpty(component?.toLinks)) {
    const targetId = _.first(component?.toLinks)?.componentId
    component = components.find(o => o.componentId === targetId) as JourneyComponent
    linkedComponents.push(component)
  }
  const unlinkedComponents = _.differenceBy(components, linkedComponents, 'componentId')
  return (
    <>
      <div>
        {_.map(linkedComponents, (o: JourneyComponent) => (
          <ComponentNode key={o.componentId} component={o} menu={menu} />
        ))}
      </div>
      <UnlinkedComponents unlinkedComponents={unlinkedComponents} />
    </>
  )
}

/**
 * Display orphaned (not linked to anything) components, if they exist.
 * Shouldn't ever be possible, but just in case...
 */
function UnlinkedComponents({ unlinkedComponents }) {
  if (_.isEmpty(unlinkedComponents)) return null
  return (
    <Box mt={3}>
      Unlinked:{' '}
      {_.map(unlinkedComponents, (o: JourneyComponent) => (
        <ComponentNode key={o.componentId} component={o} />
      ))}
    </Box>
  )
}
