import React, { useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'

import _ from 'lodash'

import { ComponentType } from '../../enums'
import { getIcon } from '../ComponentNode'

interface AddComponentMenuProps {
  after?: string // ID of component to insert after
  onAdd?: (componentType: ComponentType, after: string) => void
}

export function AddComponentMenu(props: AddComponentMenuProps) {
  const { after, onAdd = _.noop } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const Icon = ({ icon }) => {
    const IconComponent = icon
    return <IconComponent fontSize='small' />
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (componentType?: ComponentType) => {
    if (componentType) onAdd(componentType, after)
    setAnchorEl(null)
  }

  return (
    <Tooltip title='Add next action' placement='top' arrow>
      <span>
        <IconButton color='success' onClick={handleClick}>
          <AddCircle />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
          {_.values(ComponentType)
            .filter(v => ![ComponentType.START, ComponentType.END].includes(v))
            .map(v => (
              <MenuItem key={v} onClick={() => handleClose(v)}>
                <ListItemIcon>
                  <Icon icon={getIcon(v)} />
                </ListItemIcon>
                {v}&nbsp;
              </MenuItem>
            ))}
        </Menu>
      </span>
    </Tooltip>
  )
}
