import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input CampaignUpdateInput {
 *   """Type of component to create"""
 *   componentType: ComponentType!
 *
 *   """Campaign id"""
 *   campaignId: ID = null
 *   name: String = null
 *
 *   """Campaign type"""
 *   businessType: CampaignBusinessType = null
 *
 *   """Email campaign description"""
 *   description: String = null
 *
 *   """Message content"""
 *   message: Message = null
 *
 *   """Template content"""
 *   template: String = null
 *
 *   """Approval status"""
 *   approvalStatus: Boolean = false
 *
 *   """List of fields from the template"""
 *   fields: [String] = []
 * }
 */
export const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation updateCampaign($campaignUpdateInput: CampaignUpdateInput!) {
    updateCampaign(campaignUpdateInput: $campaignUpdateInput) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
