export enum JourneyStatus {
  PUBLISHED = 'Published',
  DRAFT = 'Draft',
  DISABLED = 'Disabled',
  ARCHIVED = 'Archived'
}

export enum JourneyStartTrigger {
  API = 'API',
  BATCH = 'Batch'
}

// frontend internal only
export enum JourneyEndTrigger {
  DEFAULT,
  DATE
}

export enum TriggerEvent {
  DEADLINE_EXPIRED = 'DeadlineExpired'
}

export enum ComponentType {
  START = 'Start',
  END = 'End',
  SMS = 'SMS',
  EMAIL = 'Email',
  CALL = 'Call',
  TODO = 'Todo',
  DELAY = 'Delay'
}

export enum ComponentSource {
  CRM = 'CRM',
  Shovel = 'Shovel',
  Caramail = 'Caramail',
  OES = 'OES'
}

export enum FieldClass {
  AGENT = 'Agent',
  ENTITY = 'Entity',
  POLICY = 'Policy',
  POLICY_CARRIER = 'PolicyCarrier',
  SHOPPER = 'Shopper'
}

export enum CampaignBusinessType {
  INFORMATIONAL = 'Informational',
  MARKETING = 'Marketing',
  TRANSACTIONAL = 'Transactional'
}

export enum TargetedDelay {
  CUSTOM_HOURS = 'CustomHours',
  CCA_HOURS = 'CcaHours'
}

export enum LineOfInsurance {
  Auto = 'auto',
  Health = 'health',
  Home = 'home',
  Life = 'life',
  Medicare = 'medicare',
  P_and_C = 'p&c'
}
