import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import { Add, ArrowDropDown } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'

import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { CampaignListFilters } from '../components/CampaignListFilters'
import { CampaignListItem } from '../components/CampaignListItem'
import CampaignQuery from '../hooks/CampaignQuery'
import type { ComponentType } from '../enums'
import { useGlobalActions, useGlobalState } from '../store'

import styles from './Campaigns.module.scss'

export function Campaigns() {
  const campaignTypeToShow = useGlobalState(
    state => state.settings.campaignTypeToShow,
    () => true // skip re-render upon this value changing
  )

  const { setCampaignTypeToShow } = useGlobalActions(actions => actions.settings)

  const { campaignType } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (campaignType) {
      setCampaignTypeToShow(campaignType as ComponentType)
    } else {
      navigate(`/campaigns/${campaignTypeToShow}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignType])

  if (!campaignType) return <Loading />

  return (
    <div>
      <div className={styles.header}>
        <Breadcrumbs root={`${campaignType} Campaigns`} title='All' />
        <CreateButton />
      </div>
      <div className={styles.content}>
        <Filters campaignType={campaignType} />
        <CampaignListContainer campaignType={campaignType} />
      </div>
    </div>
  )
}

function Filters({ campaignType }) {
  const navigate = useNavigate()
  return (
    <CampaignListFilters
      campaignTypeToShow={campaignType}
      onChange={campaignType => navigate(`/campaigns/${campaignType}`)}
    />
  )
}

function CampaignList({ campaignList, campaignType }) {
  const sort_campaigns = campaigns => {
    return _.sortBy(
      campaigns,
      o => new Date(o.updatedTimestamp || '1970-01-01T00:00:00+00:00')
    ).reverse()
  }

  if (_.isEmpty(campaignList)) {
    return (
      <Alert severity='warning' sx={{ mt: 1, width: '100%', height: 'fit-content' }}>
        <AlertTitle>No campaigns to display!</AlertTitle>
        Check the filters or create a new campaign.
      </Alert>
    )
  }

  return (
    <List className={styles.list}>
      {sort_campaigns(campaignList).map(campaign => {
        return (
          <CampaignListItem
            key={campaign.campaignId + campaign.businessType}
            campaignType={campaignType}
            campaign={campaign}
          />
        )
      })}
    </List>
  )
}

function CampaignListContainer({ campaignType }) {
  return (
    <CampaignQuery componentType={campaignType} noCache showSkeleton>
      {({ campaigns }) => <CampaignList campaignList={campaigns} campaignType={campaignType} />}
    </CampaignQuery>
  )
}

function CreateButton() {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  return (
    <>
      <Button
        className='animate__animated animate__slideInRight'
        variant='contained'
        color='primary'
        endIcon={<ArrowDropDown />}
        aria-controls={open ? 'button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='menu'
        ref={anchorRef}
        onClick={() => setOpen(prevOpen => !prevOpen)}
        disabled={!hasEditPermission}
      >
        Create New Campaign
      </Button>
      <Popper
        sx={{
          zIndex: 99
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='button-menu'>
                  <MenuItem component={RouterLink} to='/campaigns/SMS/create-campaign'>
                    <ListItemIcon>
                      <Add fontSize='small' color='primary' />
                    </ListItemIcon>
                    New SMS Campaign
                  </MenuItem>
                  <MenuItem component={RouterLink} to='/campaigns/Email/create-campaign'>
                    <ListItemIcon>
                      <Add fontSize='small' color='primary' />
                    </ListItemIcon>
                    New Email Campaign
                  </MenuItem>
                  <MenuItem component={RouterLink} to='/campaigns/Call/create-campaign'>
                    <ListItemIcon>
                      <Add fontSize='small' color='primary' />
                    </ListItemIcon>
                    New Call Campaign
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
