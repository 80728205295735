import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const CAMPAIGN_QUERY = gql`
  query Campaign(
    $componentType: ComponentType!
    $campaignId: String
    $campaignName: String
    $componentSource: ComponentSource
  ) {
    campaign(
      campaignInput: { componentType: $componentType, componentSource: $componentSource }
      campaignId: $campaignId
      campaignName: $campaignName
    ) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
