import { useContext } from 'react'

function useContextWrapper<ContextType>(contextInstance): ContextType {
  const context = useContext(contextInstance)
  if (context === undefined) {
    throw new Error('Context hooks must be used within their Context.Provider')
  }
  return context as ContextType
}

export { useContextWrapper as useContext }
