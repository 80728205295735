import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { Circle } from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'

import _ from 'lodash'

import { ComponentType, LineOfInsurance } from '../../enums'
import { LoiIcon } from '../LoiIcon'
import { getIcon } from '../ComponentNode'

import styles from './JourneyListFilters.module.scss'

interface JourneyListFiltersProps {
  tags?: string[] // tag list for autocomplete
  searchByTags?: string[]
  onSearchByTags?: (tags: string[]) => void

  showCreatedByMe?: boolean
  onShowCreatedByMe?: (toggle: boolean) => void
  showCreatedByOthers?: boolean
  onShowCreatedByOthers?: (toggle: boolean) => void

  showActive?: boolean
  onShowActive?: (toggle: boolean) => void
  showInactive?: boolean
  onShowInactive?: (toggle: boolean) => void
  showArchived?: boolean
  onShowArchived?: (toggle: boolean) => void

  journeyLoisFilter?: LineOfInsurance[]
  setJourneyLoisFilter?: (lois: LineOfInsurance[]) => void

  journeyCampaignsFilter?: ComponentType[]
  setJourneyCampaignsFilter?: (componentTypes: ComponentType[]) => void

  showTrafficSplit?: boolean
  onShowTrafficSplit?: (toggle: boolean) => void
}

export function JourneyListFilters(props: JourneyListFiltersProps) {
  const {
    tags = [],
    searchByTags = [],
    onSearchByTags = _.noop,
    showCreatedByMe = true,
    onShowCreatedByMe = _.noop,
    showCreatedByOthers = true,
    onShowCreatedByOthers = _.noop,
    showActive = true,
    onShowActive = _.noop,
    showInactive = false,
    onShowInactive = _.noop,
    showArchived = false,
    onShowArchived = _.noop,
    journeyLoisFilter = [],
    setJourneyLoisFilter = _.noop,
    journeyCampaignsFilter = [],
    setJourneyCampaignsFilter = _.noop,
    showTrafficSplit = false,
    onShowTrafficSplit = _.noop
  } = props

  const IconLabel = ({ text, Icon, color = 'inherit' }) => (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Icon fontSize='small' color={color} />
      <Typography sx={{ ml: 1 }}>{text}</Typography>
    </Box>
  )

  const ImageIconLabel = ({ text, Icon }) => (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      {Icon}
      <Typography sx={{ ml: 1 }}>{text}</Typography>
    </Box>
  )

  return (
    <Box className='animate__animated animate__slideInLeft'>
      <Paper variant='outlined' sx={{ top: '1rem', width: '16rem', mt: 1, mr: 2, px: 3, py: 2 }}>
        <FormHelperText>FILTER JOURNEYS</FormHelperText>

        <FormGroup>
          <Typography component='div' mt={1} mb={0.5}>
            <Box className={styles.filterType}>Tags</Box>
          </Typography>
          <FormControl sx={{ maxWidth: '14rem' }}>
            <Autocomplete
              value={searchByTags || []}
              onChange={(_event, newValue) => {
                onSearchByTags(newValue)
              }}
              options={tags}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  placeholder={_.isEmpty(searchByTags) ? 'Select tags' : ''}
                />
              )}
              multiple
              disableClearable
            />
          </FormControl>

          <Typography component='div' mt={1}>
            <Box className={styles.filterType}>Created By</Box>
          </Typography>
          <FormControlLabel
            label='Created by me'
            control={
              <Checkbox
                checked={showCreatedByMe}
                onChange={e => onShowCreatedByMe(e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label='Created by others'
            control={
              <Checkbox
                checked={showCreatedByOthers}
                onChange={e => onShowCreatedByOthers(e.target.checked)}
              />
            }
          />

          <Divider className={styles.divider} />

          <Typography display='block' className={styles.filterType}>
            Journey Status
          </Typography>
          <FormControlLabel
            label={<IconLabel text='Active' Icon={Circle} color='success' />}
            control={
              <Checkbox checked={showActive} onChange={e => onShowActive(e.target.checked)} />
            }
          />
          <FormControlLabel
            label={<IconLabel text='Inactive' Icon={Circle} color='secondary' />}
            control={
              <Checkbox checked={showInactive} onChange={e => onShowInactive(e.target.checked)} />
            }
          />
          <FormControlLabel
            label={<IconLabel text='Archived' Icon={Circle} color='disabled' />}
            control={
              <Checkbox checked={showArchived} onChange={e => onShowArchived(e.target.checked)} />
            }
          />

          <Divider className={styles.divider} />

          <Typography display='block' className={styles.filterType}>
            Line of Insurance
          </Typography>
          {_.values(LineOfInsurance).map(v => (
            <FormControlLabel
              key={v}
              className={styles.loiControl}
              label={
                <ImageIconLabel
                  text={v === LineOfInsurance.P_and_C ? _.toUpper(v) : _.upperFirst(v)}
                  Icon={<LoiIcon loi={v} />}
                />
              }
              control={
                <Checkbox
                  checked={journeyLoisFilter.includes(v)}
                  onChange={e => {
                    if (e.target.checked) {
                      setJourneyLoisFilter([...journeyLoisFilter, v])
                    } else {
                      setJourneyLoisFilter(_.without(journeyLoisFilter, v))
                    }
                  }}
                />
              }
            />
          ))}

          <Divider className={styles.divider} />

          <Typography display='block' className={styles.filterType}>
            Actions
          </Typography>
          {_.values(ComponentType)
            .filter(v => ![ComponentType.START, ComponentType.END].includes(v))
            .map(v => (
              <FormControlLabel
                key={v}
                label={<IconLabel text={v} Icon={getIcon(v)} />}
                control={
                  <Checkbox
                    checked={journeyCampaignsFilter.includes(v)}
                    onChange={e => {
                      if (e.target.checked) {
                        setJourneyCampaignsFilter([...journeyCampaignsFilter, v])
                      } else {
                        setJourneyCampaignsFilter(_.without(journeyCampaignsFilter, v))
                      }
                    }}
                  />
                }
              />
            ))}

          <Divider className={styles.divider} />

          <Typography display='block' className={styles.filterType}>
            A/B Testing
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={showTrafficSplit}
                onChange={e => onShowTrafficSplit(e.target.checked)}
              />
            }
            label='With Traffic Split'
          />
        </FormGroup>
      </Paper>
    </Box>
  )
}
