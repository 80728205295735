import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input ComponentCreate {
 *   journeyId: ID!
 *   componentName: String!
 *   componentType: ComponentType!
 *   after: ID = null // Optional: Insert after this component. If null, inserts at the end.
 * }
 */
export const ADD_COMPONENT_MUTATION = gql`
  mutation InsertNewJourneyComponent($componentInput: ComponentCreate!) {
    insertNewComponent(componentInput: $componentInput) {
      ...ComponentFragment
    }
  }
  ${COMPONENT_FRAGMENT}
`
