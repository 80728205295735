import { AddCircle, Info, RemoveCircle } from '@mui/icons-material'
import { Box, Button, FormControl, FormLabel, IconButton, TextField, Tooltip } from '@mui/material'

import _ from 'lodash'

import { formatPhoneNumber, isValidPhone } from '../../utils/number'

interface SenderNumberInputProps {
  senderNumbers?: string[]
  setSenderNumbers?: (senderNumbers: string[]) => void
  disabled?: boolean
}

export function SenderNumberInput(props: SenderNumberInputProps) {
  const { setSenderNumbers = _.noop, disabled = false } = props
  let { senderNumbers = [''] } = props

  // make sure the array has at least an empty string at all times for the inputs to populate below
  if (_.isEmpty(senderNumbers)) {
    senderNumbers = ['']
  }

  return (
    <FormControl>
      <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
        Sender Number(s)
        <Tooltip title='Must be verified numbers registered in Twilio' placement='right' arrow>
          <Info color='disabled' fontSize='small' sx={{ ml: 0.75 }} />
        </Tooltip>
      </FormLabel>
      {senderNumbers.map((number, i) => (
        <PhoneNumberInput
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          index={i}
          count={senderNumbers.length}
          value={number}
          onChange={e => {
            const newNumbers = _.clone(senderNumbers)
            newNumbers[i] = e.target.value as never
            setSenderNumbers(newNumbers)
          }}
          onDelete={() => {
            const newTriggers = _.clone(senderNumbers)
            _.pullAt(newTriggers, i)
            setSenderNumbers(newTriggers)
          }}
          disabled={disabled}
        />
      ))}
      <Button
        onClick={() => setSenderNumbers([...senderNumbers, '' as never])}
        color='success'
        startIcon={<AddCircle color={disabled ? 'disabled' : 'success'} />}
        sx={{ mt: 0.5, width: 'fit-content' }}
        disabled={disabled}
      >
        Add another number
      </Button>
    </FormControl>
  )
}

function PhoneNumberInput({ index, count, value, onChange, onDelete, disabled }) {
  const hasInvalidPhoneNumber = !!value && !isValidPhone(value)
  return (
    <Box mt={1} display='flex' alignItems='center'>
      <TextField
        variant='outlined'
        value={disabled ? formatPhoneNumber(value) : value}
        onChange={onChange}
        error={hasInvalidPhoneNumber}
        autoComplete='tel-national'
        placeholder='Phone number'
        inputProps={{ maxLength: 14 }}
        disabled={disabled}
        autoFocus={index > 0}
        sx={{ minWidth: '15rem' }}
      />
      {count > 1 && (
        <Tooltip title='Delete' placement='right' arrow>
          <IconButton onClick={onDelete} sx={{ ml: 0.5 }} disabled={disabled}>
            <RemoveCircle color={disabled ? 'disabled' : 'error'} fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
