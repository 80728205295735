import {
  FormControl,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  Tooltip
} from '@mui/material'
import type { SxProps } from '@mui/system'

import _ from 'lodash'

const MIN_VALUE = 0
const MAX_VALUE = 9999999

const INPUT_PROPS = {
  min: MIN_VALUE,
  max: MAX_VALUE,
  maxLength: 7,
  inputMode: 'numeric',
  pattern: '[0-9]*'
} as InputBaseComponentProps

interface NumberInputProps {
  label?: string
  tooltip?: string
  tooltipPlacement?: 'left' | 'right' | 'bottom' | 'top'
  postfix?: string // for endAdornment
  placeholder?: string
  size?: 'small' | 'medium' // input size
  inputProps?: InputBaseComponentProps
  value?: number | undefined | null
  onChange?: (value: number) => void
  error?: boolean
  disabled?: boolean
  autoFocus?: boolean
  sx?: SxProps
}

export function NumberInput(props: NumberInputProps) {
  const {
    value,
    onChange = _.noop,
    label = '',
    tooltip = '',
    tooltipPlacement = 'right', // default to 'right' if no tooltipPlacement prop is passed
    postfix = '',
    placeholder = `${MIN_VALUE}`, // only works when value is undefined or null
    size = 'medium',
    inputProps = {},
    error = false,
    disabled = false,
    autoFocus = false,
    sx = {}
  } = props

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} arrow>
      <FormControl
        sx={{ width: '18ch', ...sx }}
        variant='outlined'
        size={size}
        error={error}
        disabled={disabled}
      >
        {label && <InputLabel>{label}</InputLabel>}
        <OutlinedInput
          label={label || null}
          endAdornment={postfix ? <InputAdornment position='end'>{postfix}</InputAdornment> : null}
          type='number'
          placeholder={placeholder}
          inputProps={{ ...INPUT_PROPS, ...inputProps }}
          value={value}
          onChange={e => onChange(_.toNumber(e.target.value))}
          onFocus={e => e.target.select()}
          autoFocus={autoFocus}
        />
      </FormControl>
    </Tooltip>
  )
}
