import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { InsertLink } from '@mui/icons-material'

import _ from 'lodash'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useSnackbar } from '@assuranceiq/react-components'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { CampaignBusinessType, ComponentType } from '../enums'
import { CampaignCreateInput, useCampaignMutation } from '../hooks/useCampaignMutation'
import CampaignQuery from '../hooks/CampaignQuery'
import { CloneButton } from '../components/CloneButton'
import type { ComponentCampaign } from '../types'
import { SenderNumberInput } from '../components/SenderNumberInput'
import { formatPhoneNumber, isValidPhone } from '../utils/number'
import { isValidName } from '../utils/campaign'
import { useGlobalState } from '../store'

import styles from './SmsCampaign.module.scss'

const DYNAMIC_VARIABLE_LIST_URL =
  'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2989752418/Internal+Omnichannel+Onboard+new+Outreach+SMS+campaigns#List-Supporting-Fields'
const WEBHOOK_REQUEST_URL =
  'https://vs258gfzxi.execute-api.us-east-1.amazonaws.com/prod/messaging_service/incoming_message'
const TWILIO_REGISTRATION_URL =
  'https://console.twilio.com/us1/develop/sms/services?frameUrl=%2Fconsole%2Fsms%2Fservices%3Fx-target-region%3Dus1'
const REGISTRATION_DOC_URL =
  'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2989752418/Internal+Omnichannel+Onboard+new+Outreach+SMS+campaigns#Configure-Campaign-in-TWILIO-(which-cannot-be-done-by-API)'

export function SmsCampaign() {
  const { campaignName } = useParams()
  if (!campaignName) return <Main />
  return (
    <CampaignQuery componentType={ComponentType.SMS} campaignName={campaignName} noCache>
      {({ campaigns }) => {
        if (_.isEmpty(campaigns)) {
          return <Alert severity='warning'>No matching campaign found.</Alert>
        }
        const campaign = campaigns.pop()
        return <Main campaign={campaign} />
      }}
    </CampaignQuery>
  )
}

function Main({ campaign }: { campaign?: ComponentCampaign }) {
  const [name, setName] = useState<string>(campaign?.campaignName || '')
  const [description, setDescription] = useState<string>(campaign?.description || '')
  const [businessType, setBusinessType] = useState<CampaignBusinessType>(
    campaign?.businessType || CampaignBusinessType.MARKETING
  )
  const [sentByAgent, setSentByAgentOption] = useState<boolean>(campaign?.sentByAgent || false)
  const [template, setTemplate] = useState<string>(campaign?.template || '')
  const [smsCallbackNumber, setSmsCallbackNumber] = useState<string>(
    campaign?.smsCallbackNumber || ''
  )
  const [senderPool, setSenderPool] = useState<string[]>(campaign?.senderPool || [])
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const { createSmsCampaign, updateCampaign, copyCampaign } = useCampaignMutation()

  const { showSnackbar } = useSnackbar()

  const disableEdit = !!campaign || !hasEditPermission
  const hasInvalidName = !campaign && !!name && !isValidName(name)
  const hasInvalidCallbackNumber = !!smsCallbackNumber && !isValidPhone(smsCallbackNumber)
  const hasInvalidSenderNumbers =
    senderPool && _.some(_.compact(senderPool), num => !isValidPhone(num))
  const disableSubmit =
    !hasEditPermission ||
    !name ||
    !template ||
    hasInvalidName ||
    hasInvalidCallbackNumber ||
    hasInvalidSenderNumbers

  const create = () => {
    let payload: CampaignCreateInput = { name, template, businessType, sentByAgent }
    if (description) payload = { ...payload, description } // optional field
    if (smsCallbackNumber) payload = { ...payload, smsCallbackNumber } // optional field
    if (!_.isEmpty(senderPool)) payload = { ...payload, senderPool } // optional field
    return createSmsCampaign(payload)
  }

  const cloneCampaign = targetName => {
    return copyCampaign(campaign?.campaignId, targetName, ComponentType.CALL)
  }

  const author = campaign?.updatedBy || campaign?.createdBy
  const journeyId = campaign?.journeyId
  const journeyName = campaign?.journeyName

  return (
    <div className={clsx(styles.root, 'animate__animated animate__fadeIn')}>
      <div className={styles.header}>
        <Breadcrumbs root='SMS Campaigns' title={campaign?.campaignName || 'New SMS Campaign'} />
        {campaign && (
          <div className={styles.right}>
            <div className={styles.metadata}>
              <div>Version {campaign.version}</div>
              {(campaign.updatedTimestamp || author) && (
                <div>
                  Updated
                  {campaign.updatedTimestamp &&
                    ` at ${dayjs(campaign.updatedTimestamp).format('MM/DD/YYYY @h:mma')}`}
                  {author && (
                    <>
                      {' by '}
                      <span className={styles.author}>{author}</span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Box mt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <Box display='flex' alignItems='center'>
          <TextField
            id='name'
            label='Name'
            variant='outlined'
            value={name}
            onChange={e => setName(e.target.value)}
            error={hasInvalidName}
            autoComplete='off'
            inputProps={{ maxLength: 100 }}
            disabled={disableEdit}
            sx={{ minWidth: '35rem' }}
            autoFocus
            required
          />
          <FormHelperText sx={{ ml: 1.5 }}>
            (must be unique, lowercase, and alphanumeric characters; underscores okay)
          </FormHelperText>
        </Box>

        {campaign && (
          <CloneButton
            label='campaign'
            defaultName={campaign.campaignName}
            onConfirm={cloneCampaign}
            disabled={!hasEditPermission}
          />
        )}
      </Box>

      <Box mt={2}>
        <TextField
          id='description'
          label='Description'
          variant='outlined'
          value={description}
          onChange={e => setDescription(e.target.value)}
          autoComplete='off'
          disabled={disableEdit}
          fullWidth
        />
      </Box>

      <Box mt={4}>
        <FormControl disabled={disableEdit} required>
          <FormLabel id='outreach-type-radio'>Outreach Type</FormLabel>
          <RadioGroup
            aria-labelledby='outreach-type-radio'
            name='outreach-type'
            value={businessType}
            onChange={e => setBusinessType(e.target.value as CampaignBusinessType)}
            row
          >
            <FormControlLabel
              value={CampaignBusinessType.MARKETING}
              control={<Radio />}
              label='Marketing'
            />
            <FormControlLabel
              value={CampaignBusinessType.INFORMATIONAL}
              control={<Radio />}
              label='Informational'
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={4}>
        <TextField
          id='sms-template'
          label='SMS Template'
          variant='outlined'
          placeholder='Hello, {{shopper.givenName}}! We are delighted to...'
          value={template}
          onChange={e => {
            setShowSaveButton(true)
            setTemplate(e.target.value)
          }}
          autoComplete='off'
          disabled={!hasEditPermission}
          multiline
          rows={4}
          fullWidth
          required
        />
        <Box display='flex' justifyContent='space-between' mx={1} mt={0.5}>
          {campaign && showSaveButton && (
            <Box>
              <Button
                variant='contained'
                size='small'
                onClick={async () => {
                  showSnackbar('Saving changes...', 'info')
                  await updateCampaign({ componentType: ComponentType.SMS, name, template })
                  setShowSaveButton(false)
                }}
                sx={{ ml: -1, mr: 1 }}
              >
                Save
              </Button>
              <Button
                size='small'
                onClick={() => {
                  setTemplate(campaign?.template || '')
                  setShowSaveButton(false)
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
          <Typography variant='caption' display='block'>
            Exceeding 320 characters may reduce deliverability and readability.{' '}
            <Link href={DYNAMIC_VARIABLE_LIST_URL} target='_blank'>
              Dynamic Variable List
            </Link>
          </Typography>
          <Typography variant='caption' display='block'>
            Characters: {template.length}
          </Typography>
        </Box>
      </Box>

      <Box mt={4}>
        <TextField
          id='callback-number'
          label='CTA Callback Number'
          variant='outlined'
          value={disableEdit ? formatPhoneNumber(smsCallbackNumber) : smsCallbackNumber}
          onChange={e => setSmsCallbackNumber(e.target.value)}
          error={hasInvalidCallbackNumber}
          autoComplete='tel-national'
          placeholder='Phone number'
          inputProps={{ maxLength: 14 }}
          disabled={disableEdit || sentByAgent}
          sx={{ minWidth: '15rem' }}
        />
      </Box>

      <Box mt={4}>
        <FormControl disabled={disableEdit}>
          <FormLabel id='outreach-sent-by-radio'>Send via Agent Number</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={sentByAgent}
                onChange={e => {
                  if (e.target.checked) {
                    setSmsCallbackNumber('')
                    setSenderPool([])
                  }
                  return setSentByAgentOption(e.target.checked)
                }}
              />
            }
            label={sentByAgent ? 'Yes' : 'No'}
          />
        </FormControl>
      </Box>

      {!sentByAgent && (
        <Box mt={4}>
          <SenderNumberInput
            senderNumbers={senderPool}
            setSenderNumbers={setSenderPool}
            disabled={disableEdit}
          />
        </Box>
      )}

      <Box mt={4}>
        {!campaign && (
          <Button variant='contained' color='primary' onClick={create} disabled={disableSubmit}>
            CREATE CAMPAIGN
          </Button>
        )}
      </Box>

      {journeyId && (
        <Box mt={3}>
          <Alert severity='warning'>
            This campaign is already being used in the journey:{' '}
            <Link component={RouterLink} to={`/journeys/${journeyId}`}>
              {journeyName}
            </Link>
          </Alert>
        </Box>
      )}

      {campaign && (
        <Box mt={5} mb={1}>
          <Alert variant='standard' icon={false} className={styles.registrationAlert}>
            <AlertTitle>Registration Info</AlertTitle>
            <Box>
              SMS template has been successfully registered. Please also register the template in
              Twilio using the below information. Refer to{' '}
              <Link href={REGISTRATION_DOC_URL} target='_blank'>
                this documentation
              </Link>{' '}
              for more details.
            </Box>
            <Box mt={2}>
              <TextField
                id='provider-campaign-id'
                label='Messaging SID'
                defaultValue={campaign.providerCampaignId}
                InputProps={{
                  readOnly: true
                }}
                variant='filled'
                fullWidth
              />
            </Box>
            <Box mt={2} mb={2}>
              <TextField
                id='webhook-request-url'
                label='Webhook Request URL'
                defaultValue={WEBHOOK_REQUEST_URL}
                InputProps={{
                  readOnly: true
                }}
                variant='filled'
                fullWidth
              />
            </Box>
            <Button
              variant='contained'
              color='success'
              startIcon={<InsertLink />}
              component={Link}
              href={TWILIO_REGISTRATION_URL}
              target='_blank'
              disabled={!hasEditPermission}
            >
              Register in Twilio
            </Button>
          </Alert>
        </Box>
      )}
    </div>
  )
}
