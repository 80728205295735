import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'
import { JOURNEY_FRAGMENT } from '../fragments/journey-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const JOURNEY_QUERY = gql`
  query Journey($journeyId: ID!) {
    journey(journeyId: $journeyId) {
      ...JourneyFragment
      components {
        ...ComponentFragment
      }
    }
    journeyField(journeyId: $journeyId) {
      body
    }
  }
  ${JOURNEY_FRAGMENT}
  ${COMPONENT_FRAGMENT}
`
