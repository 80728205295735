import { useState } from 'react'

import { FormControlLabel, FormGroup, MenuItem, Select, Switch, Tooltip } from '@mui/material'

import _ from 'lodash'

import type { DelayInput } from '../../types'
import { NumberInput } from '../NumberInput'
import { TargetedDelay } from '../../enums'
import { useDebounce } from '../../hooks/useDebounce'

import styles from './DelaySelect.module.scss'

interface DelaySelectProps {
  value?: DelayInput // pre-fill with existing config
  onChange?: (delayInput: DelayInput) => void
  useTargetedDelay?: boolean
  debounce?: boolean
  disabled?: boolean
}

enum SelectOption {
  CCA,
  MORNING,
  MORNING_AFTERNOON,
  AFTERNOON,
  EVENING
}

const HOURS_WINDOWS = {
  [SelectOption.CCA]: null,
  [SelectOption.MORNING]: [8, 12],
  [SelectOption.MORNING_AFTERNOON]: [8, 16],
  [SelectOption.AFTERNOON]: [12, 16],
  [SelectOption.EVENING]: [16, 20]
}

export function DelaySelect(props: DelaySelectProps) {
  const {
    value,
    onChange = _.noop,
    useTargetedDelay = false,
    debounce = false,
    disabled = false
  } = props

  const [days, setDays] = useState<number | undefined>(value?.days)
  const [hours, setHours] = useState<number | undefined>(value?.hours)
  const [minutes, setMinutes] = useState<number | undefined>(value?.minutes)
  const [targetedDelay, setTargetedDelay] = useState<TargetedDelay | null>(
    value?.targetedDelay || null
  )
  const [customHoursWindow, setCustomHoursWindow] = useState<number[] | null>(
    value?.customHoursWindow || null
  )

  useDebounce(
    () => {
      if (!!hours && hours > 23) return
      if (!!minutes && minutes > 59) return
      onChange({ days, hours, minutes, targetedDelay, customHoursWindow })
    },
    [days, hours, minutes, targetedDelay, customHoursWindow],
    disabled,
    debounce ? undefined : 0
  )

  const handleToggle = e => {
    const checked = e.target.checked
    setTargetedDelay(checked ? TargetedDelay.CCA_HOURS : null)
    if (!checked) setCustomHoursWindow(null)
  }

  const handleSelect = e => {
    const value: SelectOption = e.target.value
    setTargetedDelay(
      value === SelectOption.CCA ? TargetedDelay.CCA_HOURS : TargetedDelay.CUSTOM_HOURS
    )
    setCustomHoursWindow(HOURS_WINDOWS[value])
  }

  return (
    <div className={styles.root}>
      <NumberInput
        postfix='days'
        placeholder='0'
        value={days}
        onChange={setDays}
        disabled={disabled}
        sx={{ mr: 1, width: '14ch' }}
      />
      <NumberInput
        postfix='hours'
        placeholder='0'
        inputProps={{ max: 23, maxLength: 2 }}
        value={hours}
        onChange={setHours}
        error={!!hours && hours > 23}
        disabled={disabled}
        sx={{ mr: 1, width: '14ch' }}
      />
      <NumberInput
        postfix='minutes'
        placeholder='0'
        inputProps={{ max: 59, maxLength: 2 }}
        value={minutes}
        onChange={setMinutes}
        error={!!minutes && minutes > 59}
        disabled={disabled}
        sx={{ mr: 1, width: '14ch' }}
      />

      <div className={styles.extraConfig}>
        {useTargetedDelay && (
          <Tooltip
            title="You can target a window of hours in which the next action is triggered, after the specified time delay above has occurred. These time windows depend on downstream service's availability and thus are not guaranteed."
            placement='bottom-start'
            arrow
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={!!targetedDelay} onChange={handleToggle} disabled={disabled} />
                }
                label='Apply targeted hours'
              />
            </FormGroup>
          </Tooltip>
        )}

        {targetedDelay && (
          <Select
            id='time-window-select'
            value={_.findKey(HOURS_WINDOWS, v => _.isEqual(v, customHoursWindow))}
            onChange={handleSelect}
            size='small'
            sx={{ mt: '-2px' }}
            disabled={disabled}
            autoWidth
          >
            <MenuItem value={SelectOption.CCA}>
              CCA hours (5am - 5pm, Pacific Time, weekdays)
            </MenuItem>
            <MenuItem value={SelectOption.MORNING}>Morning (8am - 12pm, local time)</MenuItem>
            <MenuItem value={SelectOption.MORNING_AFTERNOON}>
              Morning - Afternoon (8am - 4pm, local time)
            </MenuItem>
            <MenuItem value={SelectOption.AFTERNOON}>Afternoon (12pm - 4pm, local time)</MenuItem>
            <MenuItem value={SelectOption.EVENING}>Evening (4pm - 8pm, local time)</MenuItem>
          </Select>
        )}
      </div>
    </div>
  )
}
