import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const UNREGISTERED_TEMPLATES_QUERY = gql`
  query UnregisteredEmailCampaigns {
    unregisteredEmailCampaigns {
      unregisteredEmailCampaigns {
        campaignId
        campaignName
      }
    }
  }
`
