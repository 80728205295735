import React, { useEffect } from 'react'

import { Loading } from '@assuranceiq/react-components'
import { useLazyQuery } from '@apollo/client'

import { CAMPAIGNS_QUERY } from '../graphql/queries/campaigns'
import { CAMPAIGN_QUERY } from '../graphql/queries/campaign'
import type { ComponentCampaign } from '../types'
import { ComponentSource, ComponentType } from '../enums'
import { Error } from '../components/Error'
import { ListItemSkeleton } from '../components/ListItemSkeleton'
import { useContext } from './useContext'

interface RenderProps {
  campaigns: ComponentCampaign[]
}

interface CampaignQueryProps {
  campaignId?: string
  campaignName?: string
  componentType: ComponentType
  children: ({ campaigns }: RenderProps) => React.ReactNode
  noCache?: boolean
  showSkeleton?: boolean
}

export const CampaignContext = React.createContext(undefined)

export default function CampaignQuery(props: CampaignQueryProps) {
  const {
    campaignId,
    campaignName,
    componentType,
    children,
    noCache = false,
    showSkeleton = false
  } = props

  const isSingleCampaign = campaignId || campaignName
  const source = componentType === ComponentType.CALL ? ComponentSource.Shovel : null
  const variables = { componentType: componentType }

  if (source) variables['componentSource'] = source

  const [doQuery, { loading, error, data }] = useLazyQuery(
    isSingleCampaign ? CAMPAIGN_QUERY : CAMPAIGNS_QUERY,
    {
      variables,
      fetchPolicy: noCache ? 'no-cache' : 'cache-first'
    }
  )

  useEffect(() => {
    const variables = { componentType: componentType }
    if (campaignId) variables['campaignId'] = campaignId
    if (campaignName) variables['campaignName'] = campaignName
    doQuery({ variables })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentType, campaignId])

  if (error) {
    console.error('Error while querying campaigns!', error)
    return <Error errors={error as never} />
  }

  if (loading || !data) {
    if (showSkeleton && !isSingleCampaign) {
      return <ListItemSkeleton variant='campaign' repeat={20} />
    }
    return <Loading />
  }

  let campaigns = []
  if (data) campaigns = isSingleCampaign ? [data.campaign] : data.campaigns || []
  return (
    <CampaignContext.Provider value={isSingleCampaign ? data?.campaign : undefined}>
      {children({ campaigns })}
    </CampaignContext.Provider>
  )
}

export const useCampaign = () => useContext<ComponentCampaign>(CampaignContext)
