import { Button } from '@mui/material'
import type { ButtonProps } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'

import { getJourneyHubUrl } from '../../utils/url'

interface JourneyHubLinkProps extends ButtonProps {
  journeyName: string
}

export function JourneyHubLink(props: JourneyHubLinkProps) {
  const { journeyName, ...others } = props
  return (
    <Button
      size='small'
      startIcon={<OpenInNew />}
      onClick={e => {
        e.preventDefault() // makes it clickable inside another clickable element
        window.open(getJourneyHubUrl(journeyName), '_blank')
      }}
      {...others}
    >
      Journey Hub
    </Button>
  )
}
