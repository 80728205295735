import { gql } from '@apollo/client'

import { COMPONENT_FRAGMENT } from '../fragments/component-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyCreate {
 *   name: String!
 *   description: String = ""
 *   version: String = ""
 *
 *   """Type of trigger to start journey"""
 *   journeyStartTrigger: JourneyStartTrigger = API
 *
 *   """Daily journey start limit for batch journeys"""
 *   batchDailyLimit: Int = 0
 *
 *   """
 *   Daily journey start threshold for alerting.
 *   -1 indicates no alert threshold.
 *   """
 *   alertDailyThreshold: Int = -1
 * }
 */
export const CREATE_JOURNEY_MUTATION = gql`
  mutation createJourney($journeyInput: JourneyCreate!) {
    createJourney(journeyInput: $journeyInput) {
      id
      components {
        ...ComponentFragment
      }
    }
  }
  ${COMPONENT_FRAGMENT}
`
