import { useState } from 'react'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { FileCopy } from '@mui/icons-material'

import _ from 'lodash'

interface CloneButtonProps {
  label: 'journey' | 'campaign'
  defaultName?: string
  onConfirm?: (targetName: string) => Promise<void>
  disabled?: boolean
}

export function CloneButton(props: CloneButtonProps) {
  const { label, defaultName = '', onConfirm = _.noop, disabled = false } = props

  const [targetName, setTargetName] = useState<string>(defaultName + ' (copy)')
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const closeDialog = () => setDialogOpen(false)
  const clone = () => onConfirm(targetName)

  return (
    <>
      <Button
        variant='contained'
        color='inherit'
        size='small'
        startIcon={<FileCopy />}
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
      >
        CLONE {label.toUpperCase()}
      </Button>

      {dialogOpen && (
        <Dialog onClose={closeDialog} open>
          <DialogTitle>Clone this {label}</DialogTitle>
          <DialogContent>
            <TextField
              id='targetName'
              label={`New ${_.upperFirst(label)} Name`}
              variant='outlined'
              value={targetName}
              onChange={e => setTargetName(e.target.value)}
              autoComplete='off'
              onKeyDown={e => {
                if (e.key === 'Enter') return clone()
              }}
              sx={{ minWidth: '20rem', mt: 1 }}
              fullWidth
              autoFocus
              required
            />
            <Alert severity='warning' sx={{ mt: 1 }}>
              Name cannot be changed once created
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button onClick={clone}>Clone</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
