import { useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'

import _ from 'lodash'

import type { Journey } from '../../types'

interface JourneyUnpublishButtonProps {
  journey: Journey
  unpublishFn?: (journeyId: string, disableJourney: boolean) => Promise<void>
  disabled?: boolean
}

export function JourneyUnpublishButton(props: JourneyUnpublishButtonProps) {
  const { journey, unpublishFn = _.noop, disabled = false } = props

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [kickOutShoppers, setkickOutShoppers] = useState<boolean>(false)

  const handleKickOutShoppersChange = _ => {
    setkickOutShoppers(!kickOutShoppers)
  }

  const closeDialog = () => setDialogOpen(false)
  const unpublish = () => unpublishFn(journey.id, kickOutShoppers)

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
        sx={{ ml: 1 }}
      >
        DEACTIVATE JOURNEY
      </Button>

      {dialogOpen && (
        <Dialog onClose={closeDialog} open>
          <DialogTitle>Deactivate Journey</DialogTitle>
          <Divider />
          <DialogContent>
            <FormControl component='fieldset'>
              <Typography variant='body1' display='block'>
                How would you like to deactivate this journey?
              </Typography>
              <RadioGroup
                aria-label='kick-all-shoppers'
                name='kick-all-shoppers'
                value={kickOutShoppers}
                onChange={handleKickOutShoppersChange}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Deactivate journey and keep existing shoppers'
                />
                <Typography variant='caption' display='block' mt={-0.5} ml={4.4}>
                  This won't affect existing shoppers mid-flight in the journey
                </Typography>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Deactivate journey and drop all shoppers'
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button variant='text' color='secondary' onClick={unpublish} disabled={disabled}>
              DEACTIVATE JOURNEY
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
