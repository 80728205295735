import _ from 'lodash'

import { ComponentType } from '../enums'
import type { DelayInput, Journey, JourneyComponent } from '../types'

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24

/**
 * A "modern" sleep statement.
 *
 * @param ms The number of milliseconds to wait.
 */
export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Returns an optimized DelayInput object from the given minutes.
 *
 * ex) 3450 -> { days: 2, hours: 9, minutes: 30 }
 * ex) 1440 -> { days: 1, hours: 0, minutes: 0 }
 * ex) 60   -> { days: 0, hours: 1, minutes: 0 }
 * ex) 59   -> { days: 0, hours: 0, minutes: 59 }
 */
export function convertMinutesToDelayInput(minutes: number): DelayInput {
  const result: DelayInput = { days: 0, hours: 0, minutes: 0 }
  if (!_.isNumber(minutes) || minutes < 0) return result

  result.minutes = minutes

  if (result.minutes >= MINUTES_IN_DAY) {
    const days = _.floor(result.minutes / MINUTES_IN_DAY)
    result.days = days
    result.minutes -= days * MINUTES_IN_DAY
  }

  if (result.minutes >= MINUTES_IN_HOUR) {
    const hours = _.floor(result.minutes / MINUTES_IN_HOUR)
    result.hours = hours
    result.minutes -= hours * MINUTES_IN_HOUR
  }

  return result
}

/**
 * Returns a number in minutes from the given DelayInput object.
 *
 * ex) { days: 2, hours: 9, minutes: 30 } -> 3450
 * ex) { days: 1, hours: 0, minutes: 0 }  -> 1440
 * ex) { days: 0, hours: 1, minutes: 0 }  -> 60
 * ex) { days: 0, hours: 0, minutes: 59 } -> 59
 */
export function convertDelayInputToMinutes(delayInput: DelayInput): number {
  if (!delayInput) return 0

  let minutes = 0
  minutes += delayInput.minutes ?? 0
  minutes += (delayInput.hours ?? 0) * MINUTES_IN_HOUR
  minutes += (delayInput.days ?? 0) * MINUTES_IN_DAY

  return minutes
}

/**
 * Returns an array of Delay Components(JourneyComponent) objects that represent the delays before another SMS Components.
 */
export function getDelaysBeforeAnotherSms(
  journey: Journey | undefined,
  componentId: string | undefined,
  delayComponents: JourneyComponent[]
): JourneyComponent[] | undefined {
  if (componentId === '') {
    delayComponents = []
    return delayComponents
  }

  const component = journey?.components.find(o => o.componentId === componentId)

  if (component?.componentType === ComponentType.START) {
    delayComponents = []
    return delayComponents
  } else if (component?.componentType === ComponentType.DELAY) {
    delayComponents.push(component)
  } else if (component?.componentType === ComponentType.SMS) {
    return delayComponents
  }

  const beforeComponent = component?.fromLinks[0]
  if (beforeComponent?.componentType === ComponentType.START) {
    delayComponents = []
    return delayComponents
  } else if (beforeComponent?.componentType === ComponentType.DELAY) {
    delayComponents.push(beforeComponent)
  } else if (beforeComponent?.componentType === ComponentType.SMS) {
    return delayComponents
  } else {
    const beforeComponentObject = journey?.components.find(
      o => o.componentId === beforeComponent?.componentId
    )

    return getDelaysBeforeAnotherSms(
      journey,
      beforeComponentObject?.fromLinks[0].componentId || '',
      delayComponents
    )
  }
}

/**
 * Returns a boolean value that represents whether the total delay in all delay components is valid or not.
 */
export function validDelay(components: JourneyComponent[] | undefined): boolean {
  let totalDelayInMinutes = 0
  components?.forEach(component => {
    const delayInMinutes = convertDelayInputToMinutes({
      days: parseInt(component?.componentConfig?.days || '0'),
      hours: parseInt(component?.componentConfig?.hours || '0'),
      minutes: parseInt(component?.componentConfig?.minutes || '0')
    })
    totalDelayInMinutes += delayInMinutes
  })

  return totalDelayInMinutes > 3 * 24 * 60 // 3 days
}
