import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input RunScheduledReportInput {
 *   journeyId: ID!
 *   description: String = ""
 *   version: String = ""
 */
export const RUN_SCHEDULED_REPORT = gql`
  mutation run_scheduled_report($runScheduledReport: RunScheduledReportInput!) {
    runScheduledReport(runInput: $runScheduledReport)
  }
`
