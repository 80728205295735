import { useState } from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import _ from 'lodash'

import type { ComponentCampaign } from '../../types'

interface CampaignSelectProps {
  campaigns: ComponentCampaign[]
  valueKey?: 'campaignId' | 'campaignName'
  value?: string // pre-select by 'campaignId' (or by 'campaignName'; defined by valueKey prop)
  onChange?: (campaign: ComponentCampaign) => void
  disabled?: boolean
}

export function CampaignSelect(props: CampaignSelectProps) {
  const { campaigns, valueKey = 'campaignId', value, onChange = _.noop, disabled = false } = props

  const [selected, setSelected] = useState<string>(value || '')

  const handleChange = e => {
    const campaignValue = e.target.value
    setSelected(campaignValue)
    onChange(campaigns.find(o => o[valueKey] === campaignValue))
  }

  return (
    <FormControl sx={{ minWidth: 150 }} error={!selected} disabled={disabled}>
      <InputLabel id='campaign-select-label'>Campaign</InputLabel>
      <Select
        labelId='campaign-select-label'
        id='campaign-select'
        value={selected}
        label='Campaign'
        onChange={handleChange}
        autoWidth
      >
        {campaigns.map(campaign => (
          <MenuItem key={campaign[valueKey]} value={campaign[valueKey]}>
            {campaign.campaignName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
