import { useState } from 'react'

import { FormControlLabel, FormGroup, Switch, Tooltip } from '@mui/material'

import _ from 'lodash'

interface CcaInputProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
}

/**
 * This component has been deprecated as of 7/13/2023.
 *
 * The "DelaySelect" component now controls the involvement of CCA hours.
 *
 * Learn more at:
 * https://assuranceiq.atlassian.net/browse/OMNI-1953
 */
export function CcaInput(props: CcaInputProps) {
  const { checked = false, onChange = _.noop, disabled = false } = props

  const [input, setInput] = useState<boolean>(checked)

  const handleChange = e => {
    const value = e.target.checked
    setInput(value)
    onChange(value)
  }

  return (
    <Tooltip
      title='Involve the CCA team? Outreach will be bound from 5am - 5pm Pacific Time on weekdays. (NOTE: This toggle has been deprecated. You can now use a Delay action to target CCA hours)'
      placement='bottom-start'
    >
      <FormGroup sx={{ ml: 1 }}>
        <FormControlLabel
          control={<Switch checked={input} onChange={handleChange} disabled={disabled} />}
          label='Involve CCA'
        />
      </FormGroup>
    </Tooltip>
  )
}
