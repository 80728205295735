import { useNavigate, useParams } from 'react-router-dom'

import _ from 'lodash'
import { useApolloClient, useMutation } from '@apollo/client'
import { useSnackbar } from '@assuranceiq/react-components'

import { ADD_COMPONENT_MUTATION } from '../graphql/mutations/add-component'
import { ALL_TAGS_QUERY } from '../graphql/queries/tags'
import { ARCHIVE_JOURNEY_MUTATION } from '../graphql/mutations/archive-journey'
import { CONFIGURE_DELAY_MUTATION } from '../graphql/mutations/configure-delay-component'
import { CONFIGURE_DIAL_MUTATION } from '../graphql/mutations/configure-dial-component'
import { CONFIGURE_EMAIL_MUTATION } from '../graphql/mutations/configure-email-component'
import { CONFIGURE_SMS_MUTATION } from '../graphql/mutations/configure-sms-component'
import { CONFIGURE_TODO_MUTATION } from '../graphql/mutations/configure-todo-component'
import { COPY_JOURNEY_MUTATION } from '../graphql/mutations/copy-journey'
import { CREATE_JOURNEY_MUTATION } from '../graphql/mutations/create-journey'
import { DELETE_JOURNEY_MUTATION } from '../graphql/mutations/delete-journey'
import type {
  DelayInput,
  JourneyEndRule,
  JourneyRoutingExperiment,
  RunScheduledReportInput
} from '../types'
import { EDIT_JOURNEY_MUTATION } from '../graphql/mutations/edit-journey'
import { JOURNEYS_QUERY } from '../graphql/queries/journeys'
import { JOURNEY_QUERY } from '../graphql/queries/journey'
import type { JourneyStartTrigger } from '../enums'
import { MOVE_COMPONENT_MUTATION } from '../graphql/mutations/move-component'
import { PUBLISH_JOURNEY_MUTATION } from '../graphql/mutations/publish-journey'
import { REMOVE_COMPONENT_MUTATION } from '../graphql/mutations/remove-component'
import { RUN_SCHEDULED_REPORT } from '../graphql/mutations/run-scheduled-report'
import { TEST_COMPONENT_MUTATION } from '../graphql/mutations/test-component'
import { UNARCHIVE_JOURNEY_MUTATION } from '../graphql/mutations/unarchive-journey'
import { UNPUBLISH_JOURNEY_MUTATION } from '../graphql/mutations/unpublish-journey'

export interface JourneyCreate {
  name: string
  description?: string
  loi?: string
  journeyStartTrigger: JourneyStartTrigger
  batchDailyLimit?: number
  alertDailyThreshold?: number
}

export interface JourneyEditInput {
  description?: string
  loi?: string
  journeyStartTrigger?: JourneyStartTrigger
  batchDailyLimit?: number | null
  alertDailyThreshold?: number
  journeyEndRules?: JourneyEndRule[]
  tags?: string[] | null
  routingExperiments?: JourneyRoutingExperiment[]
}

export interface JourneyTagsEditInput {
  journeyId: string
  tags?: string[] | null
}

export function useJourneyMutation() {
  const client = useApolloClient()

  const [createJourneyFn] = useMutation(CREATE_JOURNEY_MUTATION)
  const [editJourneyFn] = useMutation(EDIT_JOURNEY_MUTATION)
  const [publishJourneyFn] = useMutation(PUBLISH_JOURNEY_MUTATION)
  const [deleteJourneyFn] = useMutation(DELETE_JOURNEY_MUTATION)
  const [copyJourneyFn] = useMutation(COPY_JOURNEY_MUTATION)
  const [unpublishJourneyFn] = useMutation(UNPUBLISH_JOURNEY_MUTATION)
  const [archiveJourneyFn] = useMutation(ARCHIVE_JOURNEY_MUTATION)
  const [unArchiveJourneyFn] = useMutation(UNARCHIVE_JOURNEY_MUTATION)
  const [runScheduledReportFn] = useMutation(RUN_SCHEDULED_REPORT)

  const [addComponentFn] = useMutation(ADD_COMPONENT_MUTATION)
  const [removeComponentFn] = useMutation(REMOVE_COMPONENT_MUTATION)
  const [moveComponentFn] = useMutation(MOVE_COMPONENT_MUTATION)
  const [testComponentFn] = useMutation(TEST_COMPONENT_MUTATION)

  const [configureDialFn] = useMutation(CONFIGURE_DIAL_MUTATION)
  const [configureTodoFn] = useMutation(CONFIGURE_TODO_MUTATION)
  const [configureEmailFn] = useMutation(CONFIGURE_EMAIL_MUTATION)
  const [configureSmsFn] = useMutation(CONFIGURE_SMS_MUTATION)
  const [configureDelayFn] = useMutation(CONFIGURE_DELAY_MUTATION)

  const navigate = useNavigate()
  const { journeyId = '' } = useParams()

  const { showSnackbar } = useSnackbar()

  const refetchJourney = async () =>
    client.refetchQueries({
      include: [JOURNEY_QUERY]
    })

  const refetchJourneyList = async () =>
    client.refetchQueries({
      include: [JOURNEYS_QUERY, ALL_TAGS_QUERY]
    })

  /** CREATE JOURNEY **/
  const createJourney = async (journeyInput: JourneyCreate) => {
    const { data } = await createJourneyFn({
      variables: { journeyInput }
    })
    const savedJourneyId = data?.createJourney?.id
    if (!savedJourneyId) return showSnackbar('Failed to create journey', 'error')

    showSnackbar('Created journey')
    navigate(`/journeys/${savedJourneyId}`)
  }

  /** EDIT JOURNEY **/
  const editJourney = async (journeyEditInput: JourneyEditInput) => {
    const { data } = await editJourneyFn({
      variables: { journeyUpdate: { journeyId, ...journeyEditInput } }
    })
    const savedJourneyId = data?.editJourney?.id
    if (!savedJourneyId) return showSnackbar('Failed to edit journey', 'error')

    showSnackbar('Updated journey')
    refetchJourney()
  }

  /** EDIT JOURNEY TAGS **/
  const editJourneyTags = async (
    journeyTagsEditInput: JourneyTagsEditInput,
    onSuccess = _.noop
  ) => {
    const { data } = await editJourneyFn({
      variables: { journeyUpdate: { ...journeyTagsEditInput } }
    })
    const savedJourneyId = data?.editJourney?.id
    if (!savedJourneyId) return showSnackbar('Failed to edit journey tags', 'error')

    showSnackbar('Updated journey tags')
    onSuccess()
    refetchJourneyList()
  }

  /** PUBLISH JOURNEY **/
  const publishJourney = async (onSuccess = _.noop) => {
    const { data } = await publishJourneyFn({
      variables: { journeyPublish: { journeyId } }
    })
    const publishedJourneyId = data?.publishJourney?.id
    if (!publishedJourneyId) return showSnackbar('Failed to activate journey', 'error')

    showSnackbar('Activated journey')
    onSuccess()
    refetchJourney()
  }

  /** DELETE JOURNEY (DRAFT) **/
  const deleteJourney = async () => {
    const { data } = await deleteJourneyFn({
      variables: { deleteInput: { journeyId } }
    })
    const success = data?.deleteJourney
    if (!success) return showSnackbar('Failed to delete journey', 'error')

    showSnackbar('Deleted journey')
    navigate('/') // navigate back to home screen
  }

  /** ARCHIVE JOURNEY **/
  const archiveJourney = async (journeyId, onSuccess = _.noop) => {
    const { data } = await archiveJourneyFn({
      variables: { archiveInput: { journeyId } }
    })
    const success = data?.archiveJourney
    if (!success) return showSnackbar('Failed to archive journey', 'error')

    showSnackbar('Archived journey')
    onSuccess()
    refetchJourney()
  }

  /** UNARCHIVE JOURNEY **/
  const unArchiveJourney = async (journeyId, onSuccess = _.noop) => {
    const { data } = await unArchiveJourneyFn({
      variables: { unArchiveInput: { journeyId } }
    })
    const success = data?.unarchiveJourney
    if (!success) return showSnackbar('Failed to unarchive journey', 'error')

    showSnackbar('Unarchived journey')
    onSuccess()
    refetchJourney()
  }

  /** COPY JOURNEY **/
  const copyJourney = async targetJourneyName => {
    const { data } = await copyJourneyFn({
      variables: { journeyCopy: { sourceJourneyId: journeyId, targetJourneyName } }
    })
    const copiedJourneyId = data?.copyJourney?.id
    if (!copiedJourneyId) return showSnackbar('Failed to clone journey', 'error')

    showSnackbar('Cloned journey')
    navigate(`/journeys/${copiedJourneyId}`)
  }

  /** UNPUBLISH JOURNEY **/
  const unpublishJourney = async (journeyId, disableJourney, onSuccess = _.noop) => {
    const { data } = await unpublishJourneyFn({
      variables: { journeyUnpublish: { journeyId, disableJourney } }
    })
    const unpublishedJourneyId = data?.unpublishJourney?.id
    if (!unpublishedJourneyId) return showSnackbar('Failed to deactivate journey', 'error')

    showSnackbar('Deactivated journey')
    onSuccess()
    refetchJourney()
  }

  /** ADD COMPONENT **/
  const addComponent = async (componentType, after) => {
    const { data } = await addComponentFn({
      variables: {
        componentInput: {
          journeyId,
          componentName: ' ',
          componentType,
          after
        }
      }
    })
    const componentId = data?.insertNewComponent?.componentId
    if (!componentId) return showSnackbar('Failed to insert new component', 'error')

    showSnackbar('Added action')
    refetchJourney()
  }

  /** REMOVE COMPONENT **/
  const removeComponent = async componentId => {
    const { data } = await removeComponentFn({
      variables: {
        componentDelete: {
          journeyId,
          componentId
        }
      }
    })
    const success = data?.removeComponent
    if (!success) return showSnackbar('Failed to remove component', 'error')

    showSnackbar('Deleted action')
    refetchJourney()
  }

  /** MOVE COMPONENT **/
  const moveComponent = async (componentId, delta) => {
    const { data } = await moveComponentFn({
      variables: {
        componentInput: {
          journeyId,
          componentId,
          delta
        }
      }
    })
    const success = data?.moveComponent
    if (!success) return showSnackbar('Failed to move component', 'error')

    showSnackbar('Moved action')
    refetchJourney()
  }

  /** TEST COMPONENT **/
  const testComponent = async (componentId, payload) => {
    const { data } = await testComponentFn({
      variables: {
        componentTest: {
          journeyId,
          componentId,
          ...payload
        }
      }
    })
    const success = data?.testComponent
    if (!success) {
      showSnackbar('Failed to test component', 'error')
      return false
    }

    showSnackbar('Sent test')
    refetchJourney()
    return true
  }

  /** CONFIGURE DIAL COMPONENT **/
  const configureDialComponent = async (
    componentId,
    campaignId,
    campaignName,
    deadline,
    nextActionOnEnd
  ) => {
    const { data } = await configureDialFn({
      variables: {
        dialConfig: {
          journeyId,
          componentId,
          campaignId,
          campaignName,
          deadline,
          nextActionOnEnd
        }
      }
    })
    const id = data?.configureDialComponent?.componentId
    if (!id) return showSnackbar('Failed to configure call component', 'error')

    showSnackbar('Configured call action')
    refetchJourney()
  }

  /** CONFIGURE TO-DO COMPONENT **/
  const configureTodoComponent = async (componentId, campaignId, campaignName) => {
    const { data } = await configureTodoFn({
      variables: {
        todoConfig: {
          journeyId,
          componentId,
          campaignId,
          campaignName
        }
      }
    })
    const id = data?.configureTodoComponent?.componentId
    if (!id) return showSnackbar('Failed to configure ToDo component', 'error')

    showSnackbar('Configured ToDo action')
    refetchJourney()
  }

  /** CONFIGURE EMAIL COMPONENT **/
  const configureEmailComponent = async (
    componentId,
    campaignId,
    campaignName,
    redOakId,
    involvesCca = false,
    deadline
  ) => {
    const { data } = await configureEmailFn({
      variables: {
        emailConfig: {
          journeyId,
          componentId,
          campaignId,
          campaignName,
          redOakId,
          involvesCca,
          deadline
        }
      }
    })
    const id = data?.configureEmailComponent?.componentId
    if (!id) return showSnackbar('Failed to configure email component', 'error')

    showSnackbar('Configured email action')
    refetchJourney()
  }

  /** CONFIGURE SMS COMPONENT **/
  const configureSmsComponent = async (
    componentId,
    campaignId,
    campaignName,
    redOakId,
    involvesCca = false,
    deadline
  ) => {
    const { data } = await configureSmsFn({
      variables: {
        smsConfig: {
          journeyId,
          componentId,
          campaignId,
          campaignName,
          redOakId,
          involvesCca,
          deadline
        }
      }
    })
    const id = data?.configureSmsComponent?.componentId
    if (!id) return showSnackbar('Failed to configure SMS component', 'error')

    showSnackbar('Configured SMS action')
    refetchJourney()
  }

  /** CONFIGURE DELAY COMPONENT **/
  const configureDelayComponent = async (componentId, delayInput: DelayInput) => {
    const { data } = await configureDelayFn({
      variables: {
        delayConfig: {
          journeyId,
          componentId,
          campaignName: ' ',
          ...delayInput
        }
      }
    })
    const id = data?.configureDelayComponent?.componentId
    if (!id) return showSnackbar('Failed to configure delay component', 'error')

    showSnackbar('Configured delay action')
    refetchJourney()
  }

  /** RUN SCHEDULED REPORT **/
  const runScheduledReport = async (runScheduledReportInput: RunScheduledReportInput) => {
    if (!runScheduledReportInput.journeyId) {
      return showSnackbar('Failed to run scheduled report', 'error')
    }

    const { data } = await runScheduledReportFn({
      variables: { runScheduledReport: { ...runScheduledReportInput } }
    })
    const success = data?.runScheduledReport
    if (!success) return showSnackbar('Failed to trigger scheduled report', 'error')

    showSnackbar('Triggered scheduled report')
    refetchJourney()
  }

  return {
    createJourney,
    editJourney,
    editJourneyTags,
    publishJourney,
    archiveJourney,
    unArchiveJourney,
    deleteJourney,
    copyJourney,
    unpublishJourney,
    addComponent,
    removeComponent,
    moveComponent,
    testComponent,
    configureDialComponent,
    configureTodoComponent,
    configureEmailComponent,
    configureSmsComponent,
    configureDelayComponent,
    runScheduledReport
  }
}
