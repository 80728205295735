import React, { useEffect } from 'react'

import { Loading } from '@assuranceiq/react-components'
import { useLazyQuery } from '@apollo/client'

import { Error } from '../components/Error'
import { JOURNEYS_QUERY } from '../graphql/queries/journeys'
import { JOURNEY_QUERY } from '../graphql/queries/journey'
import type { Journey, JourneyField } from '../types'
import { ListItemSkeleton } from '../components/ListItemSkeleton'
import { useContext } from './useContext'

interface RenderProps {
  journeys: Journey[]
  journeyField: JourneyField
}

interface JourneyQueryProps {
  journeyId?: string
  showSkeleton?: boolean
  noCache?: boolean
  children: ({ journeys, journeyField }: RenderProps) => React.ReactNode
}

export const JourneyContext = React.createContext(undefined)

export default function JourneyQuery(props: JourneyQueryProps) {
  const { journeyId, showSkeleton = false, noCache = false, children } = props

  const [doQuery, { loading, error, data }] = useLazyQuery(
    journeyId ? JOURNEY_QUERY : JOURNEYS_QUERY,
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' }
  )

  useEffect(() => {
    doQuery({ variables: journeyId ? { journeyId } : {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyId])

  if (error) {
    console.error('Error while querying journeys!', error)
    return <Error errors={error as never} />
  }

  if (loading || !data) {
    if (showSkeleton && !journeyId) return <ListItemSkeleton repeat={20} />
    return <Loading />
  }

  let journeys = []
  let journeyField: JourneyField = {}
  if (data) {
    journeys = journeyId ? [data.journey] : data.journeys || []
    journeyField = data.journeyField || {}
  }
  return (
    <JourneyContext.Provider value={journeyId ? data?.journey : undefined}>
      {children({ journeys, journeyField })}
    </JourneyContext.Provider>
  )
}

export const useJourney = () => useContext<Journey>(JourneyContext)
