import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input CampaignCreateInput {
 *   """Type of component to create"""
 *   componentType: ComponentType!
 *   componentSource: ComponentSource!
 *   name: String!
 *   description: String = ""
 *
 *   """Campaign type"""
 *   businessType: CampaignBusinessType = null
 * }
 */
export const CREATE_CALL_CAMPAIGN_MUTATION = gql`
  mutation createCampaign($campaignCreateInput: CampaignCreateInput!) {
    createCampaign(campaignCreateInput: $campaignCreateInput) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
