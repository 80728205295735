import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const ALL_TAGS_QUERY = gql`
  query {
    allTags
  }
`
