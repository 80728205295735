import Confetti from 'react-confetti'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Check, CheckCircle, Circle, Report } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import _ from 'lodash'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useSnackbar } from '@assuranceiq/react-components'

import { AddComponentMenu } from '../components/AddComponentMenu'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { CloneButton } from '../components/CloneButton'
import { ComponentsView } from '../components/ComponentsView'
import type { Journey, JourneyField, JourneyRoutingExperiment } from '../types'
import { JourneyCreate, JourneyEditInput, useJourneyMutation } from '../hooks/useJourneyMutation'
import {
  JourneyEndTrigger,
  JourneyStartTrigger,
  JourneyStatus,
  LineOfInsurance,
  TriggerEvent
} from '../enums'
import { JourneyHubLink } from '../components/JourneyHubLink'
import JourneyNamesQuery from '../hooks/JourneyNamesQuery'
import JourneyQuery from '../hooks/JourneyQuery'
import { JourneyTrafficInput } from '../components/JourneyTrafficInput'
import { JourneyUnpublishButton } from '../components/JourneyUnpublishButton'
import { LoiIcon } from '../components/LoiIcon'
import { NO_VALUE } from '../components/ToggleNumberInput'
import { NumberInput } from '../components/NumberInput'
import { formatLocale } from '../utils/number'
import { getDateOnly } from '../utils/date'
import { haveSameContents } from '../utils/array'
import { pluralize } from '../utils/pluralize'
import { scrollToTop } from '../utils/scroll'
import { useDebounce } from '../hooks/useDebounce'
import { useGlobalState } from '../store'
import { validateJourney } from '../utils/journey'

import styles from './JourneyDetail.module.scss'

export function JourneyDetail() {
  const { journeyId = '' } = useParams()
  if (!journeyId) return <Main />
  return (
    <JourneyQuery journeyId={journeyId} noCache>
      {({ journeys, journeyField }) => {
        if (_.isEmpty(journeys)) {
          return <Alert severity='warning'>No matching journey found.</Alert>
        }
        const journey = journeys.pop() as Journey
        return <Main journey={journey} journeyField={journeyField} />
      }}
    </JourneyQuery>
  )
}

function Main({ journey, journeyField }: { journey?: Journey; journeyField?: JourneyField }) {
  const deadlineRule = _.find(journey?.journeyEndRules, {
    triggerEvent: TriggerEvent.DEADLINE_EXPIRED
  })
  const deadlineDate = getDateOnly(deadlineRule?.deadline)

  const [name, setName] = useState<string>(journey?.name || '')
  const [description, setDescription] = useState<string>(journey?.description || '')
  const [loi, setLoi] = useState<string>(journey?.loi || '')
  const [journeyStartTrigger, setJourneyStartTrigger] = useState<JourneyStartTrigger>(
    journey?.journeyStartTrigger || JourneyStartTrigger.API
  )
  const [batchDailyLimit, setBatchDailyLimit] = useState<number>(journey?.batchDailyLimit || 0)
  const [alertDailyThreshold, setAlertDailyThreshold] = useState<number>(
    journey?.alertDailyThreshold == null ? NO_VALUE : journey.alertDailyThreshold
  )
  const [endDate, setEndDate] = useState<string | undefined>(deadlineDate)
  const [hasInvalidDate, setHasInvalidDate] = useState<boolean>(false)
  const [journeyEndTrigger, setJourneyEndTrigger] = useState<JourneyEndTrigger>(
    deadlineRule ? JourneyEndTrigger.DATE : JourneyEndTrigger.DEFAULT
  )
  const [routingExperiments, setRoutingExperiments] = useState<
    JourneyRoutingExperiment[] | undefined
  >(journey?.routingExperiments)
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)

  const [triggerReportDialogOpen, setTriggerReportDialogOpen] = useState<boolean>(false)

  const [confetti, setConfetti] = useState<boolean>(false)

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const { showSnackbar } = useSnackbar()

  const {
    createJourney,
    editJourney,
    publishJourney,
    archiveJourney,
    unArchiveJourney,
    deleteJourney,
    copyJourney,
    unpublishJourney,
    addComponent,
    runScheduledReport
  } = useJourneyMutation()

  const isActive = journey?.status === JourneyStatus.PUBLISHED
  const isInactive = _.includes([JourneyStatus.DISABLED, JourneyStatus.DRAFT], journey?.status)
  const isArchived = journey?.status === JourneyStatus.ARCHIVED
  const disableEdit = isActive || isArchived || !hasEditPermission
  const isBatch = journeyStartTrigger === JourneyStartTrigger.BATCH

  const closeDialog = () => setTriggerReportDialogOpen(false)
  const triggerReport = async () => {
    await runScheduledReport({ journeyId: journey?.id })
    setTriggerReportDialogOpen(false)
  }

  // auto-save changes when editing journey
  useDebounce(
    () => {
      const payload: JourneyEditInput = {}
      if (description !== journey?.description) {
        payload.description = description
      }
      if (loi !== journey?.loi) {
        payload.loi = loi
      }
      if (journeyStartTrigger !== journey?.journeyStartTrigger) {
        payload.journeyStartTrigger = journeyStartTrigger
      }
      if (batchDailyLimit !== journey?.batchDailyLimit) {
        payload.batchDailyLimit = batchDailyLimit
      }
      if (alertDailyThreshold !== journey?.alertDailyThreshold) {
        payload.alertDailyThreshold = alertDailyThreshold
      }
      if (journeyEndTrigger === JourneyEndTrigger.DATE) {
        payload.journeyEndRules = [
          { triggerEvent: TriggerEvent.DEADLINE_EXPIRED, deadline: endDate }
        ]
      } else {
        payload.journeyEndRules = []
      }

      if (
        isActive &&
        !_.isNil(journey?.batchDailyLimit) &&
        journey.batchDailyLimit < batchDailyLimit &&
        !journey?.scheduledReportRunning
      ) {
        setTriggerReportDialogOpen(true)
      }

      return editJourney(payload)
    },
    [
      description,
      loi,
      journeyStartTrigger,
      batchDailyLimit,
      alertDailyThreshold,
      journeyEndTrigger,
      endDate
    ],
    !journey || isArchived || !hasEditPermission
  )

  // auto-save changes to A/B tests (only when the journey is not "Active")
  useDebounce(
    () => {
      if (isActive) {
        setShowSaveButton(true)
      } else {
        if (!haveSameContents(routingExperiments, journey?.routingExperiments)) {
          showSnackbar('Saving changes...', 'info')
          return editJourney({ routingExperiments })
        }
      }
    },
    [routingExperiments],
    !journey || !hasEditPermission,
    0
  )

  const create = () => {
    const payload: JourneyCreate = { name, description, loi, journeyStartTrigger }
    if (batchDailyLimit > 0) payload.batchDailyLimit = batchDailyLimit
    return createJourney(payload)
  }

  const publish = () => {
    const isValid = validateJourney(journey)
    if (isValid && window.confirm('You are about to activate this journey. Proceed?'))
      return publishJourney(() => {
        scrollToTop() // scroll to top to see confetti
        setConfetti(true)
      })
  }

  const archive = () => {
    if (window.confirm('You are about to archive this journey. Proceed?'))
      return archiveJourney(journey?.id)
  }

  const unarchive = () => {
    if (window.confirm('You are about to unarchive this journey. Proceed?'))
      return unArchiveJourney(journey?.id)
  }

  const showReportStatusBadge = journey && journey.status === JourneyStatus.PUBLISHED

  return (
    <div className={clsx(styles.root, 'animate__animated animate__fadeIn')}>
      <div className={styles.header}>
        <Breadcrumbs
          root='Journeys'
          title={journey?.name || 'New Journey'}
          subtitle={getBreadcrumbSubtitle(journey)}
          metadata={getBreadcrumbMetadata(journey)}
        />

        {journey && (
          <div className={styles.right}>
            <div className={styles.metadata}>
              {isActive && (
                <div>
                  Published at {dayjs(journey.publishedAt).format('MM/DD/YYYY @h:mma')} by{' '}
                  <span className={styles.author}>{journey.publishedBy}</span>
                </div>
              )}
              {isArchived && (
                <div>
                  Disabled at {dayjs(journey.disabledAt).format('MM/DD/YYYY @h:mma')} by{' '}
                  <span className={styles.author}>{journey.disabledBy}</span>
                </div>
              )}
              <div>
                Updated at {dayjs(journey.modifiedAt).format('MM/DD/YYYY @h:mma')} by{' '}
                <span className={styles.author}>{journey.modifiedBy}</span>
              </div>
              <div>
                Created at {dayjs(journey.createdAt).format('MM/DD/YYYY @h:mma')} by{' '}
                <span className={styles.author}>{journey.createdBy}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <Box mt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <TextField
          id='name'
          label='Name'
          variant='outlined'
          value={name}
          onChange={e => setName(e.target.value)}
          autoComplete='off'
          disabled={!!journey || disableEdit}
          sx={{ minWidth: '35rem' }}
          autoFocus
          required
        />

        {journey && (
          <Box>
            <JourneyHubLink journeyName={journey.name} variant='contained' sx={{ mr: 1 }} />
            <CloneButton
              label='journey'
              defaultName={journey.name}
              onConfirm={copyJourney}
              disabled={!hasEditPermission}
            />
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <TextField
          id='description'
          label='Description'
          variant='outlined'
          value={description}
          onChange={e => setDescription(e.target.value)}
          autoComplete='off'
          disabled={disableEdit}
          fullWidth
        />
      </Box>

      <FormControl sx={{ display: 'flex', mt: 2 }}>
        <InputLabel id='loi-select-label'>Line of Insurance</InputLabel>
        <Select
          labelId='loi-select-label'
          id='loi-select'
          className={styles.loiSelect}
          value={loi}
          label='Line of Insurance'
          onChange={e => setLoi(e.target.value)}
        >
          {_.values(LineOfInsurance).map(v => (
            <MenuItem key={v} value={v}>
              <ListItemIcon>
                <LoiIcon loi={v} />
                <Typography sx={{ ml: 2 }} color='black'>
                  {v === LineOfInsurance.P_and_C ? _.toUpper(v) : _.upperFirst(v)}
                </Typography>
              </ListItemIcon>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mt: 4, ml: 1 }} disabled={disableEdit}>
        <FormLabel id='triggering-event-radio'>Triggering Event</FormLabel>
        <RadioGroup
          aria-labelledby='triggering-event-radio'
          name='triggering-event'
          value={journeyStartTrigger}
          onChange={e => setJourneyStartTrigger(e.target.value as JourneyStartTrigger)}
        >
          <FormControlLabel
            className={styles.radioLabel}
            value={JourneyStartTrigger.API}
            control={<Radio />}
            label={
              <Box display='flex' alignItems='center'>
                API Event{' '}
                {journeyStartTrigger === JourneyStartTrigger.API && (
                  <>
                    <NumberInput
                      label='Alert daily journey starts'
                      tooltip="If this threshold is exceeded in a single day, you'll receive an alert via the Slack channel: #omnichannel-biz-notifications"
                      size='small'
                      value={alertDailyThreshold}
                      onChange={setAlertDailyThreshold}
                      disabled={isArchived || !hasEditPermission}
                      sx={{ ml: 2 }}
                    />
                  </>
                )}
              </Box>
            }
          />
          <FormControlLabel
            className={styles.radioLabel}
            value={JourneyStartTrigger.BATCH}
            control={<Radio />}
            label={
              <Box display='flex' alignItems='center'>
                Scheduled Report{' '}
                {journeyStartTrigger === JourneyStartTrigger.BATCH && (
                  <>
                    <NumberInput
                      label='Daily Cap'
                      tooltip='You can limit the number of daily journey starts triggered via Scheduled Reports. Daily cap comes into effect on the very next batch processing time.'
                      postfix='per day'
                      size='small'
                      value={batchDailyLimit}
                      onChange={setBatchDailyLimit}
                      disabled={isArchived || !hasEditPermission || !isBatch}
                      sx={{ ml: 2 }}
                    />

                    {!showReportStatusBadge
                      ? null
                      : journey &&
                        (journey?.scheduledReportRunning ? (
                          <Chip
                            className={styles.proccessingChip}
                            label='REPORT PROCESSING'
                            color='primary'
                            icon={
                              <img
                                src='/images/spinner-dots.svg'
                                alt='...'
                                className={styles.proccessingIcon}
                              />
                            }
                          />
                        ) : (
                          <Chip
                            className={styles.successChip}
                            label='REPORT READY'
                            color='success'
                            icon={<CheckCircle className={styles.successIcon} />}
                          />
                        ))}

                    {journey?.lastRunScheduledReport && (
                      <Chip
                        className={styles.fileIconChip}
                        label={journey?.lastRunScheduledReport}
                        icon={<img src='/images/file.svg' alt='Scheduled Report File' />}
                      />
                    )}

                    <Dialog open={triggerReportDialogOpen}>
                      <DialogTitle>Run Scheduled Report</DialogTitle>
                      <DialogContent>
                        <Typography gutterBottom>
                          Would you like to immediately re-process the report with the increased
                          daily cap?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeDialog} color='inherit'>
                          Not Now
                        </Button>
                        <Button onClick={triggerReport}>Process Now</Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>

      {journey && (
        <Box mt={journeyStartTrigger === JourneyStartTrigger.BATCH ? 4 : 3}>
          <ComponentsView
            components={journey.components}
            menu={
              journey && !isActive && !disableEdit ? (
                <AddComponentMenu
                  onAdd={(componentType, after) => addComponent(componentType, after)}
                />
              ) : null
            }
            journeyField={journeyField || {}}
          />
        </Box>
      )}

      {journey && (
        <>
          <FormControl
            className={clsx(isActive && styles.trafficEditBox)}
            component={isActive ? 'fieldset' : 'div'}
            sx={{ display: 'flex', mt: 4, ml: 1 }}
            disabled={isArchived || !hasEditPermission}
          >
            {isActive && <legend className={styles.legend}>A/B Testing</legend>}
            {!isActive && (
              <FormLabel id='triggering-event-radio' sx={{ mb: 0.5 }}>
                A/B Testing
              </FormLabel>
            )}

            <JourneyTrafficInput
              masterJourneyName={journey.name}
              NamesQuery={JourneyNamesQuery}
              values={routingExperiments}
              onChange={setRoutingExperiments}
              disabled={isArchived || !hasEditPermission}
            />
          </FormControl>

          {showSaveButton && (
            <Button
              variant='contained'
              size='small'
              startIcon={<Check />}
              onClick={async () => {
                setShowSaveButton(false)
                showSnackbar('Saving changes...', 'info')
                await editJourney({ routingExperiments })
              }}
              sx={{ mt: 0.5, ml: 1 }}
            >
              Save Traffic
            </Button>
          )}
        </>
      )}

      {journey && (
        <FormControl sx={{ mt: 3, ml: 1, mb: 0.5 }} disabled={disableEdit} fullWidth>
          <FormLabel id='terminating-event-radio'>Terminating Event</FormLabel>
          <RadioGroup
            aria-labelledby='terminating-event-radio'
            value={journeyEndTrigger}
            name='terminating-event'
            onChange={e => setJourneyEndTrigger(_.toNumber(e.target.value))}
          >
            <FormControlLabel
              value={JourneyEndTrigger.DEFAULT}
              control={<Radio />}
              label='All actions are complete'
            />
            <FormControlLabel
              value={JourneyEndTrigger.DATE}
              control={<Radio />}
              label={
                <Box display='flex' alignItems='center'>
                  On a specific date
                  {journeyEndTrigger === JourneyEndTrigger.DATE && (
                    <>
                      :
                      <DatePicker
                        className={styles.datePicker}
                        value={dayjs(endDate || '')}
                        onError={error => setHasInvalidDate(!!error)}
                        onChange={date => setEndDate((date as Dayjs).format('YYYY-MM-DD'))}
                        autoFocus={_.isEmpty(endDate)}
                        sx={{ ml: 2 }}
                        disabled={disableEdit}
                        disablePast
                      />
                      <Typography ml={2} variant='caption' color='textSecondary'>
                        (shoppers mid-flight will also exit the journey on this date)
                      </Typography>
                    </>
                  )}
                </Box>
              }
            />
          </RadioGroup>
          <FormHelperText>You can always send a Stop action</FormHelperText>
        </FormControl>
      )}

      <Box mt={3}>
        {!journey && (
          <Button variant='contained' color='primary' onClick={create} disabled={!name}>
            CREATE AND CONTINUE
          </Button>
        )}

        {isActive && (
          <JourneyUnpublishButton
            journey={journey}
            unpublishFn={unpublishJourney}
            disabled={!hasEditPermission}
          />
        )}

        {isInactive && (
          <>
            <Button
              variant='contained'
              color='success'
              onClick={publish}
              sx={{ mt: 1 }}
              disabled={disableEdit || hasInvalidDate}
            >
              ACTIVATE JOURNEY
            </Button>
            <Button
              variant='contained'
              color='inherit'
              onClick={archive}
              sx={{ mt: 1, ml: 1 }}
              disabled={disableEdit}
            >
              ARCHIVE JOURNEY
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={() =>
                window.confirm('You are about to delete this journey. Proceed?') && deleteJourney()
              }
              sx={{ mt: 1, ml: 1 }}
              disabled={disableEdit}
            >
              DELETE JOURNEY
            </Button>
          </>
        )}

        {isArchived && (
          <>
            <Button
              variant='contained'
              color='secondary'
              onClick={unarchive}
              sx={{ mt: 1, ml: 1 }}
              disabled={!hasEditPermission}
            >
              UNARCHIVE JOURNEY
            </Button>
          </>
        )}
      </Box>

      {confetti && <Confetti gravity={0.4} recycle={false} />}
    </div>
  )
}

function getBreadcrumbSubtitle(journey?: Journey) {
  if (!journey || !journey.status) return null

  let statusLabel = 'Archived'
  let statusColor: 'success' | 'secondary' | 'disabled' = 'disabled'

  if (journey.status === JourneyStatus.PUBLISHED) {
    statusLabel = 'Active'
    statusColor = 'success'
  } else if (journey.status === JourneyStatus.DISABLED || journey.status === JourneyStatus.DRAFT) {
    statusLabel = 'Inactive'
    statusColor = 'secondary'
  }

  return (
    <Tooltip title={statusLabel} placement='top' arrow>
      <Circle fontSize='small' color={statusColor} />
    </Tooltip>
  )
}

function getBreadcrumbMetadata(journey?: Journey) {
  if (!journey) return null

  const chips: React.ReactNode[] = []

  const { todayJourneyStarts, midFlightShopperCount } = journey.stats
  if (todayJourneyStarts > 0) {
    const thresholdReached =
      journey.alertDailyThreshold >= 0 && todayJourneyStarts > journey.alertDailyThreshold
    const tooltip = thresholdReached
      ? `Exceeds the daily alert threshold of ${formatLocale(
          journey.alertDailyThreshold
        )}. You can adjust the threshold below.`
      : ''
    chips.push(
      <Tooltip title={tooltip} placement='bottom' arrow>
        <Chip
          label={`${formatLocale(todayJourneyStarts)} started today`}
          icon={thresholdReached ? <Report /> : undefined}
          color={thresholdReached ? 'secondary' : 'default'}
          variant='outlined'
          size='small'
          sx={{ mr: 1 }}
        />
      </Tooltip>
    )
  }

  const { remainingJourneyStarts } = journey
  if (remainingJourneyStarts != null) {
    const remainingJourneyStartsFormatted = formatLocale(remainingJourneyStarts)
    const remainingJourneyStartsLabel = remainingJourneyStartsFormatted + ' remaining'
    const remainingJourneyStartsTooltip =
      remainingJourneyStartsFormatted +
      ' remaining valid journey starts in file as the completion of the last run.'
    chips.push(
      <Tooltip title={remainingJourneyStartsTooltip} placement='bottom' arrow>
        <Chip label={remainingJourneyStartsLabel} variant='outlined' size='small' sx={{ mr: 1 }} />
      </Tooltip>
    )
  }

  const midFlightShopperCountFormatted = formatLocale(midFlightShopperCount)
  const midFlightShopperCountTooltip =
    midFlightShopperCount > 0
      ? `There ${midFlightShopperCount === 1 ? 'is' : 'are'} ${pluralize(
          midFlightShopperCount,
          'shopper'
        )}
        currently in the middle of their journey`
      : 'No shoppers are in journey mid-flight'
  const midFlightShopperCountLabel = midFlightShopperCountFormatted + ' mid-flight'
  chips.push(
    <Tooltip title={midFlightShopperCountTooltip} placement='bottom' arrow>
      <Chip label={midFlightShopperCountLabel} variant='outlined' size='small' sx={{ mr: 1 }} />
    </Tooltip>
  )

  if (_.isEmpty(chips)) return null
  return (
    <Box>
      {chips.map((chip, i) => (
        <React.Fragment key={_.get(chip, 'props.children.props.label', i)}>{chip}</React.Fragment>
      ))}
    </Box>
  )
}
