import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyArchiveInput {
 *   journeyId: ID!
 * }
 */
export const ARCHIVE_JOURNEY_MUTATION = gql`
  mutation ArchiveJourney($archiveInput: JourneyArchiveInput!) {
    archiveJourney(journeyArchive: $archiveInput)
  }
`
