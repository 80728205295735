import React, { useEffect } from 'react'

import { Loading } from '@assuranceiq/react-components'
import { useLazyQuery } from '@apollo/client'

import type { ComponentType, JourneyStatus } from '../enums'
import { Error } from '../components/Error'
import { FILTERED_JOURNEYS_QUERY } from '../graphql/queries/filtered_journeys'
import type { Journey } from '../types'
import { ListItemSkeleton } from '../components/ListItemSkeleton'
import { useContext } from './useContext'

interface RenderProps {
  journeys: Journey[]
}

interface FilteredJourneysQueryProps {
  status?: JourneyStatus[]
  createdBy?: string
  loi?: string[]
  journeyCampaigns?: ComponentType[]
  tags?: string[]
  routingExperimentEnabled?: boolean
  showSkeleton?: boolean
  noCache?: boolean
  children: ({ journeys }: RenderProps) => React.ReactNode
}

export const JourneyContext = React.createContext(undefined)

export default function FilteredJourneysQuery(props: FilteredJourneysQueryProps) {
  const {
    status,
    createdBy,
    loi,
    journeyCampaigns,
    tags,
    routingExperimentEnabled = false,
    showSkeleton = false,
    noCache = false,
    children
  } = props

  const [doQuery, { loading, error, data }] = useLazyQuery(FILTERED_JOURNEYS_QUERY, {
    fetchPolicy: noCache ? 'no-cache' : 'cache-first'
  })

  useEffect(() => {
    doQuery({
      variables: {
        filterInput: {
          status,
          createdBy,
          loi,
          journeyCampaigns,
          tags,
          routingExperimentEnabled
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  if (error) {
    console.error('Error while querying journeys!', error)
    return <Error errors={error as never} />
  }
  if (loading || !data) {
    if (showSkeleton) return <ListItemSkeleton repeat={20} />
    return <Loading />
  }

  let journeys = []
  if (data) {
    journeys = data.filteredJourneys || []
  }
  return (
    <JourneyContext.Provider value={data?.filteredJourneys}>
      {children({ journeys })}
    </JourneyContext.Provider>
  )
}

export const useJourney = () => useContext<Journey>(JourneyContext)
