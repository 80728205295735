import React, { useEffect } from 'react'

import { Loading } from '@assuranceiq/react-components'
import { useLazyQuery } from '@apollo/client'

import type { ComponentCampaign, UnregisteredTemplate } from '../types'
import { Error } from '../components/Error'
import { UNREGISTERED_TEMPLATES_QUERY } from '../graphql/queries/unregistered-templates'
import { UNREGISTERED_TEMPLATE_QUERY } from '../graphql/queries/unregistered-template'

interface RenderProps {
  templates: UnregisteredTemplate[]
}

interface UnregisteredTemplateQueryProps {
  templateId?: string
  children: ({ templates }: RenderProps) => React.ReactNode
  mockResults?: ComponentCampaign // helpful when we want to skip query and return fixed results
}

export default function UnregisteredTemplateQuery(props: UnregisteredTemplateQueryProps) {
  const { templateId, children, mockResults } = props

  const [doQuery, { loading, error, data }] = useLazyQuery(
    templateId ? UNREGISTERED_TEMPLATE_QUERY : UNREGISTERED_TEMPLATES_QUERY,
    { fetchPolicy: 'no-cache' } // ensures the list is up-to-date right after registering
  )

  useEffect(() => {
    if (mockResults) return
    const variables = {}
    if (templateId) variables['campaignId'] = templateId
    doQuery({ variables })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, mockResults])

  if (mockResults) {
    return <>{children({ templates: [mockResults as UnregisteredTemplate] })}</>
  }

  if (error) {
    console.error('Error while querying unregistered templates!', error)
    return <Error errors={error as never} />
  }
  if (loading || !data) return <Loading />

  let templates = []
  if (data)
    templates = templateId
      ? [data.unregisteredEmailCampaign]
      : data.unregisteredEmailCampaigns?.unregisteredEmailCampaigns || []
  return <>{children({ templates })}</>
}
