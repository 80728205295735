import { ContentCopy } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { useSnackbar } from '@assuranceiq/react-components'

import styles from './CopyButton.module.scss'

interface CopyButtonProps {
  toolTip?: string
  value: string
}

export function CopyButton(props: CopyButtonProps) {
  const { toolTip = 'Copy', value } = props
  const { showSnackbar } = useSnackbar()
  const handleCopy = () => {
    navigator.clipboard.writeText(value)
    showSnackbar('Copied!', 'success')
  }
  return (
    <Tooltip title={toolTip} placement='top' arrow>
      <IconButton onClick={handleCopy} className={styles.copyIcon}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  )
}
