import _ from 'lodash'

import { ComponentType } from '../enums'
import { DD_ENV, JOURNEY_HUB_URL } from './environment-variables'
import type { JourneyComponent } from '../types'

const DD_LOGS_BASE_URL = 'https://app.datadoghq.com/logs'

export function findWindowLocationParameter(param: string) {
  let value: string | null = null
  window.location.search
    .substring(1)
    .split('&')
    .forEach(function (item) {
      const tmp = item.split('=')
      if (tmp[0] === param) value = decodeURIComponent(tmp[1])
    })
  return value
}

export function campaignDetailLink(
  componentType: string,
  campaignId: string,
  campaignName: string
) {
  let linkTo = ''
  if (campaignId === '' && campaignName === '') {
    linkTo = ''
  } else if (componentType === ComponentType.SMS) {
    linkTo = `/campaigns/SMS/${encodeURIComponent(campaignName)}`
  } else if (componentType === ComponentType.EMAIL) {
    linkTo = `/campaigns/Email/${encodeURIComponent(campaignId)}`
  } else if (componentType === ComponentType.CALL) {
    linkTo = `/campaigns/Call/${encodeURIComponent(campaignId)}`
  }

  return linkTo
}

/**
 * Generates a link to Datadog logs for the given Lead ID and journey component.
 */
export function getCampaignLogsUrl(leadId: number, component: JourneyComponent) {
  const params = {}
  const { componentConfig } = component
  if (_.includes([ComponentType.EMAIL, ComponentType.SMS], component.componentType)) {
    params[
      'query'
    ] = `env:${DD_ENV} service:(journey-camunda-gateway OR outreach-poller) (@request.data.campaignDetails.shopper.leadId:${leadId} AND @request.data.campaign.name:${componentConfig?.campaignName} OR @lead_id:${leadId})`
  } else if (component.componentType === ComponentType.CALL) {
    params[
      'query'
    ] = `env:${DD_ENV} service:(journey-camunda-gateway OR shovel* OR insurance*) @lead_id:${leadId} (@record.data.event_metadata.campaign_name:${componentConfig?.campaignName} OR @campaign_name:${componentConfig?.campaignName} OR journey_start OR -status:info)`
  }
  return `${DD_LOGS_BASE_URL}?${new URLSearchParams(params).toString()}`
}

export function getJourneyHubUrl(journeyName: string) {
  return `${JOURNEY_HUB_URL}/journeys/${encodeURIComponent(journeyName)}`
}
