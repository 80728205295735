import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { DataDogRUMProvider, SnackbarProvider } from '@assuranceiq/react-components'
import { StoreProvider } from 'easy-peasy'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION
} from './utils/environment-variables'
import Authenticate from './navigation/Authenticate'
import { Campaigns } from './pages/Campaigns'
import { EmailCampaign } from './pages/EmailCampaign'
import { Home } from './pages/Home'
import { JourneyDetail } from './pages/JourneyDetail'
import { Layout } from './navigation/Layout'
import { NotFound } from './pages/NotFound'
import { ShovelCampaign } from './pages/ShovelCampaign'
import { SmsCampaign } from './pages/SmsCampaign'
import { createApolloClient } from './graphql/apollo-client'
import { createStore } from './store'
import theme from './styles/theme'

import './styles/global.scss'

const client = createApolloClient()
const store = createStore()

const TEMP_AUTH_REDIRECT_KEY = 'initialURL'

export const App = function () {
  const navigate = useNavigate()

  useEffect(() => {
    const initialURL = sessionStorage.getItem(TEMP_AUTH_REDIRECT_KEY)
    if (!initialURL) {
      sessionStorage.setItem(TEMP_AUTH_REDIRECT_KEY, window.location.pathname)
    }
  }, [])

  const onRedirectCallback = () => {
    const initialURL = sessionStorage.getItem(TEMP_AUTH_REDIRECT_KEY)

    if (initialURL) {
      sessionStorage.removeItem(TEMP_AUTH_REDIRECT_KEY)
      navigate(initialURL)
    }
  }

  console.info('Rendering App')
  return (
    <StoreProvider store={store}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        responseType={AUTH0_RESPONSE_TYPE}
        scope={AUTH0_SCOPE}
        redirectUri={window.document.location.origin}
        onRedirectCallback={onRedirectCallback}
        post
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <SnackbarProvider>
              <DataDogRUMProvider
                applicationId={DD_APPLICATION_ID}
                clientToken={DD_CLIENT_TOKEN}
                service={DD_SERVICE}
                env={DD_ENV}
                sampleRate={DD_SAMPLE_RATE}
                trackInteractions={true}
                version={DD_VERSION}
              >
                <Authenticate>
                  <ApolloProvider client={client}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Layout>
                        <Routes>
                          <Route path='/' element={<Home />} />

                          <Route path='/journeys' element={<Home />} />
                          <Route path='/journeys/:journeyId' element={<JourneyDetail />} />
                          <Route path='/create-journey' element={<JourneyDetail />} />

                          <Route path='/campaigns' element={<Campaigns />} />
                          <Route path='/campaigns/:campaignType' element={<Campaigns />} />

                          <Route path='/campaigns/SMS/create-campaign' element={<SmsCampaign />} />
                          <Route path='/campaigns/SMS/:campaignName' element={<SmsCampaign />} />

                          <Route
                            path='/campaigns/Email/create-campaign'
                            element={<EmailCampaign />}
                          />
                          <Route path='/campaigns/Email/:campaignId' element={<EmailCampaign />} />

                          {/* redirect old campaign type paths to the new path (can remove soon) */}
                          <Route
                            path='/campaigns/Call-Shovel'
                            element={<Navigate to='/campaigns/Call' replace />}
                          />
                          <Route
                            path='/campaigns/Call-CRM'
                            element={<Navigate to='/campaigns/Call' replace />}
                          />

                          <Route path='/campaigns/Call/:campaignId' element={<ShovelCampaign />} />
                          <Route
                            path='/campaigns/Call/create-campaign'
                            element={<ShovelCampaign />}
                          />

                          <Route path='/*' element={<NotFound />} />
                        </Routes>
                      </Layout>
                    </LocalizationProvider>
                  </ApolloProvider>
                </Authenticate>
              </DataDogRUMProvider>
            </SnackbarProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </Auth0Provider>
    </StoreProvider>
  )
}
