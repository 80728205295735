import { gql } from '@apollo/client'

export const JOURNEY_FRAGMENT = gql`
  fragment JourneyFragment on Journey {
    id
    name
    description
    loi
    version
    status
    createdAt
    createdBy
    disabledAt
    disabledBy
    modifiedAt
    modifiedBy
    publishedAt
    publishedBy
    journeyStartTrigger
    batchDailyLimit
    alertDailyThreshold
    remainingJourneyStarts
    scheduledReportRunning
    lastRunScheduledReport
    tags
    stats {
      todayJourneyStarts
      midFlightShopperCount
    }
    journeyEndRules {
      triggerEvent
      deadline
    }
    routingExperiments {
      name
      traffic
      sendNextShopper
    }
  }
`
