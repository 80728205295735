import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input CampaignCreateInput {
 *   """Type of component to create"""
 *   componentType: ComponentType!
 *   name: String!
 *   description: String = ""
 *
 *   """Template content"""
 *   template: String = null
 *
 *   """Campaign type"""
 *   businessType: CampaignBusinessType = null
 *
 *   """SMS callback number"""
 *   smsCallbackNumber: String = null
 *
 *   """Approval status"""
 *   approvalStatus: Boolean = false
 *
 *   """List of sender phone numbers"""
 *   senderPool: [String] = []
 *
 *   """Sent by agent option"""
 *   sentByAgent: Boolean = false
 * }
 */
export const CREATE_SMS_CAMPAIGN_MUTATION = gql`
  mutation createCampaign($campaignCreateInput: CampaignCreateInput!) {
    createCampaign(campaignCreateInput: $campaignCreateInput) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
