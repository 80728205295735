import { Box, Skeleton } from '@mui/material'

import _ from 'lodash'

interface ListItemSkeletonProps {
  repeat?: number
  variant?: 'journey' | 'campaign'
}

export function ListItemSkeleton(props: ListItemSkeletonProps) {
  const { repeat = 1, variant = 'journey' } = props

  const isJourney = variant === 'journey'
  const topWidth = isJourney ? 200 : 325
  const middleWidth = isJourney ? 425 : 0
  const bottomWidth = isJourney ? 350 : 150

  return (
    <Box mt={4} ml={1.5}>
      {_.range(repeat).map(i => (
        <Box key={i} display='flex' mb={isJourney ? 4.8 : 4.75}>
          <Skeleton
            variant='circular'
            width={34}
            height={34}
            sx={{ mt: isJourney ? 1 : 0, ml: isJourney ? 0.3 : 0 }}
          />

          <Box ml={3}>
            <Skeleton variant='rectangular' width={topWidth} height={16} sx={{ mt: -0.5 }} />
            {isJourney && (
              <Skeleton variant='rectangular' width={middleWidth} height={16} sx={{ mt: 0.75 }} />
            )}
            <Skeleton
              variant='rectangular'
              width={bottomWidth}
              height={isJourney ? 14 : 16}
              sx={{ mt: isJourney ? 1.2 : 1.5 }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
