import { useEffect, useRef, useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material'

import _ from 'lodash'

import { isValidFieldName } from '../../utils/campaign'

import styles from './DynamicVariables.module.scss'

const METADATA_PREFIX = 'metadata.'

interface DynamicVariablesProps {
  fieldList: string[]
  selectedFields?: string[]
  onChange?: (selectedFields: string[]) => void
  disabled?: boolean
}

export function DynamicVariables(props: DynamicVariablesProps) {
  const { onChange = _.noop, disabled = false } = props

  const [fieldList, setFieldList] = useState<string[]>(props.fieldList || [])
  const [selectedFields, setSelectedFields] = useState<string[]>(props.selectedFields || [])

  const didMountRef = useRef(false)

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true // skip on mount
      return
    }
    onChange(selectedFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields])

  const handleChange = e => {
    const newFields = e.target.checked
      ? _.concat(selectedFields, e.target.name)
      : _.without(selectedFields, e.target.name)
    setSelectedFields(_.uniq(newFields))
  }

  const addMetadataField = newField => {
    setFieldList(_.uniq(_.concat(fieldList, newField)))
    setSelectedFields(_.uniq(_.concat(selectedFields, newField)))
  }

  const allowMetadata = fieldList.includes('metadata')
  const list = _.without(_.union(fieldList, selectedFields), 'metadata')

  if (_.isEmpty(list)) return null

  return (
    <FormControl
      className={styles.root}
      component='fieldset'
      variant='standard'
      disabled={disabled}
    >
      <FormLabel component='legend'>Dynamic Variables</FormLabel>
      <FormGroup className={styles.gridContainer}>
        {list.map(field => (
          <FormControlLabel
            key={field}
            control={
              <Checkbox
                checked={selectedFields.includes(field)}
                onChange={handleChange}
                name={field}
              />
            }
            label={field}
          />
        ))}
        {allowMetadata && <AddMetadata onChange={addMetadataField} disabled={disabled} />}
      </FormGroup>
    </FormControl>
  )
}

function AddMetadata({ onChange, disabled }) {
  const [newField, setNewField] = useState<string>('')
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleClose = () => setDialogOpen(false)
  const handleAdd = () => {
    onChange(METADATA_PREFIX + newField)
    handleClose()
  }

  const hasError = !isValidFieldName(newField)

  return (
    <>
      <Button
        className={styles.addMetadataBtn}
        onClick={() => setDialogOpen(true)}
        color='success'
        startIcon={<AddCircle color={disabled ? 'disabled' : 'success'} />}
        disabled={disabled}
        disableFocusRipple
      >
        Add Custom Field
      </Button>

      {dialogOpen && (
        <Dialog onClose={handleClose} open>
          <DialogTitle>Add Custom Field</DialogTitle>
          <DialogContent>
            <FormControl variant='standard' error={hasError} fullWidth>
              <InputLabel htmlFor='field-name'>Field Name</InputLabel>
              <Input
                id='field-name'
                className={styles.newFieldInput}
                startAdornment={<InputAdornment position='start'>{METADATA_PREFIX}</InputAdornment>}
                onChange={e => setNewField(e.target.value)}
                inputProps={{ maxLength: 20 }}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !hasError) handleAdd()
                }}
                autoFocus
              />
              {hasError && <FormHelperText>Only letters are allowed!</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAdd} disabled={hasError}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
