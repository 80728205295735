import { Link as RouterLink, useLocation } from 'react-router-dom'

import { Button, ButtonGroup } from '@mui/material'

export function NavMenu() {
  const { pathname } = useLocation()
  const isCampaigns = pathname.startsWith('/campaigns')
  return (
    <ButtonGroup color='info' disableElevation>
      <Button
        component={RouterLink}
        variant={isCampaigns ? 'outlined' : 'contained'}
        to={'/journeys'}
      >
        Journeys
      </Button>
      <Button
        component={RouterLink}
        variant={isCampaigns ? 'contained' : 'outlined'}
        to={'/campaigns'}
      >
        Campaigns
      </Button>
    </ButtonGroup>
  )
}
