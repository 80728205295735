import InfiniteScroll from 'react-infinite-scroll-component'
import { Link as RouterLink } from 'react-router-dom'
import { useState } from 'react'

import { Add } from '@mui/icons-material'
import { Alert, AlertTitle, Button, List } from '@mui/material'

import { Loading } from '@assuranceiq/react-components'
import _ from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'

import { Breadcrumbs } from '../components/Breadcrumbs'
import FilteredJourneysQuery from '../hooks/FilteredJourneysQuery'
import type { Journey } from '../types'
import { JourneyListFilters } from '../components/JourneyListFilters'
import { JourneyListItem } from '../components/JourneyListItem'
import { JourneyStatus } from '../enums'
import TagsQuery from '../hooks/TagQuery'
import { useGlobalActions, useGlobalState } from '../store'
import { useJourneyMutation } from '../hooks/useJourneyMutation'

import styles from './Home.module.scss'

// render this many journeys at a time as you scroll (too many at once will freeze the browser)
const INFINITE_SCROLL_PAGINATION_SIZE = 20

export function Home() {
  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)
  console.info('Rendering Home page')
  return (
    <div>
      <div className={styles.header}>
        <Breadcrumbs root='Journeys' title='All' />
        <Button
          className='animate__animated animate__slideInRight'
          variant='contained'
          color='primary'
          startIcon={<Add />}
          component={RouterLink}
          to='/create-journey'
          disabled={!hasEditPermission}
        >
          Create New Journey
        </Button>
      </div>
      <div className={styles.content}>
        <Filters />
        <JourneyList />
      </div>
    </div>
  )
}

function Filters() {
  const searchByTags = useGlobalState(state => state.settings.searchByTags)

  const showCreatedByOthers = useGlobalState(state => state.settings.showCreatedByOthers)
  const showCreatedByMe = useGlobalState(state => state.settings.showCreatedByMe)

  const showActive = useGlobalState(state => state.settings.showActive)
  const showInactive = useGlobalState(state => state.settings.showInactive)
  const showArchived = useGlobalState(state => state.settings.showArchived)

  const journeyLoisFilter = useGlobalState(state => state.settings.journeyLoisFilter)
  const journeyCampaignsFilter = useGlobalState(state => state.settings.journeyCampaignsFilter)
  const showTrafficSplit = useGlobalState(state => state.settings.showTrafficSplit)

  const {
    setSearchByTags,
    setShowCreatedByMe,
    setShowCreatedByOthers,
    setShowActive,
    setShowInactive,
    setShowArchived,
    setJourneyLoisFilter,
    setJourneyCampaignsFilter,
    setShowTrafficSplit
  } = useGlobalActions(actions => actions.settings)

  return (
    <TagsQuery>
      {({ tags }) => {
        return (
          <JourneyListFilters
            tags={tags}
            searchByTags={searchByTags}
            onSearchByTags={setSearchByTags}
            showCreatedByMe={showCreatedByMe}
            onShowCreatedByMe={setShowCreatedByMe}
            showCreatedByOthers={showCreatedByOthers}
            onShowCreatedByOthers={setShowCreatedByOthers}
            showActive={showActive}
            onShowActive={setShowActive}
            showInactive={showInactive}
            onShowInactive={setShowInactive}
            showArchived={showArchived}
            onShowArchived={setShowArchived}
            journeyLoisFilter={journeyLoisFilter}
            setJourneyLoisFilter={setJourneyLoisFilter}
            journeyCampaignsFilter={journeyCampaignsFilter}
            setJourneyCampaignsFilter={setJourneyCampaignsFilter}
            showTrafficSplit={showTrafficSplit}
            onShowTrafficSplit={setShowTrafficSplit}
          />
        )
      }}
    </TagsQuery>
  )
}

function JourneyList() {
  const { user } = useAuth0()
  const { editJourneyTags } = useJourneyMutation()

  const searchByTags = useGlobalState(state => state.settings.searchByTags)

  const showCreatedByMe = useGlobalState(state => state.settings.showCreatedByMe)
  const showCreatedByOthers = useGlobalState(state => state.settings.showCreatedByOthers)

  const showActive = useGlobalState(state => state.settings.showActive)
  const showInactive = useGlobalState(state => state.settings.showInactive)
  const showArchived = useGlobalState(state => state.settings.showArchived)

  const journeyLoisFilter = useGlobalState(state => state.settings.journeyLoisFilter)
  const journeyCampaignsFilter = useGlobalState(state => state.settings.journeyCampaignsFilter)
  const showTrafficSplit = useGlobalState(state => state.settings.showTrafficSplit)

  let createdBy = ''
  if (showCreatedByMe && !showCreatedByOthers) {
    createdBy = user?.email || ''
  } else if (showCreatedByOthers && !showCreatedByMe) {
    createdBy = `!${user?.email}`
  }

  const status: JourneyStatus[] = []
  if (showActive) {
    status.push(JourneyStatus.PUBLISHED)
  }
  if (showInactive) {
    status.push(JourneyStatus.DRAFT)
    status.push(JourneyStatus.DISABLED)
  }
  if (showArchived) {
    status.push(JourneyStatus.ARCHIVED)
  }

  return (
    <FilteredJourneysQuery
      status={status}
      createdBy={createdBy}
      loi={journeyLoisFilter}
      journeyCampaigns={journeyCampaignsFilter}
      tags={searchByTags}
      routingExperimentEnabled={showTrafficSplit}
      showSkeleton
      noCache
    >
      {({ journeys }) => {
        journeys = _.sortBy(journeys, 'modifiedAt').reverse()

        // nothing to display
        if (_.isEmpty(journeys)) {
          return (
            <Alert severity='warning' sx={{ mt: 1, width: '100%', height: 'fit-content' }}>
              <AlertTitle>No journeys to display!</AlertTitle>
              Check the filters or create a new journey.
            </Alert>
          )
        }

        return (
          <List className={styles.list} dense>
            <ListItems journeys={journeys} editJourneyTags={editJourneyTags} />
          </List>
        )
      }}
    </FilteredJourneysQuery>
  )
}

/**
 * Render instances of `JourneyListItem` with InfiniteScroll.
 *
 * Currently, the pagination mechanism is done on the client side
 * because we don't have too many journeys to overwhelm network bandwidth
 * but enough to overwhelm the browser if we rendered everything at once.
 * Browser CPU over-usage can make our page animations lag/stutter, which is a bad UX.
 */
function ListItems({ journeys, editJourneyTags }) {
  const [cursor, setCursor] = useState<number>(INFINITE_SCROLL_PAGINATION_SIZE)
  const renderedJourneys: Journey[] = _.take(journeys, cursor)
  const hasMore = renderedJourneys.length < journeys.length
  return (
    <InfiniteScroll
      dataLength={renderedJourneys.length} // important field to render the next data
      next={() => setCursor(cursor + INFINITE_SCROLL_PAGINATION_SIZE)}
      hasMore={hasMore}
      loader={null /* no server-side loader */}
    >
      <>
        {renderedJourneys.map(journey => (
          <JourneyListItem key={journey.id} journey={journey} editJourneyTags={editJourneyTags} />
        ))}
        {/* client-side loader */}
        {hasMore && <Loading />}
      </>
    </InfiniteScroll>
  )
}
