import { Link as RouterLink } from 'react-router-dom'
import { useState } from 'react'

import { Add, Clear, Report } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Popover,
  Tooltip,
  Typography
} from '@mui/material'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { isEqual, noop } from 'lodash'
import relativeTime from 'dayjs/plugin/relativeTime'

import type { Journey } from '../../types'
import { JourneyHubLink } from '../JourneyHubLink'
import { JourneyStartTrigger, JourneyStatus } from '../../enums'
import type { JourneyTagsEditInput } from '../../hooks/useJourneyMutation'
import { LoiIcon } from '../LoiIcon'
import { formatLocale } from '../../utils/number'
import { pluralize } from '../../utils/pluralize'

import styles from './JourneyListItem.module.scss'

dayjs.extend(relativeTime)

interface JourneyListItemProps {
  journey: Journey
  editJourneyTags?: (props: JourneyTagsEditInput) => void
}

export function JourneyListItem(props: JourneyListItemProps) {
  const { journey, editJourneyTags = noop } = props
  const icon = getJourneyIcon(journey.status, journey.loi)
  const metadata = getMetadata(journey, editJourneyTags)

  return (
    <ListItem
      className={styles.root}
      component={RouterLink}
      to={`/journeys/${journey.id}`}
      disablePadding
    >
      <Box className={styles.listItemContent}>
        <ListItemIcon sx={{ ml: -0.5 }}>{icon}</ListItemIcon>
        <ListItemText
          primary={<Typography variant='body1'>{journey.name}</Typography>}
          secondary={metadata}
          secondaryTypographyProps={{ component: 'div' }}
        />
      </Box>
      <Box className={styles.journeyHubLink}>
        <JourneyHubLink journeyName={journey.name} />
      </Box>
    </ListItem>
  )
}

function getJourneyIcon(status, loi) {
  let avatarClassname = ''
  if (status === JourneyStatus.PUBLISHED) {
    avatarClassname = styles.success
  } else if (status === JourneyStatus.DRAFT || status === JourneyStatus.DISABLED) {
    avatarClassname = styles.secondary
  }
  return (
    <Avatar className={clsx(styles.avatar, avatarClassname)}>
      <LoiIcon loi={loi} />
    </Avatar>
  )
}

function getMetadata(journey: Journey, editJourneyTags: () => void) {
  return (
    <>
      <Box className={styles.chipsContainer}>
        {displayStats(journey)}
        <Tags journey={journey} editJourneyTags={editJourneyTags} />
      </Box>
      <Typography variant='caption' display='block' mt={0.25}>
        Updated {dayjs(journey.modifiedAt || journey.createdAt).fromNow()} by{' '}
        <span className={styles.author}>{journey.modifiedBy || journey.createdBy}</span>
      </Typography>
    </>
  )
}

function displayStats(journey: Journey) {
  const { todayJourneyStarts, midFlightShopperCount } = journey.stats || {}
  const todayJourneyStartsFormatted = formatLocale(todayJourneyStarts)

  const midFlightShopperCountFormatted = formatLocale(midFlightShopperCount)

  const { remainingJourneyStarts } = journey
  const remainingJourneyStartsFormatted = formatLocale(remainingJourneyStarts)
  const remainingJourneyStartsLabel = remainingJourneyStartsFormatted + ' remaining'
  const remainingJourneyStartsTooltip =
    remainingJourneyStartsFormatted +
    ' remaining valid journey starts in file as the completion of the last run.'

  const thresholdReached =
    journey.alertDailyThreshold >= 0 && todayJourneyStarts > journey.alertDailyThreshold

  let tooltip = `${todayJourneyStartsFormatted} started today`
  if (thresholdReached) {
    tooltip += `, exceeding the daily alert threshold of ${formatLocale(
      journey.alertDailyThreshold
    )}`
  }

  const isBatch = journey.journeyStartTrigger === JourneyStartTrigger.BATCH
  const showStats = isBatch || todayJourneyStarts > 0
  const journeyStartsDescription = isBatch
    ? ` of ${formatLocale(journey.batchDailyLimit)} daily cap`
    : ' started today'

  const midFlightShopperCountTooltip =
    midFlightShopperCount > 0
      ? `There ${midFlightShopperCount === 1 ? 'is' : 'are'} ${pluralize(
          midFlightShopperCount,
          'shopper'
        )}
        currently in the middle of their journey`
      : 'No shoppers are in journey mid-flight'
  const midFlightShopperCountLabel = midFlightShopperCountFormatted + ' mid-flight'

  return (
    <>
      {showStats && (
        <Tooltip title={tooltip} placement='top' arrow>
          <Chip
            label={todayJourneyStartsFormatted + journeyStartsDescription}
            size='small'
            icon={thresholdReached ? <Report /> : undefined}
            color={thresholdReached ? 'error' : undefined}
          />
        </Tooltip>
      )}
      {remainingJourneyStarts != null && (
        <Tooltip title={remainingJourneyStartsTooltip} placement='top' arrow>
          <Chip label={remainingJourneyStartsLabel} size='small' />
        </Tooltip>
      )}
      <Tooltip title={midFlightShopperCountTooltip} placement='top' arrow>
        <Chip label={midFlightShopperCountLabel} size='small' />
      </Tooltip>
    </>
  )
}

function Tags({ journey, editJourneyTags }) {
  const [newTagInput, setNewTagInput] = useState<string>('')
  const [newTagsList, setNewTagsList] = useState<string[] | null>()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)

    if (!newTagsList || newTagsList.length === 0 || isEqual(journey.tags, newTagsList)) return

    const tags = getPartialTags()
    journey.tags = tags
    setNewTagsList(null)
    return editJourneyTags({ journeyId: journey.id, tags })
  }

  const open = Boolean(anchorEl)

  const removeTag = async tag => {
    if (!journey?.tags) return

    const index = journey.tags.indexOf(tag)
    journey.tags.splice(index, 1)

    await editJourneyTags({ journeyId: journey.id, tags: journey.tags })
  }

  const updateTags = async tag => {
    const parsedTag = tag.trim()

    if (!parsedTag) return

    if (journey.tags?.includes(parsedTag) || newTagsList?.includes(parsedTag)) {
      return setNewTagInput('')
    }

    setNewTagsList(tagsList => [...(tagsList ?? []), parsedTag].sort())
    setNewTagInput('')
  }

  const getPartialTags = () => {
    if (!newTagsList) return journey.tags

    return [...(journey?.tags ?? []), ...newTagsList].sort((a, b) => {
      return a.localeCompare(b, undefined, { sensitivity: 'base' })
    })
  }

  return (
    <>
      {journey.tags?.map(tag => (
        <Chip
          label={tag}
          key={tag}
          variant='outlined'
          size='small'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDelete={e => {
            e.preventDefault()
            e.stopPropagation()
            if (
              window.confirm(`You're about to remove the tag "${tag}" from this journey. Proceed?`)
            ) {
              removeTag(tag)
            }
          }}
          deleteIcon={<Clear />}
        />
      ))}

      <Tooltip
        title='Add a tag'
        className={clsx(styles.addTags, { [styles.addTags_show]: open })}
        placement='right'
        arrow
      >
        <Chip
          size='small'
          variant='outlined'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
          }}
          label={<Add sx={{ fontSize: '18px' }} />}
          sx={{ backgroundColor: 'white' }}
        ></Chip>
      </Tooltip>

      <Popover
        id='popover-tags'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Box sx={{ p: 2 }}>
          <FormControl fullWidth sx={{ mb: 1 }} size='small'>
            <InputLabel htmlFor='tag-input' size='small'>
              Tags
            </InputLabel>
            <OutlinedInput
              id='tag-input'
              value={newTagInput}
              onChange={e => {
                if (e.target.value.length > 30) return
                setNewTagInput(e.target.value)
              }}
              onKeyUp={async e => {
                if (e.key !== 'Enter' && e.code !== '13') {
                  return
                }
                updateTags(newTagInput)
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={() => updateTags(newTagInput)}>
                    <Add sx={{ fontSize: '20px' }} />
                  </IconButton>
                </InputAdornment>
              }
              label='Tags'
              autoFocus
            />
          </FormControl>

          {journey.tags && journey.tags.length > 0 && <Divider />}

          <List>
            {getPartialTags()?.map(tag => (
              <ListItem disablePadding key={tag}>
                <ListItemText primary={tag} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  )
}
