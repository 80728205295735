import { useState } from 'react'

import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { Box, Button, FormControl, FormLabel, IconButton, Tooltip, Typography } from '@mui/material'

import _ from 'lodash'
import clsx from 'clsx'

import type { DelayInput } from '../../types'
import { DelaySelect } from '../DelaySelect'
import { convertDelayInputToMinutes, convertMinutesToDelayInput } from '../../utils/delay'

import styles from './DialCadenceInput.module.scss'

interface RowValue {
  value: number // delay value in minutes
  key?: string // unique ID needed to render dynamic rows correctly
}

interface DialCadenceInputProps {
  delayMinutes?: number[]
  onChange?: (delayMinutes: number[]) => void
  disabled?: boolean
}

export function DialCadenceInput(props: DialCadenceInputProps) {
  const { delayMinutes = [], onChange = _.noop, disabled = false } = props

  const [values, setValues] = useState<RowValue[]>(
    delayMinutes.map(delay => {
      const value: RowValue = { value: delay }
      if (!value.key) value.key = _.uniqueId()
      return value
    })
  )

  const handleChange = (newValues: RowValue[]) => {
    setValues(newValues)
    onChange(_.map(newValues, 'value'))
  }

  const addNewDelay = () => {
    const newValue = { value: 0, key: _.uniqueId() }
    handleChange([...values, newValue])
  }

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }} disabled={disabled}>
      <FormLabel>Cadence</FormLabel>
      <>
        {values.map((value: RowValue, i) => (
          <InputRow
            key={value.key}
            index={i}
            value={value}
            onChange={(newValue: RowValue) => {
              const newValues = _.cloneDeep(values)
              const indexToChange = _.findIndex(newValues, {
                key: newValue.key
              })
              newValues[indexToChange] = { key: newValue.key, value: newValue.value }
              handleChange(newValues)
            }}
            onDelete={() => {
              const newValues = _.cloneDeep(values)
              const indexToDelete = _.findIndex(newValues, {
                key: value.key
              })
              _.pullAt(newValues, indexToDelete)
              handleChange(newValues)
            }}
            disabled={disabled}
          />
        ))}

        <Button
          onClick={addNewDelay}
          color='success'
          startIcon={<AddCircle color={disabled ? 'disabled' : 'success'} />}
          disabled={disabled}
          sx={{ mt: 0.5, width: 'fit-content' }}
        >
          Add {values.length > 0 ? 'another ' : ''}dial
        </Button>
      </>
    </FormControl>
  )
}

function InputRow({
  index,
  value,
  onChange,
  onDelete,
  disabled
}: {
  index: number
  value: RowValue
  onChange: (newValue: RowValue) => void
  onDelete: () => void
  disabled: boolean
}) {
  const delayInput = convertMinutesToDelayInput(value.value)
  return (
    <Box display='flex' alignItems='center' mt={1.5}>
      <Box className={clsx(styles.dialCount, disabled && styles.disabled)}>
        <Box>
          <strong>Dial #{index + 1}</strong>
        </Box>
        <Typography display='block' variant='caption' mt={-0.5}>
          ↳ Delay for:
        </Typography>
      </Box>

      <DelaySelect
        value={delayInput}
        onChange={(newInput: DelayInput) => {
          const newValue: RowValue = _.cloneDeep(value)
          newValue.value = convertDelayInputToMinutes(newInput)
          onChange(newValue)
        }}
        disabled={disabled}
      />

      <Tooltip title='Delete' placement='right'>
        <Box ml={0.5}>
          <IconButton onClick={onDelete} disabled={disabled}>
            <RemoveCircle color={disabled ? 'disabled' : 'error'} fontSize='small' />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}
