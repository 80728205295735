import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input CampaignCopyInput {
 *   sourceCampaignId: ID!
 *   targetCampaignName: String!
 *   componentType: ComponentType!
 * }
 */
export const COPY_CAMPAIGN_MUTATION = gql`
  mutation CopyCampaign($campaignCopyInput: CampaignCopyInput!) {
    copyCampaign(campaignCopyInput: $campaignCopyInput) {
      campaignId
    }
  }
`
