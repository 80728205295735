import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'

import _ from 'lodash'
import clsx from 'clsx'
import dayjs from 'dayjs'

import { Breadcrumbs } from '../components/Breadcrumbs'
import { CampaignBusinessType, ComponentSource, ComponentType } from '../enums'
import {
  CampaignCreateInput,
  CampaignUpdateInput,
  useCampaignMutation
} from '../hooks/useCampaignMutation'
import CampaignQuery from '../hooks/CampaignQuery'
import { CloneButton } from '../components/CloneButton'
import type { ComponentCampaign } from '../types'
import { DialCadenceInput } from '../components/DialCadenceInput'
import { LookerLink } from '../components/LookerLink'
import { isValidName } from '../utils/campaign'
import { useGlobalState } from '../store'

import styles from './ShovelCampaign.module.scss'

export function ShovelCampaign() {
  const { campaignId } = useParams()
  if (!campaignId) return <Main />
  return (
    <CampaignQuery componentType={ComponentType.CALL} campaignId={campaignId} noCache>
      {({ campaigns }) => {
        if (_.isEmpty(campaigns)) {
          return <Alert severity='warning'>No matching campaign found.</Alert>
        }
        const campaign = campaigns.pop()
        return <Main campaign={campaign} />
      }}
    </CampaignQuery>
  )
}

function Main({ campaign }: { campaign?: ComponentCampaign }) {
  const [name, setName] = useState<string>(campaign?.campaignName || '')
  const [description, setDescription] = useState<string>(campaign?.description || '')
  const [queuingEligible, setQueuingEligible] = useState<boolean>(
    campaign?.queuingEligible || false
  )
  const [businessType, setBusinessType] = useState<CampaignBusinessType>(
    campaign?.businessType || CampaignBusinessType.MARKETING
  )
  const [delays, setDelays] = useState<number[]>(campaign?.delays || [])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [openConfimationDialog, setOpenConfimationDialog] = useState(false)

  const hasEditPermission = useGlobalState(state => state.app.hasEditPermission)

  const { createCallCampaign } = useCampaignMutation()
  const { updateCampaign, copyCampaign } = useCampaignMutation()

  const hasInvalidName = !campaign && !!name && !isValidName(name)
  const disableEdit = !hasEditPermission
  const disableSubmit = !hasEditPermission || !name || hasInvalidName
  const author = campaign?.updatedBy || campaign?.createdBy
  const journeyId = campaign?.journeyId
  const journeyName = campaign?.journeyName

  const create = async () => {
    let payload: CampaignCreateInput = {
      name,
      businessType,
      delays,
      componentSource: ComponentSource.Shovel
    }
    if (description) payload = { ...payload, description }
    setIsSaving(true)
    try {
      await createCallCampaign(payload)
    } finally {
      setIsSaving(false)
    }
  }

  const update = async () => {
    let payload: CampaignUpdateInput = {
      name,
      businessType,
      campaignId: campaign?.campaignId,
      componentType: ComponentType.CALL,
      componentSource: ComponentSource.Shovel,
      delays
    }
    if (description) payload = { ...payload, description }
    if (campaign?.queuingEligible !== queuingEligible) payload = { ...payload, queuingEligible }
    setIsSaving(true)
    try {
      await updateCampaign(payload)
    } finally {
      setIsSaving(false)
    }
  }

  const confirmUpdate = async () => {
    setOpenConfimationDialog(false)
    await update()
  }

  const cloneCampaign = targetName => {
    return copyCampaign(campaign?.campaignId, targetName, ComponentType.CALL)
  }

  const handleUpdateClick = async () => {
    if (journeyId) {
      setOpenConfimationDialog(true)
    } else {
      await update()
    }
  }

  const subtitle = campaign ? (
    <LookerLink campaignName={campaign.campaignName} campaignType={ComponentType.CALL} />
  ) : null

  return (
    <div className={clsx(styles.root, 'animate__animated animate__fadeIn')}>
      <div className={styles.header}>
        <Breadcrumbs
          root='Call Campaigns'
          title={campaign?.campaignName || 'New Call Campaign'}
          subtitle={subtitle}
        />
        {campaign && (
          <div className={styles.right}>
            <div className={styles.metadata}>
              <div>Version {campaign.version}</div>
              {(campaign.updatedTimestamp || author) && (
                <div>
                  Updated
                  {campaign.updatedTimestamp &&
                    ` at ${dayjs(campaign.updatedTimestamp).format('MM/DD/YYYY @h:mma')}`}
                  {author && (
                    <>
                      {' by '}
                      <span className={styles.author}>{author}</span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Box mt={3} display='flex' justifyContent='space-between' alignItems='flex-start'>
        <Box display='flex' alignItems='center'>
          <TextField
            id='name'
            label='Name'
            variant='outlined'
            value={name}
            onChange={e => setName(e.target.value)}
            error={hasInvalidName}
            autoComplete='off'
            inputProps={{ maxLength: 100 }}
            disabled={disableEdit || !!campaign}
            sx={{ minWidth: '35rem' }}
            autoFocus
            required
          />
          <FormHelperText sx={{ ml: 1.5 }}>
            (must be unique, lowercase, and alphanumeric characters; underscores okay)
          </FormHelperText>
        </Box>

        {campaign && (
          <CloneButton
            label='campaign'
            defaultName={campaign.campaignName}
            onConfirm={cloneCampaign}
            disabled={!hasEditPermission}
          />
        )}
      </Box>

      <Box mt={2}>
        <TextField
          id='description'
          label='Description'
          variant='outlined'
          value={description}
          onChange={e => setDescription(e.target.value)}
          autoComplete='off'
          disabled={disableEdit}
          fullWidth
        />
      </Box>

      <Box mt={3}>
        <FormControl disabled={disableEdit || !!campaign} required>
          <FormLabel id='outreach-type-radio'>Outreach Type</FormLabel>
          <RadioGroup
            aria-labelledby='outreach-type-radio'
            name='outreach-type'
            value={businessType}
            onChange={e => setBusinessType(e.target.value as CampaignBusinessType)}
            row
          >
            <FormControlLabel
              value={CampaignBusinessType.MARKETING}
              control={<Radio />}
              label='Marketing'
            />
            <FormControlLabel
              value={CampaignBusinessType.TRANSACTIONAL}
              control={<Radio />}
              label='Transactional'
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={1}>
        <FormControl disabled={disableEdit} required>
          <FormGroup aria-labelledby='queuing-eligible-checkbox'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={queuingEligible}
                  onChange={e => setQueuingEligible(e.target.checked)}
                />
              }
              label='Queing Eligible (CRM)'
              labelPlacement='start'
              sx={{ ml: 0 }}
            />
          </FormGroup>
        </FormControl>
      </Box>

      <Box mt={2}>
        <DialCadenceInput delayMinutes={delays} onChange={setDelays} disabled={disableEdit} />
      </Box>

      {journeyId && (
        <Box mt={3}>
          <Alert severity='warning'>
            This campaign is already being used in the journey:{' '}
            <Link component={RouterLink} to={`/journeys/${journeyId}`}>
              {journeyName}
            </Link>
          </Alert>
        </Box>
      )}

      <Box mt={3}>
        {!campaign && (
          <Button
            variant='contained'
            color='primary'
            onClick={create}
            disabled={disableSubmit || isSaving}
          >
            {isSaving ? 'CREATING CAMPAIGN...' : 'CREATE CAMPAIGN'}
          </Button>
        )}
        {campaign && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleUpdateClick}
            disabled={disableSubmit || isSaving}
          >
            {isSaving ? 'UPDATING CAMPAIGN...' : 'UPDATE CAMPAIGN'}
          </Button>
        )}
      </Box>

      <Dialog open={openConfimationDialog} onClose={() => setOpenConfimationDialog(false)}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          Updating this campaign will affect mid-flight journey shoppers. Press confirm to proceed.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfimationDialog(false)}>Cancel</Button>
          <Button onClick={confirmUpdate}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
