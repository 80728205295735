import { useState } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { useSnackbar } from '@assuranceiq/react-components'

import CampaignQuery from '../../hooks/CampaignQuery'
import { CampaignSelect } from '../CampaignSelect'
import type { ComponentCampaign, JourneyComponent } from '../../types'
import { ComponentType } from '../../enums'
import { TestWidget } from '../TestWidget'
import { getDateOnly } from '../../utils/date'
import { useDebounce } from '../../hooks/useDebounce'
import { useJourneyMutation } from '../../hooks/useJourneyMutation'

import styles from './DialConfig.module.scss'

interface DialConfigProps {
  component: JourneyComponent
  disabled: boolean
}

export function DialConfig(props: DialConfigProps) {
  const { component, disabled } = props
  const { componentId, componentConfig } = component

  const [deadlineToggle, setDeadlineToggle] = useState<boolean>(!!componentConfig?.deadline)
  const [endDate, setEndDate] = useState<string | undefined>(getDateOnly(componentConfig?.deadline))
  const [nextActionOnEnd, setNextActionOnEnd] = useState<boolean>(
    !!componentConfig?.nextActionOnEnd
  )

  const { configureDialComponent } = useJourneyMutation()

  const { showSnackbar } = useSnackbar()

  const save = ({
    campaign = null,
    deadline = undefined,
    nextActionOnEnd = false
  }: {
    campaign?: ComponentCampaign | null
    deadline?: string | null
    nextActionOnEnd?: boolean
  }) =>
    configureDialComponent(
      componentId,
      campaign ? campaign.campaignId : componentConfig?.campaignId,
      campaign ? campaign.campaignName : componentConfig?.campaignName,
      deadline === undefined ? componentConfig?.deadline : deadline, // accept null value
      nextActionOnEnd
    )

  const handleToggle = checked => {
    if (endDate !== undefined) save({ deadline: checked ? endDate : null })
    setDeadlineToggle(checked)
  }

  const handleNextActionOnEndChange = (newValue: boolean) => {
    showSnackbar('Saving changes...', 'info')
    save({ nextActionOnEnd: newValue })
    setNextActionOnEnd(newValue)
  }

  // debounce on termination date change (to prevent race condition when typing by keyboard)
  useDebounce(() => save({ deadline: endDate }), [endDate], disabled)

  const isTerminated = disabled && endDate && dayjs().isAfter(endDate, 'day')

  return (
    <>
      <Box mr={1}>Queue Dialing for:</Box>
      <CampaignQuery componentType={ComponentType.CALL}>
        {({ campaigns }) => {
          return (
            <div className={styles.campaign}>
              <Box display='flex' alignItems='center'>
                <CampaignSelect
                  campaigns={campaigns}
                  valueKey='campaignName'
                  value={componentConfig?.campaignName}
                  onChange={campaign => save({ campaign })}
                  disabled={disabled}
                />
                {!disabled && <TestWidget component={component} sx={{ ml: 1 }} />}
              </Box>

              {componentConfig?.campaignId && (
                <div className={styles.terminationConfig}>
                  <Tooltip
                    title="You can specify a date after which this action will be skipped. Other actions in this journey won't be affected."
                    placement='bottom-start'
                    arrow
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={deadlineToggle}
                            onChange={e => handleToggle(e.target.checked)}
                            color={isTerminated ? 'warning' : 'primary'}
                            disabled={disabled}
                          />
                        }
                        label={
                          `Terminate${isTerminated ? 'd' : ''} on` +
                          (deadlineToggle ? ':' : ' a date')
                        }
                      />
                    </FormGroup>
                  </Tooltip>

                  {deadlineToggle && (
                    <DatePicker
                      className={styles.datePicker}
                      value={dayjs(endDate || '')}
                      onChange={date => setEndDate((date as Dayjs).format('YYYY-MM-DD'))}
                      autoFocus={_.isEmpty(endDate)}
                      disabled={disabled}
                      disablePast
                    />
                  )}
                </div>
              )}

              {componentConfig?.campaignId && (
                <div className={styles.nextActionConfig}>
                  <FormControl disabled={disabled}>
                    <FormLabel id='next-action-radio-label'>Execute the next action:</FormLabel>
                    <RadioGroup
                      aria-labelledby='next-action-radio-label'
                      name='next-action-radio'
                      value={nextActionOnEnd}
                      onChange={e => handleNextActionOnEndChange(e.target.value === 'true')}
                      row
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='When this campaign starts'
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='When this campaign ends'
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </div>
          )
        }}
      </CampaignQuery>
    </>
  )
}
