import { gql } from '@apollo/client'

import { CAMPAIGN_FRAGMENT } from '../fragments/campaign-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const CAMPAIGNS_QUERY = gql`
  query Campaigns($componentType: ComponentType!, $componentSource: ComponentSource) {
    campaigns(campaignInput: { componentType: $componentType, componentSource: $componentSource }) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`
