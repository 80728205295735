import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyCopyInput {
 *   sourceJourneyId: ID!
 *   targetJourneyName: String!
 * }
 */
export const COPY_JOURNEY_MUTATION = gql`
  mutation CopyJourney($journeyCopy: JourneyCopyInput!) {
    copyJourney(journeyCopy: $journeyCopy) {
      id
    }
  }
`
