import { gql } from '@apollo/client'

import { JOURNEY_FRAGMENT } from '../fragments/journey-fragment'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 */
export const FILTERED_JOURNEYS_QUERY = gql`
  query FilteredJourneys($filterInput: JourneyFilterInput!) {
    filteredJourneys(filterInput: $filterInput) {
      ...JourneyFragment
    }
  }
  ${JOURNEY_FRAGMENT}
`
