import { gql } from '@apollo/client'

/**
 * API Doc: https://assuranceiq.atlassian.net/l/cp/vPkvLSYN
 *
 * input JourneyUnpublishInput {
 *   journeyId: ID!
 *   disableJourney: Boolean! = false
 * }
 */
export const UNPUBLISH_JOURNEY_MUTATION = gql`
  mutation unpublishJourney($journeyUnpublish: JourneyUnpublishInput!) {
    unpublishJourney(journeyUnpublish: $journeyUnpublish) {
      id
    }
  }
`
